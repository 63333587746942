import Assets from '~/src/constants/assets';

export const PILOT_ORG_SIZE = 'pilot';
export const ORGANIZATION_SIZE_1_49 = '1-49';
export const ORGANIZATION_SIZE_50_149 = '50-149';
export const ORGANIZATION_SIZE_150_299 = '150-299';
export const ORGANIZATION_SIZE_300_499 = '300-499';
export const ORGANIZATION_SIZE_500_999 = '500-999';
export const ORGANIZATION_SIZE_1000_2999 = '1000-2999';
export const ORGANIZATION_SIZE_3000_4999 = '3000-4999';
export const ORGANIZATION_SIZE_5000_9999 = '5000-9999';
export const ORGANIZATION_SIZE_10000_PLUS = '10,000 plus';

export const LANGUAGE_PREFERENCE_CHOICES = [
    { text: 'organization.english', value: 'en'},
    { text: 'organization.french', value: 'fr'}
];

export const ORGANIZATION_SIZE_CHOICES = [
    { value: PILOT_ORG_SIZE, label: 'organization.1-49', name:'organization_size', prize:'' },
    { value: ORGANIZATION_SIZE_1_49, label: 'organization.1-49', name:'organization_size', prize:'organization.1-49-prize' },
    { value: ORGANIZATION_SIZE_50_149, label: 'organization.50-149', name:'organization_size', prize:'organization.50-149-prize' },
    { value: ORGANIZATION_SIZE_150_299, label: 'organization.150-299', name:'organization_size', prize:'organization.150-299-prize' },
    { value: ORGANIZATION_SIZE_300_499, label: 'organization.300-499', name:'organization_size', prize:'organization.300-499-prize' },
    { value: ORGANIZATION_SIZE_500_999, label: 'organization.500-999', name:'organization_size', prize:'organization.500-999-prize' },
    { value: ORGANIZATION_SIZE_1000_2999, label: 'organization.1000-2999', name:'organization_size', prize:'organization.1000-2999-prize' },
    { value: ORGANIZATION_SIZE_3000_4999, label: 'organization.3000-4999', name:'organization_size', prize:'organization.3000-4999-prize'  },
    { value: ORGANIZATION_SIZE_5000_9999, label: 'organization.5000-9999', name:'organization_size', prize:'organization.5000-9999-prize'  },
    { value: ORGANIZATION_SIZE_10000_PLUS, label: 'organization.10,000+', name:'organization_size'}
];


export const  PROVINCE_CHOICES = [
    {value: '', label: "launch_guide.province_placeholder", name:'province_code' },
    {value: 'AB', label: 'Alberta', name:'province_code' },
    {value: 'BC', label: 'British Columbia', name:'province_code' },
    {value: 'MB', label: 'Manitoba', name:'province_code' },
    {value: 'NB', label: 'New Brunswick', name:'province_code' },
    {value: 'NL', label: 'Newfoundland and Labrador', name:'province_code' },
    {value: 'NT', label: 'Northwest Territories', name:'province_code' },
    {value: 'NS', label: 'Nova Scotia', name:'province_code' },
    {value: 'NU', label: 'Nunavut', name:'province_code' },
    {value: 'ON', label: 'Ontario', name:'province_code' },
    {value: 'PE', label: 'Prince Edward Island', name:'province_code' },
    {value: 'QC', label: 'Quebec', name:'province_code' },
    {value: 'SK', label: 'Saskatchewan', name:'province_code' },
    {value: 'YT', label: 'Yukon', name:'province_code' }
];

export const DASHBOARD_SUBSCRIPTION_STATUS = [ 'active', 'trialing'];

export const HEAR_ABOUT_US_CHOICES_EN = [
    {value: '', label: "launch_guide.hear_about_us_placeholder", name: 'hear_about_us'},
    {value: 'Saw an advertisement on the internet, or social media', label: 'Saw an advertisement on the internet, or social media', name: 'hear_about_us'},
    {value: 'Read about it in a publication', label: 'Read about it in a publication', name: 'hear_about_us'},
    {value: 'Heard about it through my workplace', label: 'Heard about it through my workplace', name: 'hear_about_us'},
    {value: 'Heard about it at a conference', label: 'Heard about it at a conference', name: 'hear_about_us'},
    {value: 'Saw it in an email from ParticipACTION', label: 'Saw it in an email from ParticipACTION', name: 'hear_about_us'},
    {value: 'Heard about it from a friend/family/colleague', label: 'Heard about it from a friend/family/colleague', name: 'hear_about_us'},
    {value: 'Other', label: 'Other', name: 'hear_about_us'},
]

export const HEAR_ABOUT_US_CHOICES_FR = [
    {value: '', label: "launch_guide.hear_about_us_placeholder", name: 'hear_about_us'},
    {value: 'Vu une annonce sur internet ou dans les médias sociaux', label: 'Vu une annonce sur internet ou dans les médias sociaux', name: 'hear_about_us'},
    {value: 'En lisant un article ou une infolettre', label: 'En lisant un article ou une infolettre', name: 'hear_about_us'},
    {value: 'Entendu parler à mon lieu de travail', label: 'Entendu parler à mon lieu de travail', name: 'hear_about_us'},
    {value: 'Entendu parler lors d\'une conférence', label: 'Entendu parler lors d\'une conférence', name: 'hear_about_us'},
    {value: 'Reçu un courriel de ParticipACTION', label: 'Reçu un courriel de ParticipACTION', name: 'hear_about_us'},
    {value: 'Un ami/proche/collègue m\'en a parlé', label: 'Un ami/proche/collègue m\'en a parlé', name: 'hear_about_us'},
    {value: 'Autre', label: 'Autre', name: 'hear_about_us'},
]

export const LANGUAGE_DROPDOWN_EN = [
    {value: '', label:"launch_guide.language_dropdown_placeholder", name:'language_dropdown'},
    {value: 'en', label: 'English', name: 'language_dropdown'},
    {value: 'fr', label: 'French', name: 'language_dropdown'}
]

export const LANGUAGE_DROPDOWN_FR = [
    {value: '', label:"launch_guide.language_dropdown_placeholder", name:'language_dropdown'},
    {value: 'en', label: 'English', name: 'language_dropdown'},
    {value: 'fr', label: 'French', name: 'language_dropdown'}
]

export const LANGUAGE_DROPDOWN_ADMIN_EN = [
    {value: 'en', label: 'English', name: 'language_dropdown'},
    {value: 'fr', label: 'French', name: 'language_dropdown'}
]

export const LANGUAGE_DROPDOWN_ADMIN_FR = [
    {value: 'en', label: 'English', name: 'language_dropdown'},
    {value: 'fr', label: 'French', name: 'language_dropdown'}
]
