import React from "react";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { reverse } from '~/src/api/urls';
import './style.scss';

function CTA(props) {
  const {
    text,
    isBorder,
    link,
    onClick,
    miniPadding,
    className = '',
  } = props;
  return (
    <div className={`cta-box ${miniPadding ? 'mini-padding' : ''} ${className}`}>
      {
        (text && onClick) &&
          <Button
            variant="Link"
            onClick={onClick}
            className="back-button-text"
          >
            {text}
          </Button>
      }
      {
        text && link &&
          <Link to={link} className="back-button-text">
            {text}
          </Link>
      }
      <div className={`content ${isBorder ? `has-border` : ''}`}>
        {props.children}
      </div>
    </div>
  );
}

CTA.defaultProps = {
  isBorder: true,
};

export default CTA;

// isBorder
