// This is used to determine if a user is logged in and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route } from 'react-router-dom';

import { GUEST, LOGGED_IN, UNVERIFIED, VERIFIED, NON_ADMIN, VERIFIED_USERS, LOGGED_IN_USERS, NON_PAYMENT_ADMIN, CANCELLED_SUBSCRIPTION } from "~/src/constants/userTypes";
import { AUTH_ERROR, PERMISSION_ERROR, SESSION_ERROR, isAuthRelatedError } from "~/src/api/errors";
import { reverse } from '~/src/api/urls';
import { signOut } from "~/src/utils/auth";
import AuthContext from '~/src/context/authContext';

const ProtectedRoute = ({ component: Component, userType: userType, history: history, ...rest }) => {

  const { currentUserType } = React.useContext(AuthContext);

  const getRedirectObject = (props) => {
    switch(currentUserType) {
      case GUEST:
        return { pathname: reverse("app:login_page"), state: { from: props.location } };
      case UNVERIFIED:
        return { pathname: reverse("app:unconfirmed"), state: { from: props.location } };
      case NON_ADMIN:
        return { pathname: reverse("app:createOrg"), state: { from: props.location } };
      case NON_PAYMENT_ADMIN:
        return { pathname: reverse("app:payment"), state: { from: props.location } };
      case CANCELLED_SUBSCRIPTION:
        return { pathname: reverse("app:canceled_subscription"), state: { from: props.location } };
      default:
        return { pathname: reverse("app:home_page"), state: { from: props.location } };
    }
  };

  const confirmUser = (userType) => {
    switch(userType) {
      case LOGGED_IN:
        return LOGGED_IN_USERS.includes(currentUserType);
      case VERIFIED:
        return VERIFIED_USERS.includes(currentUserType);
      default:
        return userType === currentUserType;
    }
  };


  return (
    !currentUserType ? null :
    <Route
      {...rest}
      render={props =>
        confirmUser(userType) ? (
          <Component {...props} />
        ) : (
          <Redirect to={getRedirectObject(props)} />
        )
      }
    />
  )
}

export default ProtectedRoute
