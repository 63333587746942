import React from "react";
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import StepsContainer from '~/src/onboarding/steps';
import appConfig from "~/config";

import {
  ORGANIZATION_SIZE_10000_PLUS
} from "../constants/organization";
import { PAC_EMAIL } from '~/src/constants/common';
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall, GET } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import ToolTip from "../components/Tooltip";
import CTA from "~/src/components/ctaBox";
import OrgSizeCard from "~/src/components/orgSizeCard";
import Assets from '~/src/constants/assets';
import ButtonWithProgress from '~/src/components/buttonWithProgress';

import { UN_INVITED } from '~/src/constants/subpage';
import ChangePlanModal from "../components/modal/changePlanModal";
import { NON_PAYMENT_ADMIN } from '~/src/constants/userTypes';

import './createOrg.scss';

const PaymentIncompleteContainer = () => {
  const { t, i18n } = useTranslation();

  const { setInitialHeader, handleAuthError, setCurrentUserType, setOrganization, userData } = React.useContext(AuthContext);
  const [inProgress, setInProgress] = React.useState(false);

  const [show, setShow] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState('');
  const [org, setSelectedOrg] = React.useState('');

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setOrganization(result.payload[0]);
        setSelectedPlan(result.payload[0].organization_size);
        setSelectedOrg(result.payload[0]);
      }
    }
  };

  const getOrganization = () => {
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };

  React.useEffect(() => {
    window.setWindowTitle(t("launch_guide.payment_incomplete"));
    setInitialHeader({ mainHeader: UN_INVITED, subHeader: null });
    setCurrentUserType(NON_PAYMENT_ADMIN);
    getOrganization();
  }, []);


  const updatePlan = (plan) => {
    const values = { ...org, organization_size: plan };
    makeAPIcall(reverse('api:organization_update', org.pk), POST, (error, result) => {
      updateOrganizationResultHandler(error, result, values, plan);
    }, values);
  };

  const updateOrganizationResultHandler = async (error, result, values, plan) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }
    } else {
      setSelectedPlan(plan);
      setShow(false);
    }
  };

  const changePlan = () => {
    setShow(true);
  }

  const hide = () => {
    setShow(false)
  };

  const paymentHandler = async (error, result) => {
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        window.open(result.payload.url);
      }
    }
  };

  const paymentClicked = () => {
    const requestData = {
      organization_id: org.pk,
      success_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:paymentSuccess')}`,
      cancel_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:paymentIncomplete')}`
    }
    makeAPIcall(reverse('api:payment'), POST, paymentHandler, requestData);
  } 

  return (
    <Row className="create-org-page justify-content-center align-items-start">
      <StepsContainer currentStep='step2' />

      <Col lg={12} className="org-create-page">
        <CTA
          miniPadding
          text={t("launch_guide.back_step1")}
          link={reverse('app:edit_organization')}
        >
          <div className="org-edit-title-area">
            <p className="org-edit-form-title">
              <img src={Assets.WARNING} className="warning" />
              {t("launch_guide.payment_incomplete")}
            </p>
          </div>
          <div className="title-description">
            <span className="desc">{t("launch_guide.payment_incomplete_desc1")}</span>
            <a className="footer-links" href={`mailto:${PAC_EMAIL}`}>
              <span className="orange">{t("launch_guide.payment_incomplete_desc2")}</span>
            </a>
            <span className="desc">{t("launch_guide.payment_incomplete_desc3")}</span>
          </div>          
          <div className="selected-plan-container">
            <div className="selected-card">
              <div className="your-plan">{t("launch_guide.your_plan")}</div>
              <OrgSizeCard selectedValue={selectedPlan || 'pilot'} selected={true} button={t('organization.button')} />
              <div className="change-ur-plan orange">
                <a className="group-td-delete-btn orange" onClick={() => changePlan()}>
                  {t("launch_guide.choose_plan")}
                </a>
              </div>
            </div>
          </div>
          <div className="payment-link-btn">
            <Button
              className="button-purple"
              onClick={() => paymentClicked()}
            >
              {t('launch_guide.proceed_payment')}
              <img src={Assets.EXTERNAL_LINK} />
            </Button>
          </div>
        </CTA>
      </Col>

      {show && <ChangePlanModal selectedPlan={selectedPlan} onHide={hide} updatePlan={updatePlan} />}
    </Row>
  );
};

export default PaymentIncompleteContainer;
