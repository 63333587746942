import React from 'react';

import OrganizationContext from "~/src/context/organizationContext";
import GroupContext from "~/src/context/groupContext";
import ListGroups from "./index";
import './style.scss';

const GroupContainer = props => (
  <OrganizationContext.Wrapper {...props}>
    <GroupContext.Wrapper {...props}>
      <ListGroups />
    </GroupContext.Wrapper>
  </OrganizationContext.Wrapper>
);

export default GroupContainer;
