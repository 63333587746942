import React from "react";
import { Container } from 'react-bootstrap';

import Assets from '~/src/constants/assets';

import './toaster.scss';

function Toaster(props) {
  const { ToasterText } = props;
  return (
    <div className="toaster-container">
      <Container fluid className="toaster-wrapper"> 
        {/* image will coime here */}
        <img
          alt="checkmark-pic"
          src={Assets.CheckmarkIcon}
          className="checkmark-icon"
        />
        <div className="toaster-text-area">
          <p className="toaster-text">{ToasterText}</p>
        </div>
      </Container>
    </div>
  );
}

Toaster.defaultProps = {
  ToasterText: 'notfications/logout text will go here',
};

export default Toaster;
