import React from "react";
import { useTranslation } from 'react-i18next';
import { Row, Spinner } from 'react-bootstrap';
import { reverse } from "~/src/api/urls";
import { GET, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from "~/src/context/authContext";
import LaunchGuide from "~/src/components/launchGuide";
import OrganizationContext from "~/src/context/organizationContext";
import "./style.scss";

const LaunchGuideDiv = () => {
  const { selectedOrganization, organizationList } = React.useContext(OrganizationContext);
  const { handleAuthError } = React.useContext(AuthContext);

  return (
    <Row className="launch-guide justify-content-center">
      {(selectedOrganization.pk && Object.keys(organizationList).length) ?
        <LaunchGuide
          organizationList={organizationList}
          organization={selectedOrganization}
        />
        :
        null
      }
    </Row>
  );
};

export default LaunchGuideDiv;
