import React from "react";
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import StepsContainer from '~/src/onboarding/steps';

import {
  ORGANIZATION_SIZE_10000_PLUS
} from "../constants/organization";
import { CONTACT_US_INFO } from '~/src/constants/common';
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall, GET } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import ToolTip from "../components/Tooltip";
import CTA from "~/src/components/ctaBox";
import OrgSizeCard from "~/src/components/orgSizeCard";
import Assets from '~/src/constants/assets';
import ButtonWithProgress from '~/src/components/buttonWithProgress';
import { pushToPageLayer, pushEventToDataLayer } from '~/src/utils/GA';

import { UN_INVITED } from '~/src/constants/subpage';
import ChangePlanModal from "../components/modal/changePlanModal";
import { NON_PAYMENT_ADMIN } from '~/src/constants/userTypes';
import appConfig from "~/config";
import './createOrg.scss';

const UninvitedContainer = () => {
  const { t, i18n } = useTranslation();

  const { setInitialHeader, handleAuthError, setCurrentUserType, setOrganization, appUserId, userData} = React.useContext(AuthContext);
  const [inProgress, setInProgress] = React.useState(false);

  const [show, setShow] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState('');
  const [org, setSelectedOrg] = React.useState('');

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setOrganization(result.payload[0]);
        setSelectedPlan(result.payload[0].organization_size);
        setSelectedOrg(result.payload[0]);
      }
    }
  };

  const getOrganization = () => {
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };

  React.useEffect(() => {
    window.setWindowTitle(t("launch_guide.step2_title"));
    setInitialHeader({ mainHeader: UN_INVITED, subHeader: null });
    setCurrentUserType(NON_PAYMENT_ADMIN);
    getOrganization();
  }, []);

  const changePlan = () => {
    setShow(true);
  }

  const hide = () => {
    setShow(false)
  };

  const updatePlan = (plan) => {
    const values = { ...org, organization_size: plan };
    makeAPIcall(reverse('api:organization_update', org.pk), POST, (error, result) => {
      updateOrganizationResultHandler(error, result, values, plan);
    }, values);
  };

  const updateOrganizationResultHandler = async (error, result, values, plan) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }
    } else {
      setSelectedPlan(plan);
      setShow(false);
    }
  };

  const paymentHandler = async (error, result) => {
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        window.open(result.payload.url);
      }
    }
  };

  const paymentClicked = () => {
    const requestData = {
      organization_id: org.pk,
      success_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:paymentSuccess')}`,
      cancel_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:paymentIncomplete')}`
    }
    makeAPIcall(reverse('api:payment'), POST, paymentHandler, requestData);
  } 

  return (
    <Row className="create-org-page justify-content-center align-items-start">
      <StepsContainer currentStep='step2' />

      <Col lg={12} className="org-create-page">
        <CTA
          miniPadding
          text={t("launch_guide.back_step1")}
          link={reverse('app:edit_organization')}
        >
          <div className="org-edit-title-area">
            <p className="org-edit-form-title">
              {t("launch_guide.step2_title")}
              <ToolTip text={t("launch_guide.step2_title_info")} />
            </p>
          </div>
          <div className="title-description">
            <span className="desc">{t("launch_guide.step2_desc")}</span>
          </div>
          <div className="selected-plan-container">
            <div className="selected-card">
              <div className="your-plan">{t("launch_guide.your_plan")}</div>
              {selectedPlan ?
                <OrgSizeCard selectedValue={selectedPlan || 'pilot'} selected={true} button={t('organization.button')} /> 
                :
                <div className="spinner">
                  <Spinner animation="border" role="status" />
                </div>
              }

              <div className="change-ur-plan orange">
                <a className="group-td-delete-btn orange" onClick={() => changePlan()}>
                  {t("launch_guide.choose_plan")}
                </a>
              </div>
            </div>
            {selectedPlan === ORGANIZATION_SIZE_10000_PLUS &&
              <div className="enterprise-div">
                <div className="title">{t("launch_guide.enterprise_title")}</div>
                <div className="desc">
                  {t("launch_guide.enterprise_desc1")}
                  <a
                    className="footer-links"
                    href={CONTACT_US_INFO[`STEP2_ENT_${i18n.language}`]}
                    onClick={() => pushEventToDataLayer('Onboarding Step2 - 10,000+ Members', 'Contact Us clicked', 'Contact Us', '1', appUserId)}

                  >
                    <span className="orange">{t("launch_guide.enterprise_desc2")}</span>
                  </a>
                  {t("launch_guide.enterprise_desc3")}</div>
              </div>
            }
          </div>
          {selectedPlan !== ORGANIZATION_SIZE_10000_PLUS &&
            <div className="payment-link-btn">
              <Button
                className="button-purple"
                onClick={() => paymentClicked()}
              >
                {t('launch_guide.proceed_payment')}
                <img src={Assets.EXTERNAL_LINK} />
              </Button>
            </div>
          }
        </CTA>
      </Col>

      {show && <ChangePlanModal selectedPlan={selectedPlan} onHide={hide} updatePlan={updatePlan} />}
    </Row>
  );
};

export default UninvitedContainer;
