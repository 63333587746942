import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

import {
  GUEST,
  UNVERIFIED,
  ADMIN,
  NON_ADMIN,
  LOGGED_IN,
  NON_PAYMENT_ADMIN,
  CANCELLED_SUBSCRIPTION
} from '~/src/constants/userTypes';
import NotFoundPage from '~/src/errorPages/404';
import TeamContainer from '~/src/teams/container';
import AdminContainer from '~/src/admin/container';
import GroupContainer from '~/src/groups/container';
import AuthContext from '~/src/context/authContext';
import ProfileContainer from '~/src/profile/container';
import Logout from '~/src/authenticationPages/logout';
import ProtectedRoute from '~/src/routes/protectedRoute';
import ResourceContainer from '~/src/resources/container';
import DashboardContainer from '~/src/dashboard/container';
import OrganizationContainer from '~/src/organization/container';
import UnconfirmedContainer from '~/src/unauthorized/unconfirmed';
import LoginContainer from '~/src/authenticationPages/login/loginContainer';
import RegisterContainer from '~/src/authenticationPages/register/registerContainer';
import ResetPasswordContainer from '~/src/authenticationPages/resetPassword/container';
import ChallengeReportContainer from '~/src/challengeReport/container';
import LaunchGuideContainer from '~/src/launchGuide/container';
import OnboardingContainers from '~/src/onboarding/onboardingContainer';
import CreateOrgContainer from '~/src/onboarding/createOrgContainer';
import EditOrgContainer from '~/src/onboarding/editOrgContainer';
import PaymentContainer from '~/src/onboarding/paymentContainer';
import LaunchContainer from '~/src/onboarding/launchContainer';
import PaymentIncompleteContainer from '~/src/onboarding/paymentIncompleteContainer';
import CancelledSubscriptionContainer from '~/src/unauthorized/cancelledSubscription';

// importing common css here (testing)
import "./common.scss";
require('./initializations');

const history = createBrowserHistory({ basename: "dashboard/plus/" });

const App = () => (
  <Router history={history}>
    <AuthContext.Wrapper key="main-wrapper" history={history}>
      <Switch>
        <ProtectedRoute exact path="/" component={DashboardContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/profile" component={ProfileContainer} userType={LOGGED_IN} />,
        <ProtectedRoute exact path="/login" component={LoginContainer} userType={GUEST} />,
        <ProtectedRoute path="/register" component={RegisterContainer} userType={GUEST} />,
        <ProtectedRoute path="/forgot-password" component={ResetPasswordContainer} userType={GUEST} />,
        <ProtectedRoute exact path="/organization" component={OrganizationContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/resource" component={ResourceContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/organization/groups" component={GroupContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/organization/admin" component={AdminContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/organization/teams" component={TeamContainer} userType={ADMIN} />,
        <ProtectedRoute exact path="/unconfirmed" component={UnconfirmedContainer} userType={UNVERIFIED}/>,
        <ProtectedRoute exact path="/challenge-report" component={ChallengeReportContainer} userType={ADMIN}/>,
        <ProtectedRoute exact path="/organization/launch-guide" component={LaunchGuideContainer} userType={ADMIN}/>,
        {/*Onboarding flows*/}
        <Route path="/onboarding" component={OnboardingContainers} />,
        <ProtectedRoute exact path="/create-organization" component={CreateOrgContainer} userType={NON_ADMIN}/>,
        <ProtectedRoute exact path="/edit-organization" component={EditOrgContainer} userType={NON_PAYMENT_ADMIN}/>,
        <ProtectedRoute exact path="/payment" component={PaymentContainer} userType={NON_PAYMENT_ADMIN}/>,
        <ProtectedRoute exact path="/payment-incomplete" component={PaymentIncompleteContainer} userType={NON_PAYMENT_ADMIN}/>,
        <ProtectedRoute exact path="/payment-success" component={LaunchContainer} userType={ADMIN}/>,
        <ProtectedRoute exact path="/canceled-subscription" component={CancelledSubscriptionContainer} userType={CANCELLED_SUBSCRIPTION}/>,

        <Route exact path="/logout" component={Logout} />
        <Route component={NotFoundPage} />
      </Switch>
    </AuthContext.Wrapper>
  </Router>
);

export default App;
