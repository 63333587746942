export const AUTH_ERROR = 'AUTH_ERROR';
export const PERMISSION_ERROR = 'PERMISSION_ERROR';
export const SESSION_ERROR = 'SESSION_ERROR';
export const UNCONFIRMED_USER_ERROR = 'UNCONFIRMED_USER_ERROR';


function getStandardErrorCode(errorObj) {
  const hasJsonErrorCode = Boolean(errorObj.responseJson && Array.isArray(errorObj.responseJson.errors) && errorObj.responseJson.errors.length);
  if (
    errorObj.response.status == 401 &&
    hasJsonErrorCode &&
    errorObj.responseJson.errors[0].code == 'authentication_failed') {
    return AUTH_ERROR;
  }
  if (
    errorObj.response.status == 403 &&
    hasJsonErrorCode &&
    errorObj.responseJson.errors[0].code == 'permission_denied') {
    return PERMISSION_ERROR;
  }
  if (errorObj.response && errorObj.response.statusText) {
    return errorObj.response.statusText;
  }
  return '';
}


export function isAuthRelatedError(errorObj) {
  if (
    errorObj.standardErrorCode === AUTH_ERROR ||
    errorObj.standardErrorCode === PERMISSION_ERROR ||
    errorObj.standardErrorCode === SESSION_ERROR ||
    errorObj.standardErrorCode === UNCONFIRMED_USER_ERROR
  ) {
    return true;
  }
  return false;
}

export class APIError extends Error {
  constructor(errorObj) {
    super('API Error!');
    this.response = errorObj.response;
    this.responseJson = errorObj.responseJson;
    this.standardErrorCode = getStandardErrorCode(errorObj);
  }
}

export async function handleErrors(response, download) {
  let result;
  try {
    if (download) {
      result = response;
    } else {
      result = await response.json();
    }
  } catch (error) {
    throw new APIError({response, responseJson: {}});
  }

  if (!response.ok) {
    throw new APIError({response, responseJson: result});
  }
  return result;
};
