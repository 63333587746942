export const GUEST = 'GUEST';
export const LOGGED_IN = 'LOGGED_IN';
export const UNVERIFIED = 'UNVERIFIED';
export const VERIFIED = 'VERIFIED';
export const ADMIN = 'ADMIN';
export const NON_ADMIN_STEP1 = 'NON_ADMIN_STEP1';
export const NON_ADMIN = 'NON_ADMIN';
export const NON_PAYMENT_ADMIN = 'NON_PAYMENT_ADMIN';
export const CANCELLED_SUBSCRIPTION = 'CANCELLED_SUBSCRIPTION';
export const VERIFIED_USERS = [ADMIN, NON_ADMIN];
export const LOGGED_IN_USERS = [UNVERIFIED, ...VERIFIED_USERS, NON_PAYMENT_ADMIN, CANCELLED_SUBSCRIPTION];
