import React from 'react';
import { reverse } from '~/src/api/urls';
import { GET, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from '~/src/api/errors';
import AuthContext from '~/src/context/authContext';

const ProfileContext = React.createContext({});


const ProfileContextWrapper = (props) => {
  const { handleAuthError } = React.useContext(AuthContext);
  const [userData, setUserData] = React.useState({});

  const userDataAPIResultHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setUserData(result.payload);
    }
  };

  React.useEffect(() => {
    makeAPIcall(reverse('api:user_detail'), GET, userDataAPIResultHandler);
  }, []);

  return (
    <ProfileContext.Provider
      key="profile-context"
      value={{
        userData,
        setUserData
      }}>
      {props.children}
    </ProfileContext.Provider>
  );
}

ProfileContext.Wrapper = ProfileContextWrapper;
export default ProfileContext;
