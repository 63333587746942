import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';

import { changePassword } from "~/src/utils/auth";
import { LANDING } from "../constants/subpage";
import AuthContext from '~/src/context/authContext';
import CTA from "~/src/components/ctaBox";
import Assets from '~/src/constants/assets';

const ChangePassword = (props) => {
  const { t, i18n } = useTranslation();

  const [inProgress, setInProgress] = React.useState(false);
  const { setToastMsg, userData } = React.useContext(AuthContext);
  const isSocialAccount = !!userData.provider;

  let formik;
  if (!isSocialAccount) {
    formik = useFormik({
      initialValues: {
        currentPassword: '',
        newPassword: '',
        newPasswordReTyped: ''
      },
      validationSchema: Yup.object({
        currentPassword: Yup.string().required(t('common.required_password')),
        newPassword: Yup.string().required(t('common.new_password_guide')).matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          t('common.password_suggestion_mismatch')
          //Checks for 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character
        ),
        newPasswordReTyped: Yup.string().required(t('common.required_password')).oneOf(
          [Yup.ref('newPassword'), null],
          t('common.password_dont_match'),
        ),
      }),
      onSubmit: async (values, { setFieldError }) => {
        if (isSocialAccount) {
          return;
        }

        setInProgress(true);
        try {
          await changePassword(values.currentPassword, values.newPassword);
          setToastMsg(t('auth.change_password_reset'), false);
          props.updateCurrentPage(LANDING);
        } catch (ex) {
          if (['InvalidParameterException', 'NotAuthorizedException'].includes(ex.code)) {
            setFieldError('currentPassword', t('common.password_dont_match'));
          } if (ex.code === 'InvalidPasswordException') {
            setFieldError('newPassword', t('common.password_suggestion_mismatch'));
            setFieldError('newPasswordReTyped', t('common.password_suggestion_mismatch'));
          } else {
            setFieldError('form', t('common.password_dont_match'));

          }
        }
        setInProgress(false);
      }
    });
  }

  return (
    <Col xs={12} md={5} className="d-flex justify-content-center password-change-page">
      <CTA
        className="password-change-form-area"
        text={t('auth.back_profile')}
        onClick={() => props.updateCurrentPage(LANDING)}
      >
        <div className="password-change-form-container">
          <div className="profile-form-container">
            <p className="password-form-title">{t('auth.change_my_password')}</p>
            {
              isSocialAccount ?
                <div className="social-account-warning-text">
                  {/* Simple text with formating change.
                    So included the formats in the translation itself */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('common.change_password_social_info_1'),
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t('common.change_password_social_info_2'),
                    }}
                  />
                </div>
              :
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="currentPassword">
                    <Form.Label>{t('auth.current_password')}</Form.Label>
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      disabled={isSocialAccount || inProgress}
                      autoComplete="current-password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.currentPassword}
                      className={formik.touched.currentPassword && formik.errors.currentPassword && "highlight-input"}
                    />
                    { formik.touched.currentPassword && formik.errors.currentPassword ?
                      (
                        <p className="form-error-text">{formik.errors.currentPassword}</p>
                      ) : null
                    }
                  </Form.Group>
                  <div>
                    <p className="change-form-describe" style={{marginBottom: 0}}>
                      {t('auth.change_password_description')}
                    </p>
                    <ul>
                      <li className="password-suggestion-li">
                        <span style={{ marginRight: 3 }}>•</span>{t('auth.eight_char')}
                      </li>
                      <li className="password-suggestion-li">
                        <span style={{ marginRight: 3 }}>•</span>{t('auth.special_char')}
                      </li>
                      <li className="password-suggestion-li">
                        <span style={{ marginRight: 3 }}>•</span>{t('auth.upper_char')}
                      </li>
                      <li className="password-suggestion-li">
                        <span style={{ marginRight: 3 }}>•</span>{t('auth.number_val')}
                      </li>
                      <li className="password-suggestion-li">
                        <span style={{ marginRight: 3 }}>•</span>{t('auth.lower_char')}
                      </li>
                    </ul>
                  </div>
                  <Form.Group controlId="newPassword">
                    <Form.Label>{t('auth.new_password')}</Form.Label>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      disabled={isSocialAccount || inProgress}
                      autoComplete="new-password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      className={formik.touched.newPassword && formik.errors.newPassword && "highlight-input"}
                    />
                    { formik.touched.newPassword && formik.errors.newPassword ?
                      (
                        <p className="form-error-text">{formik.errors.newPassword}</p>
                      ) : null
                    }
                  </Form.Group>
                  <Form.Group controlId="newPasswordReTyped">
                    <Form.Label>{t('auth.new_password_again')}</Form.Label>
                    <Form.Control
                      type="password"
                      name="newPasswordReTyped"
                      disabled={isSocialAccount || inProgress}
                      autoComplete="new-password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPasswordReTyped}
                      className={formik.touched.newPasswordReTyped && formik.errors.newPasswordReTyped && "highlight-input"}
                    />
                    { formik.touched.newPasswordReTyped && formik.errors.newPasswordReTyped ?
                      (
                        <p className="form-error-text">{formik.errors.newPasswordReTyped}</p>
                      ) : null
                    }
                  </Form.Group>
                  <Form.Row>
                    <Col sm={12}>
                      { formik.submitCount > 0 && !formik.isValid ?
                        <span className="form-final-error-text">
                          <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                          {t('common.correct_errors_above')}
                        </span>
                        : null
                      }
                    </Col>
                    <Col sm={12}>
                      <Button
                        disabled={isSocialAccount || inProgress}
                        variant="primary"
                        type="submit"
                        className="button-purple"
                      >
                        {t('auth.update_password')}
                        {
                          inProgress &&
                          <Spinner animation="border" role="status">
                            <span className="sr-only">{t('dashboard.loading')}</span>
                          </Spinner>
                        }
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
            }
          </div>
        </div>
      </CTA>
    </Col>
  );
};

export default ChangePassword;
