import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Col, Row, Spinner, Overlay } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ORGANIZATION_SIZE_CHOICES,
  PROVINCE_CHOICES,
  HEAR_ABOUT_US_CHOICES_EN,
  HEAR_ABOUT_US_CHOICES_FR,
  LANGUAGE_DROPDOWN_EN,
  LANGUAGE_DROPDOWN_FR
} from "../constants/organization";
import { pushToPageLayer, pushEventToDataLayer } from "~/src/utils/GA";
import { CONTACT_US_INFO } from "~/src/constants/common";
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall } from "~/src/api/utils";
import AuthContext from "~/src/context/authContext";
import ToolTip from "../components/Tooltip";
import CTA from "~/src/components/ctaBox";
import OrgSizeCard from "~/src/components/orgSizeCard";
import Assets from "~/src/constants/assets";
import FormikErrorMessage from "~/src/components/Formik/errorMessage";
import ButtonWithProgress from "~/src/components/buttonWithProgress";
import { NON_PAYMENT_ADMIN } from "~/src/constants/userTypes";
import { UN_INVITED } from "~/src/constants/subpage";
import StepsContainer from "~/src/onboarding/steps";

import "./createOrg.scss";

const CreateOrgContainer = (props) => {
  const { t, i18n } = useTranslation();

  const {
    setInitialHeader,
    setToastMsg,
    handleAuthError,
    setCurrentUserType,
    appUserId,
    showErrorOnTouch = true,
  } = React.useContext(AuthContext);
  const [inProgress, setInProgress] = React.useState(false);

  const [selectedPlan, setSelectedPlan] = React.useState("");

  // items who render field
  const itemsRenderField = ["Other", "Autre"];

  // state save item selected
  const [itemSelected, setItemSelected] = useState(undefined);

  React.useEffect(() => {
    window.setWindowTitle(t("launch_guide.org_creation"));
    setInitialHeader({ mainHeader: UN_INVITED, subHeader: null });
  }, []);

  const changePlan = () => {
    setShow(true);
  };

  const hide = () => {
    setShow(false);
  };

  const existingCodes = React.useRef([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      invite_code: "",
      organization_size: "",
      address_line_1: "",
      address_line_2: "",
      city_name: "",
      province_code: "",
      postal_code: "",
      hear_about_us: "",
      other: "",
      language_dropdown: "",
      language_preference: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t("organization.name_required")),
      address_line_1: Yup.string()
        .trim()
        .required(t("organization.address_line_1_required")),
      city_name: Yup.string().trim().required(t("organization.city_required")),
      organization_size: Yup.string()
        .trim()
        .required(t("organization.org_size_required")),
      province_code: Yup.string()
        .trim()
        .required(t("organization.province_required")),
      hear_about_us: Yup.string()
        .trim()
        .required(t("organization.hear_about_us_required")),
      language_dropdown: Yup.string()
        .trim()
        .required(t("organization.language_dropdown_required")),
      postal_code: Yup.string()
        .trim()
        .required(t("organization.postal_required"))
        .matches(
          /^[^DFIOQUWZ][0-9][^DFIOQU] ?[0-9][^DFIOQU][0-9]$/,
          t("organization.post_code_invalid")
          // Valid postal code rule for Canada:
          // ABCEGHJKLMNPRSTVXY <-- letter used
          // DFIOQU <-- letters not used because it mixes up the reader
          // WZ     <-- letters used but not in the first letter
          // Eg: M6K 3P6 or M6K3P6
        ),
    }),
    validate: (values) => {
      let errors = {};
      let otherText = '';
      {i18n.language === 'en' ? otherText = 'Other' : otherText = 'Autre'}
      if (existingCodes.current.includes(values.invite_code)) {
        errors.invite_code = t("organization.code_taken");
      }
      if(values["hear_about_us"] !== otherText) {
        values["other"] = "";
      }else {
        if(values["other"] == "") {
            errors.other = handleError();
        }
      }
      return errors;
    },
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      itemSelected = "";
      values["invite_code"] = values.name;
      values["language_preference"] = values["language_dropdown"];
      makeAPIcall(
        reverse("api:organization_create"),
        POST,
        (error, result) => {
          updateOrganizationResultHandler(error, result, values, setFieldError);
        },
        values
      );
    },
  });

  const handleError = () => {
    otherField = document.getElementsByTagName('input');
    let errorMsg = '';
    for (var i = 0; i < otherField.length; ++i) {
      var newErrorMsg = '';
      if(otherField[i].name === 'other') {
        if(otherField[i].value != '') {
          errorMsg = newErrorMsg;
        }else {
          {i18n.language === "en" ? newErrorMsg = "Description is required." : newErrorMsg = "Une description est requise."};
          errorMsg = newErrorMsg;
        }
      } 
    }
    return errorMsg;
  }

  const updateOrganizationResultHandler = async (
    error,
    result,
    values,
    setFieldError
  ) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }

      const hasJsonErrorCode = Boolean(
        result.responseJson &&
          Array.isArray(result.responseJson.errors) &&
          result.responseJson.errors.length
      );
      if (result.response.status == 400 && hasJsonErrorCode) {
        result.responseJson.errors.forEach((err) => {
          if (err.code == "duplicate_invite_code") {
            setFieldError("invite_code", t("organization.code_taken"));
            existingCodes.current.push(values.invite_code);
          } else {
            setFieldError(err.field, err.message);
          }
        });
      }
    } else {
      setCurrentUserType(NON_PAYMENT_ADMIN);
      props.history.push(reverse("app:payment"));
      setToastMsg(t("launch_guide.org_saved"), false);
    }
  };

  return (
    <Row className="create-org-page justify-content-center align-items-start">
      <StepsContainer currentStep="step1" />
      <Col lg={12} className="org-create-page">
        <CTA miniPadding className="content">
          <div className="are-you-sure">
            <div className="icon-container">
              <img className="card-icon" src={Assets.INFO_ICON_I} />
            </div>
            <div className="description-container">
              <div className="title">{t("launch_guide.are_you_sure")}</div>
              <div className="desc">
                {t("launch_guide.are_you_sure_desc1")}
                <a
                  className="footer-links"
                  href={CONTACT_US_INFO[`STEP1_${i18n.language}`]}
                  onClick={() =>
                    pushEventToDataLayer(
                      "Onboarding Step1",
                      "Contact Us clicked",
                      "Contact Us",
                      "1",
                      appUserId
                    )
                  }
                >
                  <span className="contact-us">
                    {t("launch_guide.contact_us")}
                  </span>
                </a>
                {t("launch_guide.are_you_sure_desc2")}
              </div>
            </div>
          </div>
          <div className="organization-container ">
            <Form onSubmit={formik.handleSubmit}>
              <div className="org-edit-title-area">
                <p className="org-edit-form-title">
                  {t("launch_guide.org_creation")}
                </p>
              </div>
              <div className="title-description">
                <span className="note">{t("launch_guide.please_note")}</span>
                <span className="desc">{t("launch_guide.note_desc")}</span>
              </div>
              <p className="mandatory desc">
                {t("launch_guide.all_mandatory")}
              </p>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="input-label">
                  {t("launch_guide.org_name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  disabled={inProgress}
                  placeholder={t("launch_guide.name_placeholder")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={
                    formik.touched.name &&
                    formik.errors.name &&
                    "highlight-input"
                  }
                />
                <FormikErrorMessage formik={formik} fieldName="name" />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label className="input-label">
                  {t("launch_guide.org_size")}
                  <ToolTip text={t("launch_guide.org_size_info")} />
                </Form.Label>
                <div className="org-sizes">
                  {/*<Select value={selectOrg} defaultValue={ defaultOrgSize[0]}  onChange={handleInputChange} options={ORGANIZATION_SIZE_CHOICES} />*/}
                  {ORGANIZATION_SIZE_CHOICES.map((data, index) => {
                    if (data.value === "pilot") return;
                    return (
                      <OrgSizeCard
                        key={index}
                        selectedValue={data.value}
                        onSelect={() => {
                          setSelectedPlan(data.value);
                          formik.setFieldValue("organization_size", data.value);
                        }}
                        selected={data.value === selectedPlan}
                      />
                    );
                  })}
                </div>
                <Form.Control
                  type="text"
                  readOnly
                  style={{ display: "none" }}
                  name="organization_size"
                  value={selectedPlan}
                />
                <FormikErrorMessage
                  formik={formik}
                  fieldName="organization_size"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="input-label">
                  {t("launch_guide.address_1")}
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={inProgress}
                  name="address_line_1"
                  placeholder={t("launch_guide.address_1_placeholder")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line_1}
                  className={
                    formik.touched.address_line_1 &&
                    formik.errors.address_line_1 &&
                    "highlight-input"
                  }
                />
                <FormikErrorMessage
                  formik={formik}
                  fieldName="address_line_1"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="input-label">
                  {t("launch_guide.address_2")} ({t("launch_guide.optional")})
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={inProgress}
                  name="address_line_2"
                  placeholder={t("launch_guide.address_2_placeholder")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_line_2}
                  className={
                    formik.touched.address_line_2 &&
                    formik.errors.address_line_2 &&
                    "highlight-input"
                  }
                />
                <FormikErrorMessage
                  formik={formik}
                  fieldName="address_line_2"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="input-label">
                  {t("launch_guide.city")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="city_name"
                  placeholder={t("launch_guide.city_placeholder")}
                  disabled={inProgress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city_name}
                  className={
                    formik.touched.city_name &&
                    formik.errors.city_name &&
                    "highlight-input"
                  }
                />
                <FormikErrorMessage formik={formik} fieldName="city_name" />
              </Form.Group>
              <Form.Row>
                <Col sm>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="input-label">
                      {t("launch_guide.province")}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="province_code"
                      placeholder={t("launch_guide.province_placeholder")}
                      disabled={inProgress}
                      onChange={formik.handleChange}
                      value={formik.values.province_code}
                    >
                      {PROVINCE_CHOICES.map((item) => (
                        <option key={item.value || 0} value={item.value}>
                          {!item.value ? t(item.label) : item.label}
                        </option>
                      ))}
                    </Form.Control>
                    <FormikErrorMessage
                      formik={formik}
                      fieldName="province_code"
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group controlId="formOther">
                    <Form.Label className="input-label">
                      {t("launch_guide.postal")}
                    </Form.Label>
                    <Form.Control
                      name="postal_code"
                      placeholder={t("launch_guide.postal_placeholder")}
                      disabled={inProgress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postal_code}
                      className={
                        formik.touched.postal_code &&
                        formik.errors.postal_code &&
                        "highlight-input"
                      }
                    />
                    <FormikErrorMessage
                      formik={formik}
                      fieldName="postal_code"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col sm={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="input-label">
                      {t("launch_guide.language_dropdown")}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="language_dropdown"
                      placeholder={t("launch_guide.language_dropdown_placeholder")}
                      disabled={inProgress}
                      onChange={formik.handleChange}
                      value={formik.values.language_dropdown}
                      type={(itemSelected = formik.values.language_dropdown)}
                    >
                      {i18n.language === "en"
                        ? LANGUAGE_DROPDOWN_EN.map((item) => (
                            <option key={item.value || 0} 
                            value={item.value}
                            >
                              {!item.value ? t(item.label) : item.label}
                            </option>
                          ))
                        : LANGUAGE_DROPDOWN_FR.map((item) => (
                            <option key={item.value || 0} value={item.value}>
                              {!item.value ? t(item.label) : item.label}
                            </option>
                          ))}
                    </Form.Control>
                    <FormikErrorMessage
                      formik={formik}
                      fieldName="language_dropdown"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="input-label">
                      {t("launch_guide.hear_about_us")}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="hear_about_us"
                      placeholder={t("launch_guide.hear_about_us_placeholder")}
                      disabled={inProgress}
                      onChange={formik.handleChange}
                      value={formik.values.hear_about_us}
                      type={(itemSelected = formik.values.hear_about_us)}
                    >
                      {i18n.language === "en"
                        ? HEAR_ABOUT_US_CHOICES_EN.map((item) => (
                            <option key={item.value || 0} 
                            value={item.value}
                            >
                              {!item.value ? t(item.label) : item.label}
                            </option>
                          ))
                        : HEAR_ABOUT_US_CHOICES_FR.map((item) => (
                            <option key={item.value || 0} value={item.value}>
                              {!item.value ? t(item.label) : item.label}
                            </option>
                          ))}
                    </Form.Control>
                    <FormikErrorMessage
                      formik={formik}
                      fieldName="hear_about_us"
                    />
                  </Form.Group>
                </Col>  
              </Form.Row>

              <Form.Row>
                {/* check if itemslected authorize render */}
                {itemsRenderField.find((e) => e === itemSelected) && (
                  <Col sm={6}>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Label className="input-label">
                        {t("launch_guide.other")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={inProgress}
                        name="other"
                        placeholder={t("launch_guide.other_placeholder")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.other}
                        className={
                          formik.touched.other &&
                          formik.errors.other &&
                          "highlight-input"
                        }
                      />
                      <FormikErrorMessage formik={formik} fieldName="other" />
                    </Form.Group>
                  </Col>
                )}
              </Form.Row>

              <Form.Row>
                <Col sm={12}>
                  {formik.submitCount > 0 && !formik.isValid ? (
                    <span className="form-final-error-text">
                      <img
                        src={Assets.ErrorIcon}
                        alt="error-icon"
                        className="final-error-icon"
                      />
                      {t("common.correct_errors_above")}
                    </span>
                  ) : null}
                </Col>
                <Col sm={12}>
                  <div className="org-edit-save-btn-area">
                    <ButtonWithProgress
                      inProgress={inProgress}
                      text={t("launch_guide.create")}
                    />
                  </div>
                </Col>
              </Form.Row>
            </Form>
          </div>
        </CTA>
      </Col>
    </Row>
  );
};

export default CreateOrgContainer;
