import React from "react"; 
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

import { LOGOUT, profile_link_array,
manage_org_array, PROFILE_LANDING_PAGE, ORGANIZATION_LANDING_PAGE,
GROUPS_LANDING_PAGE, TEAMS_LANDING_PAGE, DASHBOARD_LANDING_PAGE,
RESOURCE_LANDING_PAGE, PROFILE_VIEW, ORGANIZATION_VIEW, CHALLENGE_REPORT_LANDING_PAGE } from "~/src/constants/subpage";
import { ADMIN, GUEST } from "~/src/constants/userTypes";
import { reverse } from "~/src/api/urls";

function ResponsiveNav(props) {
  const { t, i18n } = useTranslation();

  const { onHoverMenuClick, onHeaderMenuClick, currentUserType, open, onManageSubscription} = props;

  return (
    <React.Fragment>
      <div className= { open ? "responsive-nav-links-area container-fluid" : "in-active"} >
        <div className={open ? "responsive-navlink-container " : "in-active"}>
          { currentUserType === ADMIN &&
            <React.Fragment>
              <Link to={reverse("app:home_page")} className="link-title" onClick={() => onHeaderMenuClick(DASHBOARD_LANDING_PAGE, null)}>
                {t("common.dashboard_menu")}
              </Link>
              <Link to={reverse("app:organization_page")} className="link-title" onClick={() => onHeaderMenuClick(ORGANIZATION_LANDING_PAGE, ORGANIZATION_VIEW)}>
                {t("common.organization_menu")}
              </Link>
              {manage_org_array.map((option) => {
                if (option.isExternalLink) {
                  return(
                    <Link className="sublink-title" key={option.name} onClick={() => onManageSubscription()}>
                      {t(option.name)}
                    </Link>
                  );
                } else {
                  return (
                    <Link className="sublink-title" key={option.name} to={option.link} onClick={() => onHoverMenuClick(option)}>
                      {t(option.name)}
                    </Link>
                  );
                }
              })}
              <Link to={reverse("app:challenge_report")}  className="link-title" onClick={() => onHeaderMenuClick(CHALLENGE_REPORT_LANDING_PAGE, null)}>
                {t("common.challenge_report_menu")}
              </Link>
              <Link to={reverse("app:resources")}  className="link-title" onClick={() => onHeaderMenuClick(RESOURCE_LANDING_PAGE, null)}>
                {t("common.resource_menu")}
              </Link>
            </React.Fragment>
          }
          { currentUserType !== GUEST &&
            <React.Fragment>
              <Link to={reverse("app:profile_page")} onClick={() => onHeaderMenuClick(PROFILE_LANDING_PAGE, PROFILE_VIEW)} className="link-title">
                {t("common.profile_menu")}
              </Link>
              {profile_link_array.map((option) => {
                return (
                  <Link key={option.name} className="sublink-title" to={option.link} onClick={() => onHoverMenuClick(option)}>
                    {t(option.name)}
                  </Link>
                );
              })}
            </React.Fragment>
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResponsiveNav;
