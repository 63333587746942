import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import CTA from '~/src/components/ctaBox';
import SocialLoginButton from '~/src/components/socialLoginButton';

import { reverse } from '~/src/api/urls';
import AuthContext from '~/src/context/authContext';
import { LANDING } from '~/src/constants/subpage';
import Assets from '~/src/constants/assets';

import EmailRegisterPage from './emailRegister';
import '../style.scss';

const RegisterContainer = () => {
  const { t, i18n } = useTranslation();

  const { currentPage, setCurrentPage, inviteCode, setCurrentLandingPage } = React.useContext(AuthContext);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  
  React.useEffect(() => {
    window.setWindowTitle(t('auth.register_now'));
    setCurrentPage(LANDING);
    setCurrentLandingPage('register');
  }, []);

  return (
    <Row className="auth-container align-items-center">
      <Col xs={12} md={6} className="d-flex justify-content-center">
        {currentPage === LANDING && (
          <CTA
            link={reverse('app:home_page')}
            text={t('auth.back_home')}
            className="register-social-page"
          >
            <div className="register-form-container">
              <p className="register-form-title">{t('auth.create_your_acc')}</p>
              <span className="form-describe">
                {t('auth.register_description1')}
                <img src={Assets.PacImage} alt="pac-icon"  className="pac-icon"/>
                {t('auth.register_description2')}
              </span>
              <Link to={reverse('app:login_page')} className="login-link">
                {t('auth.try_logging')}
              </Link>
              <div className="register-links-container">
                <div className="social-links">
                  <div>
                    <SocialLoginButton
                      onClick={() => {
                        Auth.federatedSignIn({
                          provider: CognitoHostedUIIdentityProvider.Apple,
                          customState: JSON.stringify({
                            inviteCode: inviteCode,
                          }),
                        });
                      }}
                      text={t('auth.register_apple')}
                      imagePath={Assets.AppleIcon}
                    />
                  </div>
                  <div>
                    <SocialLoginButton
                      onClick={() => {
                        Auth.federatedSignIn({
                          provider: CognitoHostedUIIdentityProvider.Google,
                          customState: JSON.stringify({
                            inviteCode: inviteCode,
                          }),
                        });
                      }}
                      text={t('auth.register_google')}
                      imagePath={Assets.GmailIcon}
                    />
                  </div>
                  {/* hidden as per comment on Figma. */}
                  {/* <div> */}
                  {/*   <SocialLoginButton */}
                  {/*     onClick={() => { */}
                  {/*       Auth.federatedSignIn({ */}
                  {/*         provider: CognitoHostedUIIdentityProvider.Facebook, */}
                  {/*       }); */}
                  {/*     }} */}
                  {/*     text={t('auth.register_fb')} */}
                  {/*     imagePath={Assets.FBIcon} */}
                  {/*   /> */}
                  {/* </div> */}
                  <div>
                    <SocialLoginButton
                      onClick={() => setCurrentPage('email')}
                      text={t('auth.register_email')}
                      imagePath={Assets.EmailIcon}
                    />
                  </div>
                </div>
                <div>
                  <span>{t('auth.Or_text')}</span>
                  <Link to={reverse('app:login_page')} className="login-link">
                    {t('auth.login_text')}
                  </Link>
                </div>
              </div>
            </div>
          </CTA>
        )}
        {currentPage === 'email' && <EmailRegisterPage setCurrentPage={setCurrentPage} />}
      </Col>
    </Row>
  );
}


export default RegisterContainer;
