import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Config from '~/config';

Amplify.configure({
  Auth: {

    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'ca-central-1:6d9XXXXX8d37f5',
    // identityPoolId: 'ca-central-1:7dXXXX1e20b',

    // REQUIRED - Amazon Cognito Region
    region: Config.region,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: Config.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: '585dXXXX4263rhi6o',
    userPoolWebClientId:  Config.clientId,
    // userPoolWebClientId: '39epvXXXXXde30ndqi', // Google Client -> QA

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,
    mandatorySignIn: true,

    /*
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: "strict" | "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        // secure: true
        secure: false
    },
    */

    // OPTIONAL - customized storage object
    storage: localStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: Config.cognitoHostedUIDomain,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      /* enable for local testing */
      // redirectSignIn: 'http://localhost:8000/dashboard/plus/login',
      // redirectSignOut: 'http://localhost:8000/dashboard/plus/logout',

      /* enable for all other Envs */
      redirectSignIn: `${window.location.origin}/dashboard/plus/login`,
      redirectSignOut: `${window.location.origin}/dashboard/plus/logout`,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      // responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});

// You can get the current config object
const currentConfig = Auth.configure();
export default currentConfig;
