import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalBox from "./index";
import './style.scss';

function GenericModal(props) {
  const {
    title,
    desc,
    onClose,
    okButtonTitle,
    okButtonOnClick,
    showIcon,
    exclamationIcon,
  } = props;

  return (
    <ModalBox
      className="modal-container"
      onHide={onClose}
      showicon={showIcon}
      exclamationicon={exclamationIcon}
    >
      <React.Fragment>
        {title && <h4 className="modal-title">{title}</h4>}
        {
          desc &&
          <p className="describe-text-1">
            {desc}
          </p>
        }

        <div className="button-area" style={{ textAlign: "center" }}>
        {
          okButtonTitle &&
            <Button
              size="lg"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onClick={okButtonOnClick}
              className="button-purple"
            >
              {okButtonTitle}
            </Button>
        }
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

GenericModal.defaultProps = {
  showIcon: true,
  exclamationIcon: true,
};

export default GenericModal;
