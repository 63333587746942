import appConfig from "~/config";


const urls = {
  // List your URL configurations here.

  /*-------------------------------------------------------------------------------*/
  // APIs
  /*-------------------------------------------------------------------------------*/
  // User APIs
  'api:user_detail': (base) => `${base}/api/user/`,
  'api:user_edit': (base) => `${base}/api/user/update/`,
  'api:user_update_token': (base) => `${base}/api/user/update-token/`,
  'api:resend_confirmation_mail': (base) => `${base}/api/user/send-confirmation-mail/`,
  'api:update_login': (base) => `${base}/api/user/update-login-time/`,


  // Admin Invite APIs
  'api:admin_invite_accept': (base, inviteCode) => `${base}/api/team/admin/invites/accept/${inviteCode}/`,
  'api:admin_invite_status': (base, inviteCode) => `${base}/api/team/admin/invites/status/${inviteCode}/`,
  'api:admin_invite_list': (base) => `${base}/api/team/admin/invites/`,
  'api:admin_invite_create': (base) => `${base}/api/team/admin/invites/create/`,
  'api:admin_invite_send': (base, inviteId) => `${base}/api/team/admin/invites/send-invite/${inviteId}/`,
  'api:admin_invite_delete': (base, inviteId) => `${base}/api/team/admin/invites/${inviteId}/remove/`,
  // Admin APIs
  'api:admin_member_list': (base) => `${base}/api/team/admin/members/`,
  'api:admin_member_update': (base, adminId) => `${base}/api/team/admin/members/${adminId}/update/`,
  'api:admin_member_delete': (base, adminId) => `${base}/api/team/admin/members/${adminId}/remove/`,
  // Group APIs
  'api:group_list': (base) => `${base}/api/organization/groups/`,
  'api:group_list_new': (base, orgId) => `${base}/api/organization/organizations/${orgId}/groups`,
  'api:group_create': (base) => `${base}/api/organization/groups/create/`,
  'api:group_update': (base, groupId) => `${base}/api/organization/groups/${groupId}/update/`,
  'api:group_delete': (base, groupId) => `${base}/api/organization/groups/${groupId}/remove/`,
  // Organization APIs
  'api:organization_list': (base) => `${base}/api/organization/organizations/`,
  'api:organization_list_user': (base, userId) => `${base}/api/organization/user/${userId}/`,
  'api:organization_update': (base, orgId) => `${base}/api/organization/organizations/${orgId}/update/`,
  'api:organization_create': (base) => `${base}/api/organization/organizations/create/`,
  'api:organization_size_update': (base, orgId) => `${base}/api/organization/organizations/plan/${orgId}/update/`,
  'api:organization_checklist_list': (base, orgId) => `${base}/api/organization/organizations/checklist/${orgId}/`,
  'api:organization_checklist_update': (base, orgId) => `${base}/api/organization/organizations/checklist/${orgId}/update/`,
  // Teams APIs
  'api:team_list': (base, orgId) => `${base}/api/organization/organizations/${orgId}/teams/`,
  'api:team_delete': (base, teamId) => `${base}/api/organization/organizationteam/${teamId}/remove/`,
  // Resource API
  'api:resources': (base) => `${base}/api/content/resources/`,
  'api:resource_download': (base, id) => `${base}/api/content/resources/${id}`,
  // Challenge Report API
  'api:report_list': (base) => `${base}/api/external/challenges/`,
  'api:report_detail': (base, challengId, orgId) => `${base}/api/data/report/challenge/${challengId}/organization/${orgId}/`,
  // Payment
  'api:payment': (base) => `${base}/api/payment/stripe/checkout-session/create/`,
  'api:manage_subscription': (base) => `${base}/api/payment/stripe/management-session/create/`,
  'api:subscription_detail': (base, orgId) => `${base}/api/payment/stripe/subscriptions/${orgId}`,

  // App
  'app:home_page': () => `/`,
  'app:login_page': () => `/login`,
  'app:logout_page': () => `/logout`,
  'app:register_page': () => `/register`,
  'app:resources': () => `/resource`,
  'app:challenge_report': () => `/challenge-report`,
  'app:unconfirmed_page': () => `/unconfirmed`,
  'app:profile_page': () => `/profile`,
  'app:launch_guide_page': () => `/organization/launch-guide`,
  'app:organization_page': () => `/organization`,
  'app:admin_page': () => `/organization/admin`,
  'app:group_page': () => `/organization/groups`,
  'app:team_page': () => `/organization/teams`,
  'app:reset_password_page': () => `/forgot-password`,
  'app:unconfirmed': () => `/unconfirmed`,
  'app:createOrg': () => `/create-organization`,
  'app:edit_organization': () => `/edit-organization`,
  'app:payment': () => `/payment`,
  'app:paymentIncomplete': () => `/payment-incomplete`,
  'app:paymentSuccess': () => `/payment-success`,
  'app:canceled_subscription': () => `/canceled-subscription`,
  'app:step1': () => `/step1`,

  // dashboard
  'api:dashboard:all_time_metrics_total_org_members': (base, orgId) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__members/latest`,
  'api:dashboard:all_time_metrics_pulse_check__count__teams': (base, orgId) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__teams/latest`,
  'api:dashboard:all_time_metrics_avg_move_per_member': (base, orgId) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__avg__move_mins_per_member/latest`,
  'api:dashboard:all_time_metrics_user_with_fitness_trackers': (base, orgId) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__members_with_fitness_trackers/latest`,
  'api:dashboard:organization_ativity_breakdown': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/breakdown?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:organization_ativity_breakdown_header_move_minutes': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__tracked_any_move_minutes/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:organization_ativity_breakdown_header_active_minutes': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__tracked_any_active_minutes/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:organization_ativity_breakdown_header_steps': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__tracked_any_steps/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:organization_ativity_breakdown_header_streak': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__completed_any_streak_badges/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:graph_active_challenges': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__team_challenges/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:graph_avg_minutes': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__avg__move_minutes/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:graph_app_user_usage': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__app_opens/range?start_at=${startAt}&end_at=${endAt}`,
  'api:dashboard:organization_ativity_breakdown_header_count_members': (base, orgId, startAt, endAt) => `${base}/api/data/pulsecheck/organizations/${orgId}/stats/pulse_check__count__members/range?start_at=${startAt}&end_at=${endAt}`,
  'api:get_providers_for_email': (base, email) => `${base}/api/user/get-email-provider/${encodeURIComponent(email)}/`,
  'api:dashboard:active_challenges': (base, orgId, startAt, endAt) => `${base}/api/data/organizations/${orgId}/count/team-challenges/range?start_at=${startAt}&end_at=${endAt}`,
};

export const url_module = {
    // Initialize base properties
  init(base) {
    return function reverse(name, ...args) {
      try {
        return urls[name](base, ...args);
      } catch (err) {
        throw `Missing a URL on the front-end: "${name}"\n\n\t${err}`
      }
    }
  }
};

// Lets initialize the module with our base URL
export const reverse = url_module.init(appConfig.apiUrl);

// -------------------------------------------------------------------------------------
// Example Usage
// -------------------------------------------------------------------------------------
// reverse('api:user_detail')
// reverse('api:user_edit', 1)
