import React from "react";
import { Container, Button, Table, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { pushToPageLayer } from '~/src/utils/GA';

import { EDIT, LANDING, ADD, ORGANIZATION_LANDING_PAGE, GROUPS_LANDING_PAGE } from "../constants/subpage";
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, DELETE, makeAPIcall } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import GroupContext from "~/src/context/groupContext";
import Assets from '~/src/constants/assets';
import { Link } from "react-router-dom";
import EditGroup from "./edit";
import AddGroup from "./add";
import ReusableContent from "../components/reusableTitleContent";
import DeleteModal from "../components/modal/deleteModal";

const ListGroups = () => {
  const { t, i18n } = useTranslation();

  const { handleAuthError, currentPage, setInitialHeader, setToastMsg } = React.useContext(AuthContext);
  const { groups, setSelectedGroup, resetGroupData, isLoading, setIsLoading } = React.useContext(GroupContext);

  const [selectedPage, setSelectedPage] = React.useState(currentPage);
  const [currentGroup, setCurrentGroup] = React.useState({});
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    // To set the header component
    window.setWindowTitle(t('groups.manage_groups'));
    pushToPageLayer(t('groups.manage_groups'));
    setInitialHeader({ mainHeader: ORGANIZATION_LANDING_PAGE, subHeader: GROUPS_LANDING_PAGE });
  }, []);

  const handleEditClick = (group) => {
    setSelectedGroup(group);
    setSelectedPage(EDIT);
  };

  const updateCurrentPage = (subpage) => {
    setSelectedPage(subpage);
  };

  const deleteGroupResultHandler = async (error, result) => {
    setIsLoading(false);
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("groups.group_deleted"), false);
      resetGroupData();
      setShow(false);
    }
  };

  const deleteGroup = () => {
    setIsLoading(true);
    makeAPIcall(reverse('api:group_delete', currentGroup.pk), DELETE, deleteGroupResultHandler);
  };

  const handleDeleteClick = (group) => {
    setCurrentGroup(group);
    setShow(true);
    setSelectedPage(LANDING);
  };

  const hide = () => {
    setShow(false)
  };

  const groups_content_data = {
    describeText: t("groups.manage_groups_description"),
    contentTitle: t("groups.manage_groups"),
    LinkPath: "/group-add",
    linkText: t("groups.add_a_group"),
  };

  const renderActionLinks = (group) => {
    if (!group.is_default) {
      return (
        <React.Fragment>
          <td className="group-td-action-area">
            <a
              className="group-td-edit-btn"
              variant="link"
              onClick={() => handleEditClick(group)}
            >
              {t("groups.edit")}
            </a>
            <a
              className="group-td-delete-btn"
              onClick={() => handleDeleteClick(group)}
            >
              {t("groups.delete_btn")}
            </a>
          </td>
        </React.Fragment>
      );
    }
  }

  const renderIsDefaultTr = () => {
    if (groups) {
      const checkIsDefaultObj = groups.find(o => o.is_default === true);
      if(checkIsDefaultObj) {
        return (
          <tr>
            <td>{checkIsDefaultObj.name}</td>
            <td>{t("groups.group_deafult_decription")}</td>
            <td className="text-center">{checkIsDefaultObj.member_count}</td>
            <td></td>
          </tr>
        )
      } else 
      return (
        null
      )
    }
  }

  const renderGroups = () => {
    if (groups) {
      return (
        groups.map((group, index) => (
          <tr key={index}>
           {!group.is_default && <td>{group.name}</td>}
            {!group.is_default && <td>{group.description.length > 35 ? `${group.description.substring(0, 35)}...` : group.description }</td>}
            {!group.is_default && <td className="text-center">{group.member_count}</td>}
            {renderActionLinks(group)}
          </tr>
        ))
      );
    }
  }

  return (
      <Row className="manage-groups-container justify-content-center">
        {selectedPage === LANDING && (
          <Container className="group-page">
            <Row>
              <Col xs={12} md={9} className="header-description">
                <ReusableContent
                  content_data={groups_content_data}
                  showLink={false}
                  describeStyle={{marginBottom: 27}}
                />
              </Col>
              <Col xs={12} md={3}>
                <div className="add-group-button-area"> <Button
                  className="button-purple"
                  onClick={() => updateCurrentPage(ADD)}
                >
                  {t("groups.add_a_group")}
                </Button></div>
              </Col>
            </Row>
            <Row>
              <Col className="overview-list">
                <div className="custom-table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width="20%">{t("groups.group_name_title")}</th>
                        <th width="40%">{t("groups.description")}</th>
                        <th width="20%" className="text-center">{t("groups.members")}</th>
                        <th width="20%" className="text-center">{t("groups.action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading &&
                          <tr>
                            <td colSpan={4}>
                              <Col align="center">
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">{t("dashboard.loading")}</span>
                                </Spinner>
                              </Col>
                            </td>
                          </tr>
                      }
                      {renderIsDefaultTr()}
                      {renderGroups()}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row className="tip-row justify-content-center">
              <Col xs={12} lg={6} md={7} sm={11} className="tip">
                <div className="icon">
                  <img
                    className="card-icon"
                    src={Assets.BulbIcon}
                  />
                </div>
                <div className="description">
                  <div className="title">{t("groups.group_name")}</div>
                  <div className="subtitle">{t("groups.group_name_subtitle")}</div>
                  <ul className="subtitle">
                    <li>{t("groups.point1")}</li>
                    <li>{t("groups.point2")}</li>
                    <li>{t("groups.point3")}</li>
                    <li>{t("groups.point4")}</li>
                    <li>{t("groups.point5")}</li>
                  </ul>
                  <div className="title">{t("groups.group_used_1")}</div>
                  <div className="subtitle">{t("groups.group_used_subtitle_1")}</div>
                </div>
              </Col>
              <Col className={'image-div'}>
                <div className="icon">
                  {i18n.language === 'en' ?
                    <img
                      className="card-icon"
                      src={Assets.PnoneEn}
                    />
                    :
                    <img
                      className="card-icon"
                      src={Assets.PnoneFr}
                    />
                  }
                </div>
              </Col>
            </Row>
          </Container>
        )}
        {selectedPage === ADD && <AddGroup updateCurrentPage={updateCurrentPage}/>}
        {selectedPage === EDIT && <EditGroup updateCurrentPage={updateCurrentPage} handleDeleteClick={handleDeleteClick}/>}
        {show && <DeleteModal disabled={isLoading} onDeleteClick={deleteGroup} onHide={hide}/>}
      </Row>
  );
};

export default ListGroups;
