import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import CTA from "~/src/components/ctaBox";
import { PAC_EMAIL } from '~/src/constants/common';
import { UN_CONFIRMED } from '~/src/constants/subpage';
import { reverse } from '~/src/api/urls';
import { POST, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import './unconfirmed.scss';

const UnconfirmedContainer = (props) => {
  const { t, i18n } = useTranslation();
  const {
    setToastMsg,
    handleAuthError,
    setInitialHeader,
    refreshUser,
    userData,
  } = React.useContext(AuthContext);
  const [email, setEmail] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);

  const resendConfirmationResultHandler = async (error, result) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t('auth.unconfirmed_toaster_msg'), false)
    }
  };

  const resendMail = () => {
    setInProgress(true);
    const requestData = { id_token: window._idToken || window._accessToken };
    makeAPIcall(reverse('api:resend_confirmation_mail'), POST, resendConfirmationResultHandler, requestData);
  };

  React.useEffect(() => {
    window.setWindowTitle(t('auth.confirm_email'));
    setInitialHeader({ mainHeader: UN_CONFIRMED, subHeader: null });
    setEmail(userData.email);
  }, []);

  /* Refresh user on ever 10 seconds or when the tab comes into focus */
  React.useEffect(() => {
    const refreshInterval = window.setInterval(refreshUser, 1000 * 10);
    window.addEventListener('focus', refreshUser);
    return (() => {
      window.removeEventListener('focus', refreshUser);
      window.clearInterval(refreshInterval);
    });
  }, []);

  return (
    <Row className="unconfirmed-page justify-content-center align-items-start">
      <Col xs={12} md={8} className="d-flex justify-content-center">
        <CTA>
          <h1 className="invited_title">{t('auth.confirm_email')}</h1>
          <p className="invited_text">
            {t('auth.unconfirmed_subtitle')}
            <br />
            &nbsp;&nbsp;&nbsp;<strong>{email}</strong>
          </p>
          <div className="invite-button-atea">
            <p className="find-email-text">{t('auth.cant_find_email')}</p>
            <Button
              disabled={inProgress}
              variant="primary"
              className="button-purple invite-button"
              onClick={resendMail}
            >
              {t('auth.resend_email')}
              {
                inProgress &&
                <Spinner animation="border" role="status">
                  <span className="sr-only">{t('dashboard.loading')}</span>
                </Spinner>
              }
            </Button>
            <div className="contact-area">
            <a className="contactus-link" href={`mailto:${PAC_EMAIL}`}>{t('common.contact_us_small')}</a>
              <span className="contactus-extra-text"> {t('auth.need_help')}</span>
            </div>
          </div>
        </CTA>
      </Col>
    </Row>
  );
};

export default UnconfirmedContainer;
