import React, { useState } from "react";

import Assets from '~/src/constants/assets';

const BurgerToggler = (props) => {
  const { isOpen, onHide } = props;

  return (
    <React.Fragment>
      <span className="toggle-image-area" onClick={onHide}>
        {isOpen ? (
          <div className="close-btn-area">
            <img alt="logo-pic" src={Assets.CloseIcon} className="burger-btn" />
          </div>
        ) : (
          <div className="burger-btn-area">
            <img alt="logo-pic" src={Assets.BurgerIcon} className="burger-close-btn" />
          </div>
        )}
      </span>
    </React.Fragment>
  );
};

BurgerToggler.defaultProps = {
  isOpen: false,
};

export default BurgerToggler;
