import React from "react";
import { useTranslation } from 'react-i18next';
import Assets from '~/src/constants/assets';
import { Col } from 'react-bootstrap';
import './createOrg.scss';

const StepsContainer = React.memo(({ currentStep, launchGuideComplete }) => {
  const { t, i18n } = useTranslation();
  return (
    <Col xs={12} className="justify-content-center">
      <div className="arrow-steps clearfix">
        <div className={`step step1 ${currentStep === 'step1' && 'current'}`}>
          <div className="step-text">
            {t('launch_guide.step1')}
            {currentStep === 'step2' || currentStep === 'step3' ? <img src={Assets.CheckmarkIcon} className="warning" /> : null}
          </div>
          <div className="sub-text">{t('launch_guide.create_org')}</div>
        </div>
        <div className={`step step2 ${currentStep === 'step2' && 'current'}`}>
          <div className="step-text">
            {t('launch_guide.step2')}
            {currentStep === 'step3' ? <img src={Assets.CheckmarkIcon} className="warning" /> : null}
          </div>
          <div className="sub-text">{t('launch_guide.payment')}</div>
        </div>
        <div className={`step step3 ${currentStep === 'step3' && 'current'}`}>
          <div className="step-text">
            {t('launch_guide.step3')}
            {(currentStep === 'step3' &&  launchGuideComplete) ? <img src={Assets.CheckmarkIcon} className="warning" /> : null}
          </div>
          <div className="sub-text">{t('launch_guide.launch_guide')}</div>
        </div>
      </div>
    </Col>
  );
});

export default StepsContainer;
