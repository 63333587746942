import DeleteIcon from 'url:~/assets/images/delete.png';
import ErrorIcon from 'url:~/assets/images/icon-error.png';
import PrinterIcon from 'url:~/assets/images/printer.svg';
import PacImage from "url:~/assets/images/pac.png";

import RunnerSingleIcon from 'url:~/assets/images/runner-single.svg';
import RunnerMultipleIcon from 'url:~/assets/images/runner-multiple.svg';
import MoveIcon from 'url:~/assets/images/runner-move.svg';
import TrackersIcon from 'url:~/assets/images/fitness-trackers.svg';
import DeltaUpIcon from 'url:~/assets/images/delta-up.svg';
import DeltaDownIcon from 'url:~/assets/images/delta-down.svg';
import HeartIcon from 'url:~/assets/images/heart-icon.svg';
import StepsIcon from 'url:~/assets/images/steps-icon.svg';
import ActiveIcon from 'url:~/assets/images/active-icon.svg'
import BadgeIcon from 'url:~/assets/images/badge-icon.svg';

import BurgerIcon from "url:~/assets/images/Hamburger.png";
import CloseIcon from "url:~/assets/images/close.png";

import UserWhiteIcon from 'url:~/assets/images/user-white.svg';
import LogopicIcon from "url:~/assets/images/logo-pic2.png";
import VectorIcon from "url:~/assets/images/Vector.png";
import RightArrowIcon from "url:~/assets/images/right-arrow.png";

import LogoutIcon from "url:~/assets/images/Icon-Logout.png";

import GreenRoundCheckIcon from 'url:~/assets/images/green-round-check.svg';
import CloseRoundIcon from 'url:~/assets/images/close-round.svg';
import ExclamationTriangleIcon from 'url:~/assets/images/exclamation-triangle.svg';
import UserMailIcon from 'url:~/assets/images/user-mail.svg';

import LogoPic1 from "url:~/assets/images/logo.png";
import AppleIcon from "url:~/assets/images/apple.svg";
import GmailIcon from "url:~/assets/images/gmail.svg";
import EmailIcon from "url:~/assets/images/email2.png";
import FBIcon from "url:~/assets/images/fb.svg";

import CheckmarkIcon from "url:~/assets/images/checkmark.png";
import InfoIcon from "url:~/assets/images/info.svg";
import CopyIcon from 'url:~/assets/images/copy.svg';
import WhiteIndicatorIcon from 'url:~/assets/images/white-indicator.svg';

import PilotOrgSize from "url:~/assets/images/pilot_org.svg";
import StarterOrgSize from "url:~/assets/images/pilot_org.svg";
import SmallOrgSize from "url:~/assets/images/small_org_size.svg";
import MediumOrgSize from "url:~/assets/images/medium_org_size.svg";
import LargeOrgSize from "url:~/assets/images/large_org_size.svg";
import EnterpriseOrgSize from "url:~/assets/images/enterprise_org_size.svg";

import BulbIcon from "url:~/assets/images/bulb_icon.svg";

import PnoneEn from "url:~/assets/images/phone_en.png";
import PnoneFr from "url:~/assets/images/phone_fr.png";

import GBM_21 from "url:~/assets/images/gbm_21_en.svg";
import TRUE_NORTH_21 from "url:~/assets/images/true_north_21_en.svg";
import APRIL_21 from "url:~/assets/images/april_challenge_21_en.svg";
import AUG_21 from "url:~/assets/images/aug_21_en.svg";
import COMMING_SOON from "url:~/assets/images/coming_soon.svg";

import CBC_21_EN from "url:~/assets/images/cbc_21_en.svg";
import CBC_21_FR from "url:~/assets/images/cbc_21_fr.svg";

import AUG_21_EN from "url:~/assets/images/aug_21_en.svg";
import AUG_21_FR from "url:~/assets/images/aug_21_fr.svg";

import GBM_21_EN from "url:~/assets/images/gbm_21_en.svg";
import GBM_21_FR from "url:~/assets/images/gbm_21_fr.svg";

import DEC_21_EN from "url:~/assets/images/dec_21_en.svg";
import DEC_21_FR from "url:~/assets/images/dec_21_fr.svg";

import MFYM_23_EN from "url:~/assets/images/mfym_23_en.svg";
import MFYM_23_FR from "url:~/assets/images/mfym_23_fr.svg";

import FEB_22_EN from "url:~/assets/images/true_north_21_en.svg";
import FEB_22_FR from "url:~/assets/images/true_north_21_fr.svg";

import APR_22_EN from "url:~/assets/images/april_challenge_21_en.svg";
import APR_22_FR from "url:~/assets/images/april_challenge_21_fr.svg";

import AUG_22_EN from "url:~/assets/images/mazda_challenge_en_22.svg";
import AUG_22_FR from "url:~/assets/images/mazda_challenge_fr_22.svg";

import CBC_23_EN from "url:~/assets/images/cbc_23_en.svg";
import CBC_23_FR from "url:~/assets/images/cbc_23_fr.svg";

import NATIONAL_AVERAGE from "url:~/assets/images/national_average.jpg";
import CupIcon from "url:~/assets/images/cup.svg";

import INFO_ICON_I from "url:~/assets/images/info-icon-i.svg";

import EXTERNAL_LINK from "url:~/assets/images/external_link.svg";
import WARNING from "url:~/assets/images/warning.svg";

import Flag from "url:~/assets/images/flag.svg";



export default {
  PrinterIcon,
  DeleteIcon,
  ErrorIcon,
  PacImage,
  RunnerSingleIcon,
  RunnerMultipleIcon,
  MoveIcon,
  TrackersIcon,
  DeltaUpIcon,
  DeltaDownIcon,
  HeartIcon,
  StepsIcon,
  ActiveIcon,
  BadgeIcon,
  BurgerIcon,
  CloseIcon,
  UserWhiteIcon,
  LogopicIcon,
  VectorIcon,
  RightArrowIcon,
  LogoutIcon,
  CloseRoundIcon,
  GreenRoundCheckIcon,
  ExclamationTriangleIcon,
  UserMailIcon,
  LogoPic1,
  AppleIcon,
  GmailIcon,
  EmailIcon,
  FBIcon,
  CheckmarkIcon,
  InfoIcon,
  CopyIcon,
  WhiteIndicatorIcon,
  PilotOrgSize,
  BulbIcon,
  PnoneEn,
  PnoneFr,
  GBM_21,
  TRUE_NORTH_21,
  APRIL_21,
  CBC_21_EN,
  CBC_21_FR,
  CBC_23_EN,
  CBC_23_FR,
  AUG_21,
  COMMING_SOON,
  NATIONAL_AVERAGE,
  CupIcon,
  AUG_21_EN,
  AUG_21_FR,
  GBM_21_EN, 
  GBM_21_FR,
  DEC_21_EN, 
  DEC_21_FR,
  MFYM_23_EN,
  MFYM_23_FR,
  FEB_22_EN,
  FEB_22_FR, 
  APR_22_EN, 
  APR_22_FR,
  AUG_22_EN,
  AUG_22_FR,
  INFO_ICON_I,
  PilotOrgSize,
  StarterOrgSize,
  SmallOrgSize,
  MediumOrgSize,
  LargeOrgSize,
  EnterpriseOrgSize,
  EXTERNAL_LINK,
  WARNING,
  Flag
}


// import { DeleteIcon, ErrorIcon } from '~/src/constants/assets';
// import Assets from '~/src/constants/assets';
