import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Table, Col, Row, Spinner } from 'react-bootstrap';
import { reverse } from "~/src/api/urls";
import { GET, makeAPIcall } from "~/src/api/utils";
import ReusableContent from "../components/reusableTitleContent";
import ToolTip from "~/src/components/Tooltip";
import { isAuthRelatedError } from "~/src/api/errors";
import { pushToPageLayer, pushEventToDataLayer } from '~/src/utils/GA';
import AuthContext from "~/src/context/authContext";
import { RESOURCE_LANDING_PAGE } from "~/src/constants/subpage";
import { Link } from "react-router-dom";
import "./resources.scss";

const ResourceContainer = () => {
  const { t, i18n } = useTranslation();

  const { handleAuthError, setToastMsg, setInitialHeader, appUserId } = React.useContext(AuthContext);

  const [resourceData, setResourceData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const resource_content = {
    describeText: `${t("resource.res_description")}${t("resource.res_description1")}${t("resource.res_description2")}`,
    contentTitle: t("resource.resources"),
  };

  const handleDownload = async (error, result, name) => {
    // TBD: Handle organization_update API error
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      } else {
        setToastMsg(t("resource.download_failed"), true);
      }
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const downloadFile = result.blob();
      const filename = result.headers.get("content-disposition").split('filename=')[1] || name;
      pushEventToDataLayer('Resource Page - Download', `${filename} - Downloaded`, 'Resource Download', '1', appUserId);
      downloadFile.then(blob => {
        const objectURL = URL.createObjectURL(blob);
        a.href = objectURL;
        a.download = filename;
        a.click();
        a.remove();
        URL.revokeObjectURL(objectURL);
      });

    }
  };

  const download = (downloadId, name) => {
    makeAPIcall(
      reverse("api:resource_download", downloadId), GET, handleDownload, {}, {}, false, true, name);
  };

  const resourceDataAPIHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setResourceData(result.payload.categories);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    setInitialHeader({ mainHeader: RESOURCE_LANDING_PAGE, subHeader: null });
    window.setWindowTitle(t('resource.resources'));
    pushToPageLayer(t('resource.resources'));
    makeAPIcall(reverse("api:resources"), GET, resourceDataAPIHandler);
  }, []);


  const currentLanguage = i18n.language;

  return (
    <Row className="resources-page justify-content-center">
      <Col xs={12}>
        <Row>
          <Col xs={11} lg={8}>
            <div className="reusable-container">
              <div className="content-title">
                {t("resource.resources")}
              </div>
              <div className="content-describe">
                {t("resource.res_description")}
                <span>
                  <Link to={reverse('app:launch_guide_page')} className="orange">
                    {t("resource.res_description1")}
                  </Link>
                </span>
                {t("resource.res_description2")}`
              </div>
            </div>
          </Col>
        </Row>
        <Row className="resources-item-container">
          {isLoading &&
            <Col align="center">
              <Spinner animation="border" role="status">
                <span className="sr-only">{t("dashboard.loading")}</span>
              </Spinner>
            </Col>
          }
          {resourceData.map((data, idx) => {
            return (
              data.resources.length ?
                <Col sm={12} md={6} xl={4} key={idx}>
                  <div className="table-card">
                    <div className="table-text-area">
                      <h2 className="table-title">
                        {data[`name_${currentLanguage}`]}
                      </h2>
                      <p className="table-describe">
                        {data[`description_${currentLanguage}`]}
                      </p>
                    </div>

                    <div className="resource-table-area">
                      <Table
                        striped
                        borderless
                        responsive
                        className="resource-table"
                      >
                        <thead className="resource-table-head">
                          <tr>
                            <th>{t("resource.resource")}</th>
                            <th className="th-action">{t("resource.action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {data.resources.map((resource, resourceIdx) => {
                          return (
                            <tr key={resourceIdx}>
                              <td>
                                <span style={{ marginRight: '2px' }}>{resource[`resource_${currentLanguage}`].name}</span>
                                {resource[`resource_${currentLanguage}`].description && <ToolTip text={resource[`resource_${currentLanguage}`].description} />}
                              </td>
                              <td className="action-td">
                                <span className="resource-dwnld-bth">
                                  {t("resource.download")}
                                </span>
                                {resource.resource_en && (
                                  <Button
                                    className="resource-lang-bth"
                                    variant="link"
                                    onClick={() =>
                                      download(resource.resource_en.pk, `${resource[`resource_${currentLanguage}`].name}-en`)
                                    }
                                  >
                                    EN
                                  </Button>
                                )}
                                {resource.resource_fr && (
                                  <Button
                                    className="resource-lang-bth"
                                    variant="link"
                                    onClick={() =>
                                      download(resource.resource_fr.pk, `${resource[`resource_${currentLanguage}`].name}-fr`)
                                    }
                                  >
                                    FR
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
                :
                null
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default ResourceContainer;
