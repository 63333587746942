import React  from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Table,
  Button,
} from 'react-bootstrap';
import Select from 'react-select'

import ToolTip from '~/src/components/Tooltip';
import Constants from '~/src/constants/dashboard';
import CounterCard from '../components/counterCard';

import Utils from './utils';
import './style.scss';


const OrganizationActivityBreakdown = React.memo(({
  title,
  data,
  headerData,
  setOrgActivityBreakdownSort,
  noData
}) => {
  const { t, i18n } = useTranslation();

  const sortCol = data.sortCol;
  const sortMode = data.sortMode;

  return (
    <Row
      className={`organization-activity-breakdown-container ${noData && 'add-opacity'}`}
    >
      <Col xs={12}>
        <div className="organization-activity-breakdown">
          <div className="title">
            {t(title)}
            <ToolTip
              className="info"
              text={t("dashboard.org_activity_break_info")}
            />
          </div>
          <Table hover>
            <thead>
              <tr className="statistics">
                <th></th>
                <th colSpan={2}>
                  <CounterCard
                    noBorder
                    title="dashboard.on_average"
                    deltaSign={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.moveMinutes] || {}).deltaSign}
                    delta={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.moveMinutes] || {}).delta}
                    count={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.moveMinutes] || {}).current}
                    icon="runnerMove"
                    countDeltaInARow
                    iconText="dashboard.move_minute"
                    countTitle="dashboard.of_tracked_per_day"
                    helpText="dashboard.move_minute_info"
                  />
                </th>
                <th colSpan={2}>
                  <CounterCard
                    noBorder
                    title="dashboard.on_average"
                    deltaSign={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.activeMinutes] || {}).deltaSign}
                    delta={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.activeMinutes] || {}).delta}
                    count={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.activeMinutes] || {}).current}
                    icon="heart"
                    countDeltaInARow
                    iconText="dashboard.active_minute"
                    countTitle="dashboard.of_tracked_per_day"
                    helpText="dashboard.active_minute_info"
                  />
                </th>
                <th colSpan={2}>
                  <CounterCard
                    noBorder
                    title="dashboard.on_average"
                    deltaSign={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.steps] || {}).deltaSign}
                    delta={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.steps] || {}).delta}
                    count={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.steps] || {}).current}
                    icon="steps"
                    countDeltaInARow
                    iconText="dashboard.steps"
                    countTitle="dashboard.of_tracked_per_day"
                    helpText="dashboard.step_info"
                  />
                </th>
                <th>
                  <CounterCard
                    noBorder
                    title="dashboard.on_average"
                    deltaSign={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.streakBadge] || {}).deltaSign}
                    delta={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.streakBadge] || {}).delta}
                    count={(headerData && headerData[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.streakBadge] || {}).current}
                    icon="badge"
                    countDeltaInARow
                    iconText="dashboard.badges"
                    countTitle="dashboard.of_earned"
                    helpText="dashboard.badges_info"
                  />
                </th>
              </tr>
              <tr className="dark">
                <th>
                  Groups
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.total'),
                    Constants.PULSE_CHECK_KEY.MOVE_MINUTES.SUM,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.avg_person_day'),
                    Constants.PULSE_CHECK_KEY.MOVE_MINUTES.AVG,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.total'),
                    Constants.PULSE_CHECK_KEY.ACTIVE_MINUTES.SUM,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.avg_person_day'),
                    Constants.PULSE_CHECK_KEY.ACTIVE_MINUTES.AVG,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.total'),
                    Constants.PULSE_CHECK_KEY.STEPS.SUM,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.avg_person_day'),
                    Constants.PULSE_CHECK_KEY.STEPS.AVG,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
                <th>
                  {Utils.getSortColHeaderButton(
                    t('dashboard.streak_badges'),
                    Constants.PULSE_CHECK_KEY.STREAK.SUM,
                    sortCol,
                    sortMode,
                    setOrgActivityBreakdownSort
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {Utils.getRow((data.orgTotals || { name: t('dashboard.org_total') }), 'table-secondary')}
              {(data.data || []).map(Utils.getRow)}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
});

OrganizationActivityBreakdown.defaultProps = {
  data: {},
  headerData: {},
  title: 'org_activity_break', /* Use t to translate in */
};

export default OrganizationActivityBreakdown;
