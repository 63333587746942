
const ALL_TIME_METRCS = {
  organizationMembers: 'total_org_members',
  organizationTeam: 'total_org_team',
  activeMinutes: 'total_org_avg_move_minutes',
  withFitnessTrackers: 'total_org_with_fitness_trackers',
};

const ALL_TIME_METRICS_META = [
  {
    icon: 'runnerSingle',
    name: ALL_TIME_METRCS.organizationMembers,
    getCount: (metric) => metric &&  parseInt(metric.metric_value, 10) || 0,
    title: 'dashboard.total_org',
    helpText: 'dashboard.total_info',
  },
  {
    icon: 'runnerMultiple',
    name: ALL_TIME_METRCS.organizationTeam,
    getCount: (metric) => metric &&  parseInt(metric.metric_value, 10) || 0,
    title: 'dashboard.total_team',
    helpText: 'dashboard.team_info',
  },
  {
    icon: 'runnerMove',
    name: ALL_TIME_METRCS.moveMinutes,
    getCount: (metric) => metric &&  parseInt(metric.metric_value, 10) || 0,
    title: 'dashboard.average_move_min',
    countTitle: 'dashboard.average_move_min_per_member',
    helpText: 'dashboard.average_move_info',
  },
  {
    icon: 'fitnessTrackers',
    name: ALL_TIME_METRCS.withFitnessTrackers,
    getCount: (metric, allMetrics) => {
      if (metric) {
        const totalOrgMembers = parseInt(allMetrics[ALL_TIME_METRCS.organizationMembers].metric_value, 10) || 0;
        const fitnessTrackers = parseInt(metric.metric_value, 10) || 0;
        const percentageValue = Math.round((totalOrgMembers ? (fitnessTrackers / totalOrgMembers) : 0) * 100) || 0;
        return `${percentageValue}%`;
      }
    },
    title: 'dashboard.fitness_track',
    helpText: 'dashboard.fitness_track_info',
  },
];

const GRAPH_CARDS_META_MOVE_MINUTES = {
  title: 'dashboard.average_move_min',
  countTitle: 'dashboard.average_move_min_per_member',
  helpText: 'dashboard.graph_average_move_info',
};

const GRAPH_CARDS_META_CHALLENGES = {
  title: 'dashboard.active_challenge',
  helpText: 'dashboard.active_challenge_info',
};

const PULSE_CHECK_KEY = {
  MOVE_MINUTES: {
    SUM: 'pulse_check__sum__move_minutes',
    AVG: 'pulse_check__avg__move_minutes',
  },
  ACTIVE_MINUTES: {
    SUM: 'pulse_check__sum__active_minutes',
    AVG: 'pulse_check__avg__active_minutes',
  },
  STEPS: {
    SUM: 'pulse_check__sum__steps',
    AVG: 'pulse_check__avg__steps',
  },
  STREAK: {
    SUM: 'pulse_check__sum__streak_badges',
  },
};

const TIME_OPTION_KEY = {
  weekToDate: 'week-to-date',
  last7days: 'last-7-days',
  last14Days: 'last-14-days',
  last30Days: 'last-30-days',
  previousMonth: 'previous-month',
};

const TIME_OPTION = [
  { value: TIME_OPTION_KEY.weekToDate, label: 'dashboard.week_to_date' },
  { value: TIME_OPTION_KEY.last7days, label: 'dashboard.last_7' },
  { value: TIME_OPTION_KEY.last14Days, label: 'dashboard.last_14' },
  { value: TIME_OPTION_KEY.last30Days, label: 'dashboard.last_30' },
  { value: TIME_OPTION_KEY.previousMonth, label: 'dashboard.prev_month' },
];

const ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES = {
  moveMinutes: 'moveMinutes',
  activeMinutes: 'activeMinutes',
  steps: 'steps',
  streakBadge: 'streakBadge',
  countMembers: 'countMembers',
};

// const pointImage = new Image();
// pointImage.src = GraphPointIcon;

const GRAPH_OPTIONS = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      borderColor: '#cccccc',
      borderWidth: 1,
      padding: 5,
      backgroundColor: '#ffffff',
      titleColor: '#333',
      position: 'nearest',
      bodyColor: '#333',
      titleFont: {
        size: 16,
        family: 'Open Sans, sans-serif',
        lineHeight: 1.3,
        weight: 400,
        style: 'normal',
      },
      bodyFont: {
        size: 16,
        family: 'Open Sans, sans-serif',
        lineHeight: 1.3,
        weight: 400,
        style: 'normal',
      },
    },
    htmlLegend: {
      containerID: 'legend-container',
    },
    legend: {
      display: false,
    }
  },
  title:{
    display: false,
  },
  elements: {
    line: {
      tension: 0.3,
    },
    point: {
      pointHoverBackgroundColor: '#50287D',
      pointHoverBorderColor: '#50287D',
      pointHoverBorderWidth: 2,
      pointHoverRadius: 12,
    },
  },
  scales: {
    x: {
      ticks: {
        align: 'center',
        color: '#333333',
        font: {
          family: 'Open Sans, sans-serif',
          size: 16,
        },
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      beginAtZero: true,
      ticks: {
        color: '#50287D',
        font: {
          family: 'Open Sans, sans-serif',
          size: 16,
        },
      },
      grid: {
        drawTicks: false,
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      beginAtZero: true,
      ticks: {
        color: '#F57F29',
        font: {
          family: 'Open Sans, sans-serif',
          size: 16,
        },
      },
      grid: {
        drawTicks: false,
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
    },
  },
};

const GRAPH_DATA_META = [
  {
    name: 'dashboard.average_move_min',
    borderColor: '#50287D',
    colorRangeStart: '#B37AF399',
    colorRangeEnd: 'rgba(63, 162, 247, 0)',
    yAxisID: 'y',
  },
  {
    name: 'dashboard.app_user',
    borderColor: '#F57F29',
    colorRangeStart: '#FFBE8E99',
    colorRangeEnd: 'rgba(86, 197, 104, 0)',
    yAxisID: 'y1',
  },
];

export default {
  TIME_OPTION_KEY,
  GRAPH_DATA_META,
  GRAPH_OPTIONS,
  TIME_OPTION,
  PULSE_CHECK_KEY,
  ALL_TIME_METRICS_META,
  ALL_TIME_METRCS,
  ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES,
  GRAPH_CARDS_META_MOVE_MINUTES,
  GRAPH_CARDS_META_CHALLENGES,
};
