import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LANDING } from '~/src/constants/subpage';
import CTA from '~/src/components/ctaBox';
import { reverse } from '~/src/api/urls';
import {
  signUpViaUsernamePassword,
  getUserDataFromSession,
  signInViaUsernamePassword,
  getProviderFromEmail,
} from "~/src/utils/auth";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall } from '~/src/api/utils';
import AuthContext from '~/src/context/authContext';
import ToolTip from "~/src/components/Tooltip";
import Assets from '~/src/constants/assets';
import ButtonWithProgress from '~/src/components/buttonWithProgress';

const EmailRegisterPage = ({ setCurrentPage }) => {
  const { t, i18n } = useTranslation();
  const { handleAuthError, setAuthenticatedUser, getCurrentUserFromAmplify } = React.useContext(AuthContext);
  const [inProgress, setInProgress] = React.useState(false);

  const existingEmails = React.useRef([]);

  const signinFn = async (values, { setErrors, setFieldError }) => {
    setInProgress(true);
    setErrors({});

    /* try to see if we get social account login else reset the password */
    try {
      values.email = values.email.toLowerCase();

      const emailProvider = await getProviderFromEmail(values.email);
      if (emailProvider) {
        setInProgress(false);
        setFieldError('email', `${t('auth.account_already_exists')} ${t('auth.account_already_exists_desc')}`);
        return;
      }
    } catch (ex) {
      /* errors are ignored */
    }

    try {
      await signUpViaUsernamePassword(values.email, values.password);
      const session = await signInViaUsernamePassword(values.email, values.password);
      setAuthenticatedUser(getUserDataFromSession(session));
    } catch (ex) {
      setInProgress(false);
      console.error(ex);
      if (ex.code === 'UsernameExistsException' || (ex.message || '').includes('User Already Exists')) {
        setFieldError('email', t('common.user_exist'));
      } else if (ex.code === 'InvalidPasswordException') {
        setFieldError('password', t('common.password_suggestion_mismatch'));
      } else {
        setFieldError('form', t('common.generic_error_message'));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordReTyped: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("common.enter_valid_email")).required(t("common.required_email")),
      password: Yup.string().trim().required(t("common.new_password_guide")).matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("common.password_suggestion_mismatch")
        //Checks for 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      ),
      passwordReTyped: Yup.string().required(t("common.required_password")).oneOf(
        [Yup.ref('password'), null],
        t("common.password_dont_match"),
      ),
    }),
    validate: (values) => {
      let errors = {};
      if (existingEmails.current.includes(values.email.toLowerCase())) {
        errors.email = t("common.user_exist")
      }
      return errors;
    },
    onSubmit: async (values, { setErrors, setFieldError }) => {
      signinFn(values, { setErrors, setFieldError });
    }
  });

  return (
    <CTA
      miniPadding
      onClick={() => setCurrentPage(LANDING)}
      text={t('auth.back_create_account')}
      className="email-register-form-container"
    >
      <Form id="registerForm" onSubmit={formik.handleSubmit}>
        <p className="email-form-register-title">{t("auth.create_your_acc")}</p>
        <span className="form-describe">
          {t("auth.register_description1")} <img src={Assets.PacImage} alt="pac-icon"  className="pac-icon"/> {t("auth.register_description2")}
        </span>
        <Link to="/" style={{color: "#F57F29", textDecoration: "none", marginLeft: 6}}>
        {t("auth.try_logging")}
        </Link>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="input-label">{t("auth.email")}</Form.Label>
          <Form.Control
            name="email"
            autoComplete="email"
            disabled={inProgress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={formik.touched.email && formik.errors.email && "highlight-input"}
          />
          { formik.touched.email && formik.errors.email ?
            (
              <p className="form-error-text">{formik.errors.email}</p>
            ) : null
          }
        </Form.Group>
        <Form.Group controlId="newPasswordInput">
          <Form.Label className="input-label">
            {t("auth.password")}
            <ToolTip
              text={
                `${t("common.min_8")},
                 ${t("common.special_char")},
                 ${t("common.uppercase")},
                 ${t("common.number_1")},
                 ${t("common.lowercase")}`
              }
            />
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            disabled={inProgress}
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={formik.touched.password && formik.errors.password && "highlight-input"}
          />
          { formik.touched.password && formik.errors.password ?
            (
              <p className="form-error-text">{formik.errors.password}</p>
            ) : null
          }
        </Form.Group>
        <Form.Group controlId="confirmedPasswordInput">
          <Form.Label className="input-label">
            {t("auth.new_password_again")}
          </Form.Label>
          <Form.Control
            type="password"
            name="passwordReTyped"
            disabled={inProgress}
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordReTyped}
            className={formik.touched.passwordReTyped && formik.errors.passwordReTyped && "highlight-input"}
          />
          { formik.touched.passwordReTyped && formik.errors.passwordReTyped ?
            (
              <p className="form-error-text">{formik.errors.passwordReTyped}</p>
            ) : null
          }
        </Form.Group>
        <Form.Row>
          <Col sm={12}>
            { !formik.errors.form && formik.submitCount > 0 && !formik.isValid ?
              <span className="form-final-error-text">
                <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                {t("common.correct_errors_above")}
              </span>
              : null
            }
            {
              formik.errors.form &&
                <p className="form-error-text">{formik.errors.form}</p>
            }
          </Col>
          <Col sm={12}>
            <ButtonWithProgress
              inProgress={inProgress}
              text={t("common.register_button")}
            />
          </Col>
        </Form.Row>
      </Form>
    </CTA>
  );
};

export default EmailRegisterPage;
