import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import AboutChallenge from '../components/aboutChallenge';
import ParticipationCard from '../components/participationCard';
import CounterCard from '../components/counterCard';
import Utils from '~/src/challengeReport/utils';

import './style.scss';

const DECTopSection = (props) => {
  const { t, i18n } = useTranslation();
  const { currentCard, currentReport, isChallengeNotStarted } = props;

  return (
    <Row className={'top-section'}>
      <Col xs={12} sm={6} lg={3} className="top-card about-this-challenge">
        <AboutChallenge
          currentCard={currentCard}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container">
        <div className="team">
          <ParticipationCard
            cardView={'dec_average_milestone'}
            icon={'flag'}
            text={'challenge_report.dec_milestone_achieved'}
            count={Utils.averageMilestoneAchievedDEC(currentReport)}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
        <div className="member">
          <ParticipationCard
            cardView={'dec_average_milestone'}
            icon={'cup'}
            text={'challenge_report.dec_completed_challenge'}
            count={Utils.completedChallengePercentage(currentReport)}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
      </Col>
      <Col xs={12} sm={6} lg={3} className={'top-card participation-card-container'}>
        <div className="team">
          <ParticipationCard
            cardView={'dec_average_per_person'}
            icon={'runnerSingle'}
            title={'challenge_report.dec_avg_per_person'}
            helpText={'challenge_report.aug_average_info'}
            count={(currentReport.activity_data && Math.abs(Math.round(currentReport.activity_data.avg_move_minutes))) || 0}
            nationalAverageValue={(currentReport.national_data && Math.abs(Math.round(currentReport.national_data.avg_move_minutes))) || 0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
        <div className="member">
          <ParticipationCard
            cardView={'dec_total_activity'}
            icon={'stand'}
            title={'challenge_report.dec_total_activity'}
            helpText={'challenge_report.aug_total_activity_info'}
            count={(currentReport.activity_data && Math.abs(Math.round(currentReport.activity_data.total_move_minutes))) || 0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container december-dash-normal-container">
          <div className="team">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerMultiple'}
              text={'challenge_report.participated_team'}
              helpText={'challenge_report.team_info'}
              count={(currentReport.engagement_data && currentReport.engagement_data.participating_teams) || 0}
              isChallengeNotStarted = {isChallengeNotStarted}
            />
          </div>
          <div className="member">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerSingle'}
              text={'challenge_report.participated_member'}
              helpText={'challenge_report.member_info'}
              count={(currentReport.engagement_data && currentReport.engagement_data.participating_individuals) || 0}
              isChallengeNotStarted = {isChallengeNotStarted}
            />
          </div>
      </Col>
    </Row>
  );
};

export default DECTopSection;
