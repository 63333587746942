import React from 'react';
import { reverse } from '~/src/api/urls';
import { GET, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from '~/src/api/errors';
import AuthContext from '~/src/context/authContext';

const GroupContext = React.createContext({});

const GroupContextWrapper = (props) => {
  const { handleAuthError, orgData } = React.useContext(AuthContext);

  const [groups, setGroups] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const groupsListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setGroups(result.payload);
    }
    setIsLoading(false);
  };

  const resetGroupData = async () => {
    setIsLoading(true);
    setSelectedGroup({});
    let orgId = orgData
    await makeAPIcall(reverse('api:group_list_new', orgId), GET, groupsListHandler);
  };

  React.useEffect(() => {
    resetGroupData();
  }, []);

  return (
    <GroupContext.Provider
      key="group-context"
      value={{
        groups,
        selectedGroup,
        setSelectedGroup,
        resetGroupData,
        isLoading,
        setIsLoading,
      }}
    >
      {props.children}
    </GroupContext.Provider>
  );
}

GroupContext.Wrapper = GroupContextWrapper;
export default GroupContext;
