import React from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationContext from '~/src/context/organizationContext';
import LaunchGuideDiv from './index';
import AuthContext from "~/src/context/authContext";
import { ORGANIZATION_LANDING_PAGE, LAUNCH_GUIDE_LANDING_PAGE } from "~/src/constants/subpage";


const LaunchGuideContainer = props => {
  const { t, i18n } = useTranslation();

  const { setInitialHeader } = React.useContext(AuthContext);
  React.useEffect(() => {
    window.setWindowTitle(t("launch_guide.step3_title"));
    setInitialHeader({ mainHeader: ORGANIZATION_LANDING_PAGE, subHeader: LAUNCH_GUIDE_LANDING_PAGE });
  }, []);

  return (
    <OrganizationContext.Wrapper {...props}>
      <LaunchGuideDiv />
    </OrganizationContext.Wrapper>
  )
};

export default LaunchGuideContainer;
