import Assets from '~/src/constants/assets';

const IMAGE_MAP = {
    'Anand_Test_Challenge': { en: Assets.AUG_21_EN, fr: Assets.AUG_21_FR },
    'True North': { en: Assets.FEB_22_EN, fr: Assets.FEB_22_FR },
    'Fall Challenge 2020': { en: Assets.GBM_21_EN, fr: Assets.GBM_21_FR },
    'fall challenge (test)': { en: Assets.APR_22_EN, fr: Assets.APR_22_FR },
    'CBC_2021': { en: Assets.CBC_21_EN, fr: Assets.CBC_21_FR },
    'AUG_2021': { en: Assets.AUG_21_EN, fr: Assets.AUG_21_FR },
    'GBM_2021': { en: Assets.GBM_21_EN, fr: Assets.GBM_21_FR },
    'DEC_2021': { en: Assets.DEC_21_EN, fr: Assets.DEC_21_FR },
    'TRUENORTH_2022': { en: Assets.FEB_22_EN, fr: Assets.FEB_22_FR },
    'APR_2022': { en: Assets.APR_22_EN, fr: Assets.APR_22_FR },
    'CBC_2022': { en: Assets.CBC_21_EN, fr: Assets.CBC_21_FR },
    'AUG_2022': { en: Assets.AUG_22_EN, fr: Assets.AUG_22_FR },
    'GBM_2022': { en: Assets.GBM_21_EN, fr: Assets.GBM_21_FR },
    'MFYM_2023': { en: Assets.MFYM_23_EN, fr: Assets.MFYM_23_FR},
    'TRUENORTH_2023': { en: Assets.FEB_22_EN, fr: Assets.FEB_22_FR },
    'APR_2023': { en: Assets.APR_22_EN, fr: Assets.APR_22_FR },
    'CBC_2023': { en: Assets.CBC_23_EN, fr: Assets.CBC_23_FR }
};

const NAME_MAP = {
    'Anand_Test_Challenge': { title: 'challenge_report.test_report', subtitle: 'challenge_report.test_subtitle'},
    'True North': { title: 'challenge_report.test_report', subtitle: 'challenge_report.test_subtitle'},
    'Fall Challenge 2020': { title: 'challenge_report.test_report', subtitle: 'challenge_report.test_subtitle'},
    'CBC_2021': { title: 'challenge_report.cbc_report', subtitle: 'challenge_report.cbc_subtitle'},
    'fall challenge (test)': { title: 'challenge_report.test_report', subtitle: 'challenge_report.test_subtitle'},
    'AUG_2021': { title: 'challenge_report.aug_report', subtitle: 'challenge_report.aug_subtitle'},
    'GBM_2021': { title: 'challenge_report.gbm_report', subtitle: 'challenge_report.gbm_subtitle'},
    'DEC_2021': { title: 'challenge_report.dec_report', subtitle: 'challenge_report.dec_subtitle'},
    'TRUENORTH_2022': { title: 'challenge_report.trueNorth_report', subtitle: 'challenge_report.trueNorth_subtitle'},
    'APR_2022': { title: 'challenge_report.april_report', subtitle: 'challenge_report.april_subtitle'},
    'CBC_2022': { title: 'challenge_report.cbc_report_2022', subtitle: 'challenge_report.cbc2022_subtitle'},
    'AUG_2022': { title: 'challenge_report.mazda2022_report', subtitle: 'challenge_report.mazda2022_subtitle'},
    'GBM_2022': { title: 'challenge_report.gbm_report', subtitle: 'challenge_report.gbm2022_subtitle'},
    'MFYM_2023': { title: 'challenge_report.mfym2023_report', subtitle: 'challenge_report.mfym2023_subtitle'},
    'APR_2023': { title: 'challenge_report.april_report', subtitle: 'challenge_report.april23_subtitle'},
    'CBC_2023': { title: 'challenge_report.cbc_report_2023', subtitle: 'challenge_report.cbc2023_subtitle'},
};

const DATE_MAP = {
  'CBC_2021': { dates: 'challenge_report.cbc_date'},
  'AUG_2021': { dates: 'challenge_report.aug_date'},
  'GBM_2021': { dates: 'challenge_report.gbm_date'},
  'DEC_2021': { dates: 'challenge_report.dec_date'},
  'TRUENORTH_2022': { dates: 'challenge_report.trueNorth_date'},
  'APR_2022' : { dates: 'challenge_report.april_date' },
  'CBC_2022' : { dates: 'challenge_report.cbc_date_2022' },
  'AUG_2022': { dates: 'challenge_report.mazda_date'},
  'GBM_2022': { dates: 'challenge_report.gbm2022_date'},
  'MFYM_2023': { dates: 'challenge_report.mfym2023_date' },
  'TRUENORTH_2023': { dates: 'challenge_report.trueNorth2023_date'},
  'APR_2023' : { dates: 'challenge_report.april2023_date' },
  'CBC_2023' : { dates: 'challenge_report.cbc_date_2023' },
}

const ABOUT_CHALLENGE_DATE_MAP = {
  'CBC_2021': { cardDates: 'challenge_report.cbc_cardDate'},
  'AUG_2021': { cardDates: 'challenge_report.aug_cardDate'},
  'GBM_2021': { cardDates: 'challenge_report.gbm_cardDate'},
  'DEC_2021': { cardDates: 'challenge_report.dec_cardDate'},
  'TRUENORTH_2022': { cardDates: 'challenge_report.trueNorth_cardDate'},
  'APR_2022' : { cardDates: 'challenge_report.april_cardDate' },
  'CBC_2022': { cardDates: 'challenge_report.cbc_cardDate_2022' },
  'AUG_2022': { cardDates: 'challenge_report.mazda_cardDate' },
  'GBM_2022': { cardDates: 'challenge_report.gbm2022_cardDate' },
  'MFYM_2023': { cardDates: 'challenge_report.mfym2023_cardDate' },
  'APR_2023' : { cardDates: 'challenge_report.april23_cardDate' },
  'CBC_2023': { cardDates: 'challenge_report.cbc_cardDate_2023' },
}

const CHALLENGE_TYPE = {
  "move_minutes": { image: Assets.MoveIcon, title: "challenge_report.move_minutes" },
  "steps": { image: Assets.StepsIcon, title: "challenge_report.steps" },
  "active_minutes": { image: Assets.ActiveIcon, title: "challenge_report.active_minutes"}
}

const PERFORMANCE_TABLE_KEY = {
  MOVE_MINUTES: 'move_minutes',
  MEMBERS: 'member_count',
  ENTRIES: 'prize_entries',
  STEPS: 'steps',
  ACTIVE_MINUTES: 'active_minutes'
};


export default {
  PERFORMANCE_TABLE_KEY,
  IMAGE_MAP,
  NAME_MAP,
  CHALLENGE_TYPE,
  DATE_MAP,
  ABOUT_CHALLENGE_DATE_MAP
};
