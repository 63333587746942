import React from 'react';

const FormikErrorMessage = ({ formik, fieldName }) => {
  if (formik.touched[fieldName] && formik.errors[fieldName]) {
    return (
      <p className="form-error-text">
        {formik.errors[fieldName]}
      </p>
    );
  }
  return null;
}

export default FormikErrorMessage;
