import React from 'react';

import OrganizationContext from '~/src/context/organizationContext';
import DashboardContext from '~/src/context/dashboardContext';
import Home from './index';

const DashboardContainer = props => (
  <OrganizationContext.Wrapper>
    <DashboardContext.Wrapper>
      {
        ({
          allTimeMetrics,
          dateRange,
          setDateRange,
          orgActivityBreakdown,
          orgActivityBreakdownHeader,
          setOrgActivityBreakdownSort,
          graphActiveChallenges,
          organizationInviteCode,
          graphAppUserUsage,
          graphAverageMoveMinutes,
          noData,
          launchGuideComplete
        }) => (
          <Home
            {...props}
            dateRange={dateRange}
            organizationInviteCode={organizationInviteCode}
            setDateRange={setDateRange}
            graphAppUserUsage={graphAppUserUsage}
            orgActivityBreakdownHeader={orgActivityBreakdownHeader}
            allTimeMetrics={allTimeMetrics}
            graphActiveChallenges={graphActiveChallenges}
            graphAverageMoveMinutes={graphAverageMoveMinutes}
            orgActivityBreakdown={orgActivityBreakdown}
            setOrgActivityBreakdownSort={setOrgActivityBreakdownSort}
            noData={noData}
            launchGuideComplete={launchGuideComplete}
          />
        )
      }
    </DashboardContext.Wrapper>
  </OrganizationContext.Wrapper>
);

export default DashboardContainer;
