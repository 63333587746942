import React from 'react';

import ProfileContext from "~/src/context/profileContext";
import OrganizationContext from '~/src/context/organizationContext';
import MyProfile from "./index";
import './style.scss';

const ProfileContainer = props => (
	<OrganizationContext.Wrapper>
	    <ProfileContext.Wrapper {...props}>
	        <MyProfile />
	    </ProfileContext.Wrapper>
	</OrganizationContext.Wrapper>
);

export default ProfileContainer;
