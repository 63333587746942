import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Spinner,
} from 'react-bootstrap';
import ToolTip from '~/src/components/Tooltip';
import Assets from '~/src/constants/assets';

import './style.scss';

const Icons = {
  runnerSingle: Assets.RunnerSingleIcon,
  runnerMultiple: Assets.RunnerMultipleIcon,
  runnerMove: Assets.MoveIcon,
  fitnessTrackers: Assets.TrackersIcon,
  heart: Assets.HeartIcon,
  steps: Assets.StepsIcon,
  active: Assets.ActiveIcon,
  badge: Assets.BadgeIcon,
};

const CounterCard = ({
  icon,
  title,
  count = '-',
  countTitle,
  helpText,
  helpTextNoTranslate,
  delta,
  deltaSign,
  iconText,
  countDeltaInARow,
  noBorder,
  hasNationalAverage,
  nationalAverageValue
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`counter-card ${noBorder ? 'no-border' : ''}`}>
      <ToolTip
        className="info-icon"
        text={helpTextNoTranslate || t(helpText)}
      />
      <div className="icon-container">
        {
          Icons[icon] &&
            <div className="icon-row">
              <img
                className="card-icon"
                src={Icons[icon]}
              />
            </div>
        }
        {
          iconText &&
          <div className="icon-text">{t(iconText)}</div>
        }
      </div>
      {title && <div className={`title ${hasNationalAverage ? 'national_average' : ''}`}>{t(title)}</div>}
      <div className={`count-delta ${countDeltaInARow ? 'count-delta-same-row' : ''}`}>
        <div className={`count ${hasNationalAverage ? 'national_average' : ''}`}>
          {
            count === '-' ?
            <Spinner animation="border" role="status">
              <span className="sr-only">{t("dashboard.loading")}</span>
            </Spinner>
            : count
          }
        </div>
        {
          delta &&
          <div className={`delta ${deltaSign > 0 ? 'up' : 'down'}`}>
            <img
              className="sign"
              src={deltaSign > 0 ? Assets.DeltaUpIcon : Assets.DeltaDownIcon}
            />&nbsp;
            {delta}
          </div>
        }
      </div>
      <div className="count-title">{t(countTitle)}&nbsp;</div>
      {hasNationalAverage &&
        <div className={'national-average'}>
          <div className='image-div'>
            <img
              className="card-icon"
              src={Assets.NATIONAL_AVERAGE}
            />
          </div>
          <div className='title_text'>{t('challenge_report.national_avg')}</div>
          <div className='count_num'>{nationalAverageValue}</div>
        </div>
      }
    </div>
  );
};

CounterCard.defaultProps = {
  countDeltaInARow: false,
  noBorder: false,
  hasNationalAverage: false
};

export default CounterCard;
