import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import AboutChallenge from '../components/aboutChallenge';
import ParticipationCard from '../components/participationCard';
import CounterCard from '../components/counterCard';
import Utils from '~/src/challengeReport/utils';

import './style.scss';

const DefaultTopSection = (props) => {
  const { t, i18n } = useTranslation();
  const { currentCard, currentReport, isChallengeNotStarted } = props;
  const challengeName = currentCard.name

  return (
    challengeName === 'APR_2023' ? 
    <Row className={'top-section'}>
      <Col xs={12} sm={6} lg={3} className="top-card about-this-challenge">
        <AboutChallenge
          currentCard={currentCard}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card counter-card-container add-opacity-for-upcoming-challenge">
        <CounterCard
          icon={'runnerMove'}
          count={0}
          title={t('challenge_report.total_activity')}
          countTitle={t('challenge_report.steps')}
          helpText={t('challenge_report.april_total_activity')}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className={`top-card counter-card-container add-opacity-for-upcoming-challenge avg-activity ${i18n.language === 'fr' && 'french'}`}>
        <CounterCard
          icon={'runnerSingle'}
          count={0}
          title={t('challenge_report.avg_per_member')}
          countTitle={t('challenge_report.steps_per_day')}
          helpText={t('challenge_report.avg_info')}
          hasNationalAverage={true}
          nationalAverageValue={0}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container add-opacity-for-upcoming-challenge">
          <div className="team">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerMultiple'}
              text={'challenge_report.participated_team'}
              helpText={'challenge_report.team_info'}
              count={0}
            />
          </div>
          <div className="member">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerSingle'}
              text={'challenge_report.participated_member'}
              helpText={'challenge_report.member_info'}
              count={0}
            />
          </div>
      </Col>
    </Row>
     :
    <Row className={'top-section'}>
      <Col xs={12} sm={6} lg={3} className="top-card about-this-challenge">
        <AboutChallenge
          currentCard={currentCard}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container">
        <div className="team">
          <ParticipationCard
            cardView={'dec_average_milestone'}
            icon={'flag'}
            text={(challengeName == 'DEC_2021' ? t('challenge_report.dec_milestone_achieved') : (challengeName == 'TRUENORTH_2022') ? t('challenge_report.trueNorth_milestone_achieved') : '')}
            count={0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
          {renderBody(currentCard)}
        </div>
        <div className="member">
          <ParticipationCard
            cardView={'dec_average_milestone'}
            icon={'cup'}
            text={'challenge_report.dec_completed_challenge'}
            count={0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
      </Col>
      <Col xs={12} sm={6} lg={3} className={'top-card participation-card-container'}>
        <div className="team">
          <ParticipationCard
            cardView={'dec_average_per_person'}
            icon={'runnerSingle'}
            title={'challenge_report.dec_avg_per_person'}
            helpText={'challenge_report.aug_average_info'}
            count={0}
            nationalAverageValue={0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
        <div className="member">
          <ParticipationCard
            cardView={'dec_total_activity'}
            icon={'stand'}
            title={'challenge_report.dec_total_activity'}
            helpText={'challenge_report.aug_total_activity_info'}
            count={0}
            isChallengeNotStarted = {isChallengeNotStarted}
          />
        </div>
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container december-dash-normal-container">
          <div className="team">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerMultiple'}
              text={'challenge_report.participated_team'}
              helpText={'challenge_report.team_info'}
              count={0}
              isChallengeNotStarted = {isChallengeNotStarted}
            />
          </div>
          <div className="member">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerSingle'}
              text={'challenge_report.participated_member'}
              helpText={'challenge_report.member_info'}
              count={0}
              isChallengeNotStarted = {isChallengeNotStarted}
            />
          </div>
      </Col>
    </Row>
  );
};

export default DefaultTopSection;
