import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Row, Col, Button, Overlay, Tooltip } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Assets from '~/src/constants/assets';
import { pushToPageLayer, pushEventToDataLayer } from '~/src/utils/GA';

import {
  LANGUAGE_PREFERENCE_CHOICES, ORGANIZATION_SIZE_CHOICES, PROVINCE_CHOICES,
} from "~/src/constants/organization";
import CTA from "~/src/components/ctaBox";
import OrgSizeCard from "~/src/components/orgSizeCard";
import { CONTACT_US_INFO } from '~/src/constants/common';
import OrganizationContext from '~/src/context/organizationContext';
import AuthContext from "~/src/context/authContext";
import MyOrganizationEdit from "./edit";
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { EDIT, LANDING, ORGANIZATION_LANDING_PAGE, ORGANIZATION_VIEW  } from "~/src/constants/subpage";

const MyOrganization = () => {
  const { t, i18n } = useTranslation();
  const { selectedOrganization } = React.useContext(OrganizationContext);
  const { currentPage, setInitialHeader, setOrganization, subscriptionDetail, appUserId } = React.useContext(AuthContext);

  const copyCodeRef = React.useRef();
  const copyCodeTimeoutRef = React.useRef();
  const [isCopiedTooltipVisible, setCopiedTooltipVisible] = React.useState();
  const [selectedPage, setSelectedPage] = React.useState(currentPage);
  const [orgSize, setOrgSize] = React.useState('');
  const [orgProvince, setOrgProvince] = React.useState('');
  const [orgPreferredLang, setOrgPreferredLang] = React.useState('');


  React.useEffect(() => {
    // To set the header component
    window.setWindowTitle(t('organization.org_overview'));
    pushToPageLayer(t('organization.org_overview'));
    setInitialHeader({ mainHeader: ORGANIZATION_LANDING_PAGE, subHeader: ORGANIZATION_VIEW });
    if (selectedOrganization && Object.keys(selectedOrganization).length > 0) {
      const orgSizeObj = ORGANIZATION_SIZE_CHOICES.find(sizeChoice => sizeChoice.value === selectedOrganization.organization_size);
      setOrgSize(orgSizeObj);
      const orgProvinceObj = PROVINCE_CHOICES.find(provinceChoice => provinceChoice.value === selectedOrganization.province_code);
      setOrgProvince((orgProvinceObj && orgProvinceObj.label) || '');
      const orgLangObj = LANGUAGE_PREFERENCE_CHOICES.find(langChoice => langChoice.value === selectedOrganization.language_preference);
      setOrgPreferredLang(orgLangObj.text);
    }
  }, [selectedOrganization]);

  const autoRenewDate = () => {
    const autoRenewDate = new Date((subscriptionDetail && subscriptionDetail[0] && subscriptionDetail[0].current_period_end)*1000 || '');
    return format(autoRenewDate, 'MMMM dd, yyyy');
  }

  const isCanceled = (subscriptionDetail && subscriptionDetail[0] && subscriptionDetail[0].cancel_at_period_end) || false;

  return (
    <Row className="manage-organization-container justify-content-center">
      {
        selectedPage === LANDING ?
          <Col xs={12} lg={5}>
            <CTA miniPadding className="content">
              <p className="profile-title">{t('organization.org_overview')}</p>
              <p className="profile-text">
                {t('organization.name')}:
                <span className="profile-text-bold">
                  {selectedOrganization.name}
                </span>
              </p>
              <p className="profile-text">
                {t('organization.code')}:
                <span className="profile-text-bold">


                 <CopyToClipboard
                    text={selectedOrganization.invite_code}
                    onCopy={() => {
                      /* Show tooltip and hide the tooltip after sometime */
                      setCopiedTooltipVisible(true);
                      window.clearTimeout(copyCodeTimeoutRef.current);
                      copyCodeTimeoutRef.current = window.setTimeout(() => {
                        setCopiedTooltipVisible(false);
                      }, 3000);
                    }}
                  >
                      <span
                        className="copy-code join-us-code"
                        ref={copyCodeRef}
                      >
                        {selectedOrganization.invite_code}
                        <img
                          className="copy-icon"
                          src={Assets.CopyIcon}
                          title="Copy invite code"
                          alt="copyIconimage"
                        />
                      </span>
                  </CopyToClipboard>
                  <Overlay target={copyCodeRef} show={isCopiedTooltipVisible} placement="bottom">
                    {(props) => (
                      <Tooltip {...props}>
                        Code Copied!
                      </Tooltip>
                    )}
                  </Overlay>
                </span>
              </p>
              <p className="profile-text">
                {t('organization.org_size')}:
              </p>
              {orgSize.value &&
                (orgSize.value === 'pilot' ?
                  <OrgSizeCard selectedValue={orgSize.value} button={t('organization.button')} />
                  :
                  <div className="org-size-card-container">
                    <OrgSizeCard selectedValue={orgSize.value} button={t('organization.button')} />
                    <div className="auto-renew">
                      <div className='text'>{!isCanceled ? t("organization.autorenewson") : t("organization.expireson")}</div>
                      <div className='date'>{autoRenewDate()}</div>
                    </div>
                  </div>
                )
              }
              {orgSize.value !== 'pilot' &&
                <div className="input-label">
                  <span>
                    {t("organization.size_contact_desc")}
                    <a
                      className="contactus-link"
                      href={CONTACT_US_INFO[`MANAGE_ORG_${i18n.language}`]}
                      onClick={() => pushEventToDataLayer('Manage My Organization', 'Contact Us clicked', 'Contact Us', '1', appUserId)}
                    >
                      {t("organization.contact_us")}
                    </a>
                  </span>
                </div>
              }
              <p className="profile-text">
                {t('organization.address_1')}:
                <span className="profile-text-bold">
                  {selectedOrganization.address_line_1}
                </span>
              </p>
              <p className="profile-text">
                {t('organization.address_2')}:
                <span className="profile-text-bold">
                  {selectedOrganization.address_line_2}
                </span>
              </p>
              <p className="profile-text">
                {t('organization.city')}:
                <span className="profile-text-bold">
                  {selectedOrganization.city_name}
                </span>
              </p>
              <p className="profile-text">
                {t('organization.province')}:
                <span className="profile-text-bold">
                  {orgProvince}
                </span>
              </p>
              <p className="profile-text">
                {t('organization.postal')}:
                <span className="profile-text-bold">
                  {selectedOrganization.postal_code}
                </span>
              </p>
              <div className="profile-edit-button-area">
                <Button
                  disabled={!selectedOrganization.pk}
                  variant="primary"
                  className="button-purple"
                  onClick={() => setSelectedPage(EDIT)}
                >
                  {t('organization.edit')}
                </Button>
              </div>
            </CTA>
          </Col>
        :
          <MyOrganizationEdit
            onBackButtonClick={() => setSelectedPage(LANDING)}
          />
      }
    </Row>
  );
}

export default MyOrganization;
