import React from 'react';
import { useTranslation } from 'react-i18next';
import { reverse } from "~/src/api/urls";
import {
  Row,
  Col,
  Button,
  Container,
  Overlay,
  Tooltip,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";

import Assets from '~/src/constants/assets';
import ToolTip from '~/src/components/Tooltip';
import AuthContext from "~/src/context/authContext";
import { pushToPageLayer } from '~/src/utils/GA';
import { DASHBOARD_LANDING_PAGE } from "~/src/constants/subpage";

import AllTimeMetrics from './allTimeMetricsSection';
import DateBasedReporting from './dateBasedReporting';
import OrganizationActivityBreakdown from './orgActivityBreakdown';

import './style.scss';

const Home = ({
  dateRange,
  setDateRange,
  allTimeMetrics,
  organizationInviteCode,
  orgActivityBreakdown,
  graphActiveChallenges,
  graphAppUserUsage,
  graphAverageMoveMinutes,
  orgActivityBreakdownHeader,
  setOrgActivityBreakdownSort,
  noData,
  launchGuideComplete
}) => {
  const { t, i18n } = useTranslation();
  const { setInitialHeader } = React.useContext(AuthContext);

  const copyCodeRef = React.useRef();
  const copyCodeTimeoutRef = React.useRef();
  const [isCopiedTooltipVisible, setCopiedTooltipVisible] = React.useState();
  const [isCopiedTooltipVisible2, setCopiedTooltipVisible2] = React.useState();

  React.useEffect(() => {
    // To set the header component
    document.getElementById('dashboard-top').scrollIntoView();
    window.setWindowTitle(t('dashboard.dashboard'));
    pushToPageLayer(t('dashboard.dashboard'));
    setInitialHeader({ mainHeader: DASHBOARD_LANDING_PAGE, subHeader: null });
  }, []);

  return (
    <>
      {!launchGuideComplete &&
        <div className="no-launch-data">
          <span><img src={Assets.WARNING} title="warning" /></span>
          <span className="text">{t('launch_guide.incomplete_launch_text')}</span>
          <span>
            <Link to={reverse('app:launch_guide_page')}>
              <Button
                variant="primary"
                className="button-purple invite-button"
              >
                {t('launch_guide.incomplete_launch_button')}
              </Button>
            </Link>
          </span>
        </div>
      }
      <Row id="dashboard-top" className={`page-home ${noData && 'no-data'} ${!launchGuideComplete && 'orange-toaster'}`}>
        <Col xs={12}>
          <div className="page-title-container">
            <Row>
              <Col xs={12} md={6} className="title">{t("dashboard.pulse_check")}</Col>
              <Col className="copy-code-container">
                <ToolTip
                  placement="bottom"
                  text={t("dashboard.code_info")}
                />
                {t("dashboard.code")}
                <CopyToClipboard
                  text={organizationInviteCode}
                  onCopy={() => {
                    /* Show tooltip and hide the tooltip after sometime */
                    setCopiedTooltipVisible(true);
                    window.clearTimeout(copyCodeTimeoutRef.current);
                    copyCodeTimeoutRef.current = window.setTimeout(() => {
                      setCopiedTooltipVisible(false);
                    }, 3000);
                  }}
                >
                  <span
                    className="copy-code"
                    ref={copyCodeRef}
                  >
                    {organizationInviteCode}
                    <img
                      src={Assets.CopyIcon}
                      title="Copy invite code"
                    />
                  </span>
                </CopyToClipboard>
                <Overlay target={copyCodeRef} show={isCopiedTooltipVisible} placement="bottom">
                  {(props) => (
                    <Tooltip {...props}>
                      Code Copied!
                    </Tooltip>
                  )}
                </Overlay>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4} className="sub-title">
                {t("dashboard.dashboard_Description")}
              </Col>
            </Row>
          </div>

          <AllTimeMetrics
            noData={noData}
            title={t("dashboard.all_metric")}
            data={allTimeMetrics}
          />
          <DateBasedReporting
            noData={noData}
            title={t("dashboard.date_based_reporting")}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphAppUserUsage={graphAppUserUsage}
            graphActiveChallenges={graphActiveChallenges}
            graphAverageMoveMinutes={graphAverageMoveMinutes}
          />
          <OrganizationActivityBreakdown
            noData={noData}
            title={t("dashboard.org_activity_break")}
            data={orgActivityBreakdown}
            headerData={orgActivityBreakdownHeader}
            setOrgActivityBreakdownSort={setOrgActivityBreakdownSort}
          />
        </Col>
        {noData &&
          <div className="not-available-div">
            <div className="image-div">
              <img
                src={Assets.WARNING}
                title="warning"
              />
            </div>
            <div className="title">{t("dashboard.no_data_title")}</div>
            <div className="desc">
              <span>{t("dashboard.no_data_desc1")}</span>
              <span>
                <CopyToClipboard
                  text={organizationInviteCode}
                  onCopy={() => {
                    /* Show tooltip and hide the tooltip after sometime */
                    setCopiedTooltipVisible2(true);
                    window.clearTimeout(copyCodeTimeoutRef.current);
                    copyCodeTimeoutRef.current = window.setTimeout(() => {
                      setCopiedTooltipVisible2(false);
                    }, 3000);
                  }}
                >
                  <span
                    className="copy-code no-data"
                    ref={copyCodeRef}
                  >
                    {t("dashboard.code")}
                    {organizationInviteCode}
                    <img
                      src={Assets.CopyIcon}
                      title="Copy invite code"
                    />
                  </span>
                </CopyToClipboard>
                <Overlay target={copyCodeRef} show={isCopiedTooltipVisible2} placement="bottom">
                  {(props) => (
                    <Tooltip {...props}>
                      Code Copied!
                    </Tooltip>
                  )}
                </Overlay>
              </span>
              <span>{t("dashboard.no_data_desc3")}</span>
              <span>
                <Link className="orange" to={reverse("app:resources")}>{t("dashboard.resouce")}</Link>
                ).
              </span>
            </div>
          </div>
        }
      </Row>
    </>
  );
};

Home.defaultProps = {
  allTimeMetrics: {},
  orgActivityBreakdown: {},
};

export default Home;
