import React from 'react';
import {
  Button,
  Spinner,
} from 'react-bootstrap';

require('./styles.scss');

const ButtonWithProgress = ({
  text,
  inProgress,
  disabled = false,
  type = 'submit',
  variant = 'primary',
  className = 'button-purple',
  image,
}) => (
  <Button
    type={type}
    variant={variant}
    disabled={disabled || inProgress}
    className={className}
  >
    {text}
    {image && <img src={image} />}
    {
      inProgress &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
  </Button>
);

export default ButtonWithProgress;
