import Auth from '@aws-amplify/auth';
import queryString from 'query-string';

import { reverse } from '~/src/api/urls';
import { GET, makeAPIcall, POST } from '~/src/api/utils';
import { PROVIDER_MAPPING } from '~/src/constants/common';

export async function signUpViaUsernamePassword(username, password, email) {
  return Auth.signUp({
    username,
    password,
    attributes: {
      email: username,
      given_name: '',
      family_name: '',
    },
  });
}

export function signInViaUsernamePassword(username, password) {
  return Auth.signIn(username, password);
}

export async function signOut() {
  try {
    const session = await Auth.currentSession();
    await Auth.signOut();
    window.userID = '';
  } catch(ex) {
    /* on any error completely clear the local storage */
    localStorage.clear();
  }
}

export async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser()
  return Auth.changePassword(user, oldPassword, newPassword);
}

export async function sendForgotPasswordEmail(username) {
  return Auth.forgotPassword(username);
}

export async function setPasswordForResetCode(username, code, newPassword) {
  return Auth.forgotPasswordSubmit(username, code, newPassword);
}

export function getUserDataFromSession(session) {
  return ({
    accessToken: session.signInUserSession.getAccessToken().jwtToken,
    idToken: session.signInUserSession.getIdToken().jwtToken,
    ...session.attributes,
  });
}

export function getProviderFromEmail(email) {
  return (new Promise((resolve, reject) => {
    makeAPIcall(
      reverse('api:get_providers_for_email', email),
      GET,
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          const { provider } = result;
          resolve(PROVIDER_MAPPING[+provider]);
        }
      },
      {},
      {},
      true,
    );
  }));
}

export const getInviteCode = (urlSearch) => {
  const parsed = queryString.parse(urlSearch);
  const parsedInviteCode = parsed['invite-code'];
  if (parsedInviteCode) {
    window.history.replaceState(
      null,
      null,
      window.location.origin + window.location.pathname,
    );
  }
  return parsedInviteCode;
}

export default {
  signInViaUsernamePassword,
  signUpViaUsernamePassword,
  signOut,
  getUserDataFromSession,
  changePassword,
  sendForgotPasswordEmail,
  setPasswordForResetCode,
  getProviderFromEmail,
  getInviteCode,
}
