import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav } from 'react-bootstrap';

import AuthContext from '~/src/context/authContext';
import { reverse } from '~/src/api/urls';
import SubHeader from './sub_header';
import BurgerToggler from './burger_toggler';
import ResponsiveNav from './responsive_nav';
import { POST, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from '~/src/api/errors';
import {
  EDIT,
  LOGOUT,
  LANDING,
  PROFILE_VIEW,
  CHANGE_PASSWORD,
  manage_org_array,
  ORGANIZATION_VIEW,
  profile_link_array,
  PROFILE_LANDING_PAGE,
  RESOURCE_LANDING_PAGE,
  DASHBOARD_LANDING_PAGE,
  ORGANIZATION_LANDING_PAGE,
  CHALLENGE_REPORT_LANDING_PAGE,
} from '~/src/constants/subpage';
import { ADMIN, GUEST,NON_ADMIN, UNVERIFIED, NON_PAYMENT_ADMIN, CANCELLED_SUBSCRIPTION } from '~/src/constants/userTypes';

import Assets from '~/src/constants/assets';
import { PILOT_ORG_SIZE } from '~/src/constants/organization';
import appConfig from "~/config";

import './style.scss';

function Header(props) {
  const { t, i18n } = useTranslation();

  const { onClickHover, onClickSubheader, disabled, isRegister } = props;
  const [open, setOpen] = React.useState(false)

  const {
    currentUserType,
    initialHeader,
    selectedOrganization,
    setInitialHeader,
    handleAuthError
  } = React.useContext(AuthContext);

  const [hovered, setHovered] = React.useState(false);
  const [mainHeader, setMainHeader] = React.useState(DASHBOARD_LANDING_PAGE);
  const [subHeaderObject, setSubheaderObject] = React.useState(initialHeader);
  const [location, setLocation] = React.useState("");

  React.useEffect(() => {
    setMainHeader(initialHeader.mainHeader)
    setSubheaderObject(initialHeader)
  }, [initialHeader]);

  const toggleHover = () => setHovered(!hovered);

  const onHeaderMenuClick = (headerName, subHeaderName) => {
    if ([ORGANIZATION_LANDING_PAGE, PROFILE_LANDING_PAGE].includes(headerName)) {
      onClickHover(LANDING);
      toggleHover;
    }
    setMainHeader(headerName);
    setSubheaderObject({ mainHeader: headerName, subHeader: subHeaderName});
    setInitialHeader({ mainHeader: headerName, subHeader: subHeaderName });
    // for responsive navlink onclick close the list area
    setOpen(!open);
  };

  const paymentHandler = async (error, result) => {
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        window.open(result.payload.url);
      }
    }
  };

  const onManageSubscription = () => {
    const requestData = {
      organization_id: selectedOrganization.pk,
      success_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:organization_page')}`  
    }
    makeAPIcall(reverse('api:manage_subscription'), POST, paymentHandler, requestData);
  }

  const onClickRegister = () => {
    onClickHover(LANDING);
    windowLocation();
  }

  const onSubMenuClick = (option) => {
    setSubheaderObject({ mainHeader: option.mainHeader, subHeader: option.subHeader });
    setInitialHeader({ mainHeader: option.mainHeader, subHeader: option.subHeader });
    // Callback
    onClickSubheader(option.subpage);
  }

  const onHoverMenuClick = (option) => {
    // Callback
    onClickHover(option.subpage);
    // for responsive navlink onclick close the list area
    setOpen(!open);

    const timer = setTimeout(() => {
      setMainHeader(option.mainHeader);
      setSubheaderObject({ mainHeader: option.mainHeader, subHeader: option.subHeader });
      setInitialHeader({ mainHeader: option.mainHeader, subHeader: option.subHeader });
    }, 500);
    return () => clearTimeout(timer);
  }

  const windowLocation = () => {
    setLocation(window.location.pathname);
  }

  const showSubheader = (subHeaderObject || {}).subHeader && currentUserType !== GUEST;

  /* Determines the url that should be there for logo */
  /* Also used for `home` in case the user is not admin or not invited */
  let logoLink = reverse('app:home_page');
  switch (currentUserType) {
    case UNVERIFIED:
      logoLink = reverse('app:unconfirmed');
      break;
    case NON_PAYMENT_ADMIN:
      logoLink = reverse('app:payment');
      break;
    case NON_ADMIN:
      logoLink = reverse('app:createOrg');
      break;
    case CANCELLED_SUBSCRIPTION:
      logoLink = reverse('app:canceled_subscription');
      break;
    default:
      logoLink = reverse('app:home_page');
      break;
  }

  const showManageSunscription = selectedOrganization && selectedOrganization.organization_size !== PILOT_ORG_SIZE;

  return (
    <div className="header-nav-wrapper">
      <Navbar collapseOnSelect expand="lg" className="navbar-area">
        <Navbar.Brand
          as={Link}
          to={logoLink}
        >
          <img
            alt="logo-pic"
            src={Assets.LogopicIcon}
            className="d-inline-block align-top pac-plus-logo"
          />
        </Navbar.Brand>
        {
          !disabled &&
            <>
              {/* testing */}
              {[NON_ADMIN, UNVERIFIED, NON_PAYMENT_ADMIN, CANCELLED_SUBSCRIPTION].includes(currentUserType) ?

                <Link
                  to={logoLink}
                  className="responsive-unauthorized-home-link"
                >
                  {t("common.home")}
                </Link>
                :
                null
              }
              {currentUserType !== GUEST ?
                <BurgerToggler isOpen ={open} onHide ={() => setOpen(!open)}/>
                :
                (isRegister &&
                <Link
                  to={reverse("app:register_page")}
                  onClick={() => onClickRegister()}
                  className="brand navbar-brand brand-text-bold regiester-responsive"
                >
                  {t("common.register_button")}
                </Link>)
              }
              <ResponsiveNav
                open ={open}
                onHoverMenuClick={onHoverMenuClick}
                selectedOrganization={selectedOrganization}
                onHeaderMenuClick={onHeaderMenuClick}
                currentUserType={currentUserType}
                onManageSubscription={onManageSubscription}
              />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto main-menu">
                  <React.Fragment>
                    {currentUserType === ADMIN ?
                      <Nav className="non-responsivenav-area">
                        <Link
                          to={reverse("app:home_page")}
                          className={`brand navbar-brand brand-text-bold ${mainHeader !== DASHBOARD_LANDING_PAGE ? 'brand-text-normal' : ''}`}
                          onClick={() => onHeaderMenuClick(DASHBOARD_LANDING_PAGE, null)}
                        >
                          {t("common.dashboard_menu")}
                          {mainHeader == DASHBOARD_LANDING_PAGE && <img alt="vector-pic" src={Assets.VectorIcon} style={{ height: 6, width: 17 }} className="vector-image"/>}
                        </Link>
                        <div className="dropdown">
                          <Link
                            to={reverse("app:organization_page")}
                            className={`brand navbar-brand brand-text-bold ${mainHeader !== ORGANIZATION_LANDING_PAGE ? 'brand-text-normal' : ''}`}
                            onClick={() => onHeaderMenuClick(ORGANIZATION_LANDING_PAGE, ORGANIZATION_VIEW)}
                          >
                            {t("common.organization_menu")}
                            {mainHeader == ORGANIZATION_LANDING_PAGE && <img alt="vector-pic" src={Assets.VectorIcon} style={{ height: 6, width: 17 }} className="vector-image" />}

                          </Link>
                          <div className="dropdown-content manage-organization-dropdown-menu">
                            {manage_org_array.map((option) => {
                              if (option.isExternalLink) {
                                if (showManageSunscription) {
                                  return(
                                    <Link key={option.name} onClick={() => onManageSubscription()}>
                                      {t(option.name)}
                                    </Link>
                                  );
                                }
                                return null;
                              } else {
                                return (
                                  <Link key={option.name} to={option.link} onClick={() => onHoverMenuClick(option)}>
                                    {t(option.name)}
                                  </Link>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <Link
                          to={reverse("app:challenge_report")}
                          className={`brand navbar-brand brand-text-bold ${mainHeader !== CHALLENGE_REPORT_LANDING_PAGE ? 'brand-text-normal' : ''}`}
                          onClick={() => onHeaderMenuClick(CHALLENGE_REPORT_LANDING_PAGE, null)}
                        >
                          {t("common.challenge_report_menu")}
                          {mainHeader == CHALLENGE_REPORT_LANDING_PAGE && <img alt="vector-pic" src={Assets.VectorIcon} style={{ height: 6, width: 17 }} className="vector-image" />}
                        </Link>
                        <Link
                          to={reverse("app:resources")}
                          className={`brand navbar-brand brand-text-bold ${mainHeader !== RESOURCE_LANDING_PAGE ? 'brand-text-normal' : ''}`}
                          onClick={() => onHeaderMenuClick(RESOURCE_LANDING_PAGE, null)}
                        >
                          {t("common.resource_menu")}
                          {mainHeader == RESOURCE_LANDING_PAGE && <img alt="vector-pic" src={Assets.VectorIcon} style={{ height: 6, width: 17 }} className="vector-image" />}
                        </Link>
                      </Nav>
                      :
                      [NON_ADMIN, UNVERIFIED, NON_PAYMENT_ADMIN, CANCELLED_SUBSCRIPTION].includes(currentUserType)  ?
                       <Nav className="non-responsivenav-area">
                         <Link
                           to={logoLink}
                           className="brand navbar-brand brand-text-bold"
                         >
                           {t("common.home")}
                         </Link>
                       </Nav>
                       :
                        null
                    }
                  </React.Fragment>
                </Nav>
                <Nav className="register-myprofile">
                  {currentUserType === GUEST ? 
                    <React.Fragment>
                      {isRegister &&
                        <Link
                          to={reverse("app:register_page")}
                          onClick={() => onClickRegister()}
                          className="nav-link"
                          >
                          {t("common.register_button")}
                        </Link>
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Nav className="non-responsivenav-area">
                        <Navbar.Text className="gale-navbar-text">
                          &nbsp;
                          {(currentUserType === ADMIN || currentUserType === NON_PAYMENT_ADMIN || currentUserType === CANCELLED_SUBSCRIPTION) &&
                            <>
                              <img alt="rightarrow-pic" src={Assets.RightArrowIcon} className="right-arrow-image" />
                              {t("common.managing")} 
                              <span className='org-name'>{(selectedOrganization && selectedOrganization.name) || ''}</span>
                            </>
                          }
                        </Navbar.Text>
                        <div className="dropdown">
                          <Link
                            to={reverse("app:profile_page")}
                            className={`profile-nav-link ${subHeaderObject.mainHeader !== PROFILE_LANDING_PAGE ? 'profile-nav-link-active' : ''}`}
                            onClick={() => onHeaderMenuClick(PROFILE_LANDING_PAGE, PROFILE_VIEW)}
                          >
                            <img src={Assets.UserWhiteIcon} alt="User" className="white-user-icon"/>
                            {t("common.profile_menu")}
                          </Link>
                          <div className="dropdown-content">
                            {profile_link_array.map((option) => {
                              return (
                                <Link
                                  key={option.name}
                                  to={option.link || '#'}
                                  onClick={() => onHoverMenuClick(option)}
                                >
                                  {t(option.name)}
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </Nav>
                    </React.Fragment>
                  }
                </Nav>
              </Navbar.Collapse>
          </>
        }
      </Navbar>
      {showSubheader && (
        <SubHeader
          showManageSunscription={showManageSunscription}
          subHeaderObject={subHeaderObject}
          onClickSubheader={onSubMenuClick}
          onManageSubscription={onManageSubscription}
        />
      )}
    </div>
  );
}

export default Header;
