import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
} from 'react-bootstrap';

import AuthContext from '~/src/context/authContext';

const LogoutPage = () => {
  const { signOutCurrentUser, setInitialHeader } = React.useContext(AuthContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    window.setWindowTitle(t('common.logout'));
    setInitialHeader({ mainHeader: '', subHeader: '' });

    /* Call signout */
    signOutCurrentUser();
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ flex: 1 }}
    >
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default LogoutPage;
