import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
} from 'react-bootstrap';
import Select from 'react-select'

import Constants from '~/src/constants/dashboard';
import CounterCard from '../components/counterCard';
import Graph from './graph';

import Section from './section';
import './style.scss';

const DateBasedReporting = React.memo(({
  title,
  dateRange,
  setDateRange,
  graphAppUserUsage,
  graphActiveChallenges,
  graphAverageMoveMinutes,
  noData
}) => {
  const { t, i18n } = useTranslation();

  const TIME_OPTION = Constants.TIME_OPTION.map(data => ({
    ...data,
    label: t(data.label),
  }));

  React.useEffect(() => {
    setDateRange(TIME_OPTION.find(d => d.value === dateRange.value));
  }, [i18n.language])

  return (
    <Section
      title={t(title)}
      className={`date-based-reporting ${noData && 'add-opacity'}`}
      subTitle={<>
        <span>{t("dashboard.set_up_date_range")} &nbsp;</span>
        <Select
          key={i18n.language}
          className="date-range-selector"
          options={TIME_OPTION}
          value={dateRange}
          onChange={setDateRange}
        />
      </>}
    >
      <Col xs={12} lg={9} className="graph-col-container">
        <Graph
          helpText={t('dashboard.timeline_graph_instruction')}
          dataMeta={Constants.GRAPH_DATA_META}
          labels={graphAverageMoveMinutes.graphLabels}
          data={[graphAverageMoveMinutes.graphData, graphAppUserUsage]}
        />
      </Col>
      <Col xs={12} lg={3} className="cards-container">
        <CounterCard
          count={graphAverageMoveMinutes.currentAvgMoveMinutes}
          delta={graphAverageMoveMinutes.delta}
          deltaSign={graphAverageMoveMinutes.deltaSign}
          title={Constants.GRAPH_CARDS_META_MOVE_MINUTES.title}
          countTitle={Constants.GRAPH_CARDS_META_MOVE_MINUTES.countTitle}
          helpText={Constants.GRAPH_CARDS_META_MOVE_MINUTES.helpText}
        />
        <CounterCard
          count={graphActiveChallenges.currentChallengesCount}
          delta={graphActiveChallenges.delta}
          deltaSign={graphActiveChallenges.deltaSign}
          title={Constants.GRAPH_CARDS_META_CHALLENGES.title}
          countTitle={Constants.GRAPH_CARDS_META_CHALLENGES.countTitle}
          helpText={Constants.GRAPH_CARDS_META_CHALLENGES.helpText}
        />
      </Col>
    </Section>
  );
});

DateBasedReporting.defaultProps = {
  title: 'date_based_reporting',
};

export default DateBasedReporting;
