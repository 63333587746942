import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import CTA from '~/src/components/ctaBox';
import { reverse } from '~/src/api/urls';
import AuthContext from '~/src/context/authContext';
import { LANDING } from '~/src/constants/subpage';
import { getInviteCode } from '~/src/utils/auth';
import SocialLoginButton from '~/src/components/socialLoginButton';

import LoginPage from './login';
import Assets from '~/src/constants/assets';

import '../style.scss';

const LoginContainer = (props) => {

  const { t, i18n } = useTranslation();
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(LANDING);
  const { inviteCode, setCurrentLandingPage } = React.useContext(AuthContext);

  React.useEffect(() => {
    window.setWindowTitle(t('auth.login'));
    setCurrentPage(LANDING);
    setCurrentLandingPage('login');
  }, []);

  return (
    <Row className="auth-container align-items-center">
      <Col xs={12} md={6} className="d-flex justify-content-center">
          {currentPage === LANDING && (
            <CTA
              miniPadding
              className="login-register-social-page"
            >
              <div className="login-form-container">
                <p className="form-title">{t('auth.welcome_pac')}</p>
                <p className="form-describe">
                  {t('auth.welcome_description1')}
                  <strong>
                    {t('auth.welcome_description2')}
                    &nbsp;<img src={Assets.PacImage} alt="pac-icon" className="pac-icon"/>
                    {t('auth.welcome_description3')}
                  </strong>
                </p>
                <div className="login-links-container">
                  <div className="social-links">
                    <div>
                      <SocialLoginButton
                        onClick={() => {
                          localStorage.setItem('loginHook', 'login');
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Apple,
                            customState: JSON.stringify({
                              inviteCode: inviteCode,
                            }),
                          });
                        }}
                        text={t('auth.login_apple')}
                        imagePath={Assets.AppleIcon}
                      />
                    </div>
                    <div>
                      <SocialLoginButton
                        onClick={() => {
                          localStorage.setItem('loginHook', 'login');
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Google,
                            customState: JSON.stringify({
                              inviteCode: inviteCode,
                            }),
                          });
                        }}
                        text={t('auth.login_google')}
                        imagePath={Assets.GmailIcon}
                      />
                    </div>
                    <div>
                      <SocialLoginButton
                        onClick={() => {
                          localStorage.setItem('loginHook', 'login');
                          Auth.federatedSignIn({
                            provider: CognitoHostedUIIdentityProvider.Facebook,
                            customState: JSON.stringify({
                              inviteCode: inviteCode,
                            }),
                          });
                        }}
                        text={t('auth.login_fb')}
                        imagePath={Assets.FBIcon}
                      />
                    </div>
                    <div>
                      <SocialLoginButton
                        onClick={() => setCurrentPage('email')}
                        text={t('auth.login_email')}
                        imagePath={Assets.EmailIcon}
                      />
                    </div>
                  </div>
                  <div>
                    <span>{t('auth.Or_text')}</span>
                    <Link
                      to={reverse("app:register_page")}
                      className="register-link">
                      {t('auth.register_now')}
                    </Link>
                  </div>
                </div>
              </div>
            </CTA>
          )}
          {currentPage === 'email' && <LoginPage setCurrentPage={setCurrentPage} />}
      </Col>
    </Row>
  )
}

export default LoginContainer;
