import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalBox from "./index";
import './style.scss';

function LaunchGuideModal(props) {
  const { t, i18n } = useTranslation();
  const { onHide } = props;

  return (
    <ModalBox className="modal-container" onHide={onHide} showicon={true} exclamationicon={false} >
      <React.Fragment>
        <h4 className="modal-title">{t("launch_guide.launch_guide_modal_title")}</h4>
        <p className="describe-text-1">
          {t("launch_guide.launch_guide_modal_desc")}
        </p>

        <div className="button-area" style={{ textAlign: "center" }}>
          <Button
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={onHide}
            className="button-purple-outline"
          >
            {t("launch_guide.close")}
          </Button>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

export default LaunchGuideModal;
