import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ORGANIZATION_SIZE_CHOICES} from '~/src/constants/organization';
import OrgSizeCard from "~/src/components/orgSizeCard";

import ModalBox from "./index";
import './style.scss';

function ChangePlanModal(props) { 
  const { t, i18n } = useTranslation();
  const { onHide, userDelete, style, updatePlan, selectedPlan } = props;
  const [selectedPlan, setSelectedPlan] = React.useState(selectedPlan);

  return (
    <ModalBox className="modal-container" onHide={onHide} showicon={false} exclamationicon={false} style={style} dialogClassName={'changePlanModal'}>
      <React.Fragment>
        <h4 className="modal-title">{t("launch_guide.select_org")}</h4>
        <div className="org-sizes">
          {ORGANIZATION_SIZE_CHOICES.map((data, index) => {
              if (data.value === 'pilot') return;
              return <OrgSizeCard
                key={index}
                selectedValue={data.value}
                onSelect={() => 
                  {
                    setSelectedPlan(data.value);
                  }
                }
                selected={data.value === selectedPlan}
              />
            })
          }
        </div>

        <div className="button-area" style={{ textAlign: "center" }}>
          <Button
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={() => updatePlan(selectedPlan)}
            className="button-purple"
          >
            {t("launch_guide.choose_plan")}
          </Button>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

export default ChangePlanModal;