import React from "react";
import { Link } from "react-router-dom"; 
import { useTranslation } from 'react-i18next';

import { Navbar, Nav, Button } from 'react-bootstrap';
import { profile_link_array, manage_org_array, ORGANIZATION_LANDING_PAGE, PROFILE_LANDING_PAGE } from "../../constants/subpage";

import Assets from '~/src/constants/assets';

function SubHeader(props) {
  const { t, i18n } = useTranslation();

  const { subHeaderObject, onClickSubheader, onManageSubscription, showManageSunscription } = props;
  const [subHeader, setSubHeader] = React.useState(subHeaderObject.subHeader);

  React.useEffect(() => {
    setSubHeader(subHeaderObject.subHeader)
  }, [props]);

  const onSwitchTab = (name) => {
    setSubHeader(name);
  };

  const renderSwitch = (subHeaderObject) => {
    switch (subHeaderObject.mainHeader) {
      case ORGANIZATION_LANDING_PAGE:
        return manage_org_array.map((x, i) => {
          if (x.isExternalLink) {
            if (showManageSunscription) {
              return (
                <Button
                  className={'brand navbar-brand sub-brand-text align-right'}
                  onClick={() => { onManageSubscription(); }}
                  key={x.name}
                >
                  {t(x.name)}
                </Button>
              );

            }
            return null;
          } else if (x.isLink) {
            return (
              <Link
                to={x.link}
                className={`brand navbar-brand sub-brand-text ${(subHeader === x.subHeader) ? 'active-link' : ''}`}
                onClick={() => { onSwitchTab(x.subHeader); }}
                key={x.subHeader}
              >
                {t(x.name)}
              </Link>
            );
          } else {
            return (
              <Link
                to={x.link}
                key={x.subHeader}
                onClick={() => {
                  onSwitchTab(x.subHeader);
                  onClickSubheader(x);
                }}
                className={`brand navbar-brand sub-brand-text ${(subHeader === x.subHeader) ? 'active-link' : ''}`}
              >
                {t(x.name)}
              </Link>
            );
          }
        });
      case PROFILE_LANDING_PAGE:
        return profile_link_array.map((x) => {
          if (x.subHeader !== 'logout') {
            return (
              <Link
                to="#"
                key={x.subHeader}
                onClick={() => {
                  onSwitchTab(x.subHeader);
                  onClickSubheader(x);
                }}
                className={`brand navbar-brand sub-brand-text ${(subHeader === x.subHeader) ? 'active-link' : ''}`}
              >
                {t(x.name)}
              </Link>
            );
          } else {
            return (
              <React.Fragment>
                <Link
                  to="#"
                  key={x.subHeader}
                  onClick={() => onClickSubheader(x)}
                  className="brand navbar-brand sub-brand-text brand-text-logout"
                >
                  <img
                    alt="logout-pic"
                    src={Assets.LogoutIcon}
                    style={{
                      height: 15,
                      width: 16,
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: 5,
                    }}
                    className="logout-icon-image"
                  />
                  {t(x.name)}
                </Link>
              </React.Fragment>
            );
          }
        });

      default:
        return;
    }
  };
  return (
    <div className="sub-header">
      <Navbar collapseOnSelect expand="lg" className="sub-navbar-area">
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="sub-nav-links-area">
            {renderSwitch(subHeaderObject)}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default SubHeader;
