import React from "react";
import { useTranslation } from "react-i18next";

import { ORGANIZATION_SIZE_CHOICES } from "~/src/constants/organization";
import { Button } from "react-bootstrap";
import { CONTACT_US_INFO } from "~/src/constants/common";

import "./style.scss";

const OrgSizeCard = ({ selectedValue, selected, button, onSelect }) => {
  const { t, i18n } = useTranslation();
  const orgSizeObj = ORGANIZATION_SIZE_CHOICES.find(
    (sizeChoice) => sizeChoice.value === selectedValue
  );

  return (
    <Button
      className={
        orgSizeObj.value === "10,000 plus"
          ? `org-size-card last-card ${selected && "selected"}`
          : button && button === t("organization.button")
          ? `org-size-card padding ${selected && "selected"}`
          : `org-size-card ${selected && "selected"}`
      }
      variant="link"
      onClick={onSelect}
    >
      {orgSizeObj.label && <div className="title">{t(orgSizeObj.label)}</div>}
      <div className="subtitle">{t("organization.members")}</div>
      <div className="icon-container">
        {orgSizeObj.prize && (
          <div className="icon-row prize-text">
            <p className="prize-text-value">{t(orgSizeObj.prize)}</p>
            <p className="extension">{t("organization.ext")}</p>
          </div>
        )}
      </div>
      {orgSizeObj.value && orgSizeObj.value === "10,000 plus" ? (
        <div className="select-btn-contactUs">
          <a
            className="select-btn-text"
            href={CONTACT_US_INFO[`STEP1_${i18n.language}`]}
            onClick={() =>
              pushEventToDataLayer(
                "Onboarding Step1",
                "Contact Us clicked",
                "Contact Us",
                "1",
                appUserId
              )
            }
          >
            {t("organization.contactBtn")}
          </a>
        </div>
      ) : button && button === t("organization.button") ? (
        <div className="select-btn no-display">
          <p className="select-btn-text">{t("organization.selectBtn")}</p>
        </div>
      ) : (
        <div className="select-btn">
          <p className="select-btn-text">{t("organization.selectBtn")}</p>
        </div>
      )}
    </Button>
  );
};

OrgSizeCard.defaultProps = {
  selected: false,
};

export default OrgSizeCard;
