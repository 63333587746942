import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Table, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { pushToPageLayer } from '~/src/utils/GA';

import { reverse } from "~/src/api/urls";
import { GET, DELETE, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from "~/src/context/organizationContext";
import { ORGANIZATION_LANDING_PAGE, TEAMS_LANDING_PAGE, LANDING } from "../constants/subpage";
import ReusableContent from "../components/reusableTitleContent";
import DeleteTeamModal from "../components/modal/deleteTeamModal";

const TeamList = () => {
  const { t, i18n } = useTranslation();

  const { handleAuthError, currentPage, setInitialHeader, setToastMsg } = React.useContext(AuthContext);

  const { selectedOrganization, resetOrganization } = React.useContext(OrganizationContext);

  const [selectedPage, setSelectedPage] = React.useState(currentPage);
  const [teams, setTeams] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentTeam, setCurrentTeam] = React.useState({});
  const [show, setShow] = React.useState(false);
  

  const teamsListResultHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setTeams(result.payload);
    }
    setIsLoading(false);
  }

  const renderCaptainName = (teamId) => {
    const team = teams[teamId];
    if (!team.hasOwnProperty('captain')) {
      return <td>-</td>;
    }
    if (team.captain.first_name && team.captain.last_name) {
      return <td>{`${team.captain.first_name} ${team.captain.last_name}`}</td>;
    }
    if (team.captain.first_name) {
      return <td>{team.captain.first_name}</td>;
    }
    if (team.captain.last_name) {
      return <td>{team.captain.last_name}</td>;
    }
    return <td>-</td>;
  };

  const deleteTeamResultHandler = async (error, result) => {
    setIsLoading(false);
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("teams.team_deleted"), false);
      resetOrganization();
      setShow(false);
    }
  };

  const deleteTeam = () => {
    setIsLoading(true);
    makeAPIcall(reverse('api:team_delete', currentTeam), DELETE, deleteTeamResultHandler);
  };

  const handleDeleteClick = (team) => {
    setCurrentTeam(team);
    setShow(true);
    setSelectedPage(LANDING);
  };

  const renderActionLinks = (team) => {
      return (
        <React.Fragment>
          <td className="group-td-action-area">
            <a
              className="group-td-delete-btn"
              onClick={() => handleDeleteClick(team)}
            >
              {t("team.delete_btn")}
            </a>
          </td>
        </React.Fragment>
      );
  }

  const hide = () => {
    setShow(false)
  };

  const renderTeams = () => {
    if (teams) {
      const teamRows = [];
      const teamIds = Object.keys(teams);
      teamIds.forEach((teamId, index) => {
        teamRows.push(
          <tr key={index}>
            <td>{teams[teamId].team_name}</td>
            {renderCaptainName(teamId)}
            <td className="text-center">{teams[teamId].org_member_count}</td>
            <td className="text-center">{teams[teamId].teams_member_count}</td>
            <td>{teams[teamId].group_name}</td>
            {renderActionLinks(teams[teamId].org_team_pk)}
          </tr>
        );
      });
      return teamRows;
    }
  };

  React.useEffect(() => {
    if (Object.keys(selectedOrganization).length !== 0) {
      setIsLoading(true);
      makeAPIcall(reverse("api:team_list", selectedOrganization.pk), GET, teamsListResultHandler);
    }
  }, [selectedOrganization]);

  React.useEffect(() => {
    // To set the initial header component
    window.setWindowTitle(t('team.view_teams'));
    pushToPageLayer(t('team.view_teams'));
    setInitialHeader({ mainHeader: ORGANIZATION_LANDING_PAGE, subHeader: TEAMS_LANDING_PAGE });
  }, []);

  const teams_content_data = {
    describeText: t("team.view_teams_description"),
    contentTitle: t("team.view_teams"),
  };

  return (
    <Row className="manage-teams-container justify-content-center">
      {selectedPage === LANDING && (
        <Container className="teams-page">
          <Row>
            <Col sm={8}>
              <ReusableContent
                content_data={teams_content_data}
                describeStyle={{marginBottom: 27}}
              />
            </Col>
            <Col sm={4}></Col>
          </Row>
          <Row>
            <Col>
              <div className="custom-table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th width="30%">{t("team.team_name")}</th>
                      <th width="10%">{t("team.captain")}</th>
                      <th width="15%"className="text-center">{t("team.org_members")}</th>
                      <th width="15%" className="text-center">{t("team.team_size")}</th>
                      <th width="17%">{t("team.group_name")}</th>
                      <th width="13%">{t("team.team_action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      isLoading &&
                        <tr>
                          <td colSpan={5} className="text-center">
                            <Spinner animation="border" role="status">
                              <span className="sr-only">{t("dashboard.loading")}</span>
                            </Spinner>
                          </td>
                        </tr>
                    }
                    {renderTeams()}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {show && <DeleteTeamModal disabled={isLoading} onDeleteClick={deleteTeam} onHide={hide}/>}
    </Row>
  );
}

export default TeamList;
