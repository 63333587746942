import React from "react";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';

import './style.scss';

function ReusableContent({content_data, showLink,containerStyle, titleStyle, describeStyle}) {
  const { describeText,contentTitle,LinkPath,linkText } = content_data;
  return (
    <div className="reusable-container" style={containerStyle}>
      <div className="content-title" style={titleStyle}>
        {contentTitle}
        {showLink && (
          <Button variant="link">
            <Link to={LinkPath}>{linkText}</Link>
          </Button>
        )}
      </div>
      <div className="content-describe" style={describeStyle}>{describeText}</div>
    </div>
  );
}

export default ReusableContent;

ReusableContent.defaultProps = {
  showLink: false,

};
