import React from "react";
import {
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';

import Assets from '~/src/constants/assets';

import './style.scss';

/**
 * Tooltip that shows a small icon and when hover shows the bootstrap tooltip
 * Usage:
 *  <Tooltip text="Sample tooltip text" />
 *
 * To control the margins around, use the class `pac-tootip-icon`
 *
 */
function ToolTip(props) {
  const {
    img,
    text,
    className,
    imgClassName,
    placement
  } = props;

  if (!text) {
    return null;
  }

  return (
    <span className={`pac-tootip-icon ${className}`}>
      <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>} placement={placement}>
        <img
          className={`tooltip-img ${imgClassName}`}
          src={img}
          alt={text}
        />
      </OverlayTrigger>
    </span>
  );
}

ToolTip.defaultProps = {
  imgClassName: '',
  className: '',
  img: Assets.InfoIcon,
};

export default ToolTip;
