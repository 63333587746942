import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalBox from "./index";
import './style.scss';

function DeleteTeamModal(props) { 
  const { t, i18n } = useTranslation();
  const { onHide, modaltitle, onDelete, onDeleteClick, userDelete ,style } = props;

  return (
    <ModalBox className="modal-container" onHide={onHide} showicon={true} exclamationicon={true} style={style}>
      <React.Fragment>
        <h4 className="modal-title">{t("common.delete_modal_title")}</h4>
        <p className="describe-text-2">
          {t("common.delete_modal_subtitle1")}<strong>{t("common.delete_modal_subtitle2")}:</strong>
        </p>

        <div className="ul-area">
          {userDelete ?
            <ul>
              <li className="describe-list-text">
                <span style={{ marginRight: 4, display: 'inline-block' }}>•</span>{t("admin.admin_access_message")}
              </li>
            </ul>
            :
            <ul>
              <li className="describe-list-text">
                <span style={{ marginRight: 4, display: 'inline-block' }}>•</span>{t("team.delete_team_modal_desc1")}
              </li>
              <li className="describe-list-text">
                <span style={{ marginRight: 4, display: 'inline-block' }}>•</span>{t("team.delete_team_modal_desc2")}
              </li>
            </ul>
          }
        </div>
        <div className="button-area" style={{ textAlign: "center" }}>
          <Button
            disabled={props.disabled}
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={onHide}
            className="button-purple"
          >
            {t("common.cancel_btn")}
          </Button>
          <br />
          <Button
            disabled={props.disabled}
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={onDeleteClick}
            className="button-outline"
          >
          {userDelete ?  `${t("common.delete_this_small")} ${t("common.admin")}` : `${t("common.delete_this")} ${t("common.team")}`}
          </Button>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

export default DeleteTeamModal;

DeleteTeamModal.defaultProps = {
  modaltitle: "Are you sure?",
  onDelete: () => {
    console.log("delete function got clicked");
  },
};
