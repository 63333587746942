import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pushToPageLayer } from '~/src/utils/GA';

import { reverse } from "~/src/api/urls";
import { POST, DELETE, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import ProfileContext from "~/src/context/profileContext";
import OrganizationContext from "~/src/context/organizationContext";
import AdminContext from '~/src/context/adminContext';
import { Container, Button, Table, Col, Row, Spinner } from 'react-bootstrap';
import { LANDING, EDIT, ORGANIZATION_LANDING_PAGE, ADMIN_LANDING_PAGE } from "~/src/constants/subpage";
import DeleteModal from "~/src/components/modal/deleteModal";
import Assets from '~/src/constants/assets';
import InviteAdminModal from "./inviteAdmin";
import EditUser from "./edit";
import ReusableContent from "~/src/components/reusableTitleContent";
import Select from 'react-select';
import {LANGUAGE_DROPDOWN_ADMIN_EN,
  LANGUAGE_DROPDOWN_ADMIN_FR
} from "../constants/organization";

const ListUsers = () => {
  const { t, i18n } = useTranslation();

  const { handleAuthError, currentPage, setInitialHeader, setToastMsg } = React.useContext(AuthContext);
  const { userData } = React.useContext(ProfileContext);
  const { selectedOrganization } = React.useContext(OrganizationContext);
  const { admins, adminInvites, setSelectedAdmin, resetAdminData, isLoading, setIsLoading } = React.useContext(AdminContext);

  const [selectedAdmin, setLocalSelectedAdmin] = React.useState({});
  const [selectedInvite, setSelectedInvite] = React.useState({});
  const [selectedPage, setSelectedPage] = React.useState(currentPage);
  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [showAdminDeleteModal, setShowAdminDeleteModal] = React.useState(false);
  const [showInviteDeleteModal, setShowInviteDeleteModal] = React.useState(false);


  const updateCurrentPage = (subpage) => {
    setSelectedPage(subpage);
  };

  const handleAddAdminClick = () => {
    setShowInviteModal(true);
  };

  const handleEditClick = (admin) => {
    setSelectedAdmin(admin);
    setSelectedPage(EDIT);
  }

  const deleteAdminResultHandler = async (error, result) => {
    setIsLoading(false);
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("admin.admin_deleted"), false);
      resetAdminData();
      setShowAdminDeleteModal(false);
    }
  };

  const deleteAdmin = () => {
    setIsLoading(true);
    makeAPIcall(reverse('api:admin_member_delete', selectedAdmin.pk), DELETE, deleteAdminResultHandler);
  };

  const handleDeleteAdminClick = (admin, page) => {
    setLocalSelectedAdmin(admin);
    setShowAdminDeleteModal(true);
    setSelectedPage(LANDING);
  };

  const sendInviteResultHandler = async (error, result) => {
    setIsLoading(false);
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("admin.invite_resent"), false);
    }
  };

  const resendInvite = (adminInvite) => {
    makeAPIcall(reverse('api:admin_invite_send', adminInvite.pk), POST, sendInviteResultHandler);
  };

  const deleteInviteResultHandler = async (error, result) => {
    setIsLoading(false);
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("admin.admin_deleted"), false);
      resetAdminData();
      setShowInviteDeleteModal(false);
    }
  };

  const deleteAdminInvite = () => {
    setIsLoading(true);
    makeAPIcall(reverse('api:admin_invite_delete', selectedInvite.pk), DELETE, deleteInviteResultHandler);
  };

  const handleDeleteInviteClick = (adminInvite) => {
    setSelectedInvite(adminInvite);
    setShowInviteDeleteModal(true);
  }

  const closeModal = () => {
    setShowInviteModal(false);
    setShowAdminDeleteModal(false);
    setShowInviteDeleteModal(false);
  }

  React.useEffect(() => {
    // To set the initial header componenet
    window.setWindowTitle(t('admin.manage_admin'));
    pushToPageLayer(t('admin.manage_admin'));
    setInitialHeader({ mainHeader: ORGANIZATION_LANDING_PAGE, subHeader: ADMIN_LANDING_PAGE });
  }, []);

  const renderAdmins = () => {
    if (admins) {
      return (
        admins.map((admin, index) => (
          <tr key={index}>
            <td className="action-td">{(admin.profile && admin.profile.first_name) || '-'}</td>
            <td className="action-td">{(admin.profile && admin.profile.last_name) || '-'}</td>
            <td className="action-td">{admin.preferred_email || '-'}</td>
            <td className="action-td-invite">{t("admin.active")}</td>
            <td className="action-link-area">
              <a
                className="action-edit-link"
                variant="link"
                onClick={() => handleEditClick(admin)}
              >
                {t("admin.edit")}
              </a>
              &nbsp;&nbsp;
              { (admin.profile  && userData.pk === admin.profile.pk) ? null :
                <a
                  className="action-delete-link"
                  variant="link"
                  onClick={() => handleDeleteAdminClick(admin)}
                >
                  {t("admin.delete_btn")}
                </a>
              }
            </td>
            <td>
              <div className="language-dropdown">
                {i18n.language === 'en' ?
                  <Select
                    className="language-options" 
                    options={LANGUAGE_DROPDOWN_ADMIN_EN}
                    >
                  </Select>
                : <Select 
                    className="language-options" 
                    options={LANGUAGE_DROPDOWN_ADMIN_FR}
                    >
                  </Select>}
              </div>
            </td>
          </tr>
        ))
      );
    }
  };

  const renderAdminInvites = () => {
    if (adminInvites) {
      return (
        adminInvites.map((adminInvite, index) => (
          <tr key={index}>
            <td className="action-td"></td>
            <td className="action-td"></td>
            <td className="action-td">{adminInvite.email}</td>
            <td className="action-td-invite">
              {t("admin.invited")}
              <br/>
              <Button
              className="invite-resend-btn"
                variant="link"
                onClick={() => resendInvite(adminInvite)}
              >
                {t("admin.resend_invite")}
              </Button>
            </td>
            <td className="action-link-area">
              <a
                variant="link"
                className="action-delete-link"
                onClick={() => handleDeleteInviteClick(adminInvite)}
              >
                {t("admin.delete_btn")}
              </a>
            </td>
            <td>
              <div className="language-dropdown">
                {i18n.language === 'en' ?
                  <Select
                    className="language-options" 
                    options={LANGUAGE_DROPDOWN_ADMIN_EN}
                    >
                  </Select>
                : <Select 
                    className="language-options" 
                    options={LANGUAGE_DROPDOWN_ADMIN_FR}
                    >
                  </Select>}
              </div>
            </td>
          </tr>
        ))
      );
    }
  };

  return (
    <Row className="manage-admins-container justify-content-center">
      {selectedPage === LANDING && (
        <Container className="admin-page">
          <Row>
            <Col xs={12} md={9} className="">
              <div className="admin-content-container">
              <p className="admin-title">{t("admin.existing_admin")}</p>
              <p className="admin-describe">{t("admin.existing_admin_description")}</p>
              </div>
            </Col>
            <Col sm={3}>
              <div className="add-admin-button-area">
              <Button
                className="button-purple"
                onClick={() => handleAddAdminClick()}
              >
                {t("admin.add_an_admin")}
              </Button></div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="custom-table">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>{t("admin.first_name")}</th>
                      <th>{t("admin.last_name")}</th>
                      <th>{t("admin.email_address")}</th>
                      <th className="admin-th-status">{t("admin.status")}</th>
                      <th className="admin-th-action">{t("admin.action")}</th>
                      <th className="admin-th-language">{t("admin.language")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      isLoading &&
                        <tr>
                          <td colSpan={5}>
                            <Col align="center">
                              <Spinner animation="border" role="status">
                                <span className="sr-only">{t("dashboard.loading")}</span>
                              </Spinner>
                            </Col>
                          </td>
                        </tr>
                    }
                    {renderAdmins()}
                    {renderAdminInvites()}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          <Row className="tip-row justify-content-center">
            <Col xs={12} lg={6} md={7} sm={11} className="tip">
              <div className="icon">
                <img
                  className="card-icon"
                  src={Assets.BulbIcon}
                />
              </div>
              <div className="description">
                <div className="title">{t("admin.invite_title")}</div>
                <div className="subtitle">{t("admin.invite_subtitle")}</div>
                <ul className="subtitle">
                  <li>{t("admin.point1")}</li>
                  <li>{t("admin.point2")}</li>
                  <li>{t("admin.point3")}</li>
                  <li>{t("admin.point4")}</li>
                  <li>{t("admin.point5")}</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      {selectedPage === EDIT && <EditUser updateCurrentPage={updateCurrentPage} handleDeleteAdminClick={handleDeleteAdminClick} />}
      {showInviteModal && <InviteAdminModal onHide={closeModal} />}
      {showAdminDeleteModal && <DeleteModal disabled={isLoading} userDelete={true} onDeleteClick={deleteAdmin} onHide={closeModal} />}
      {showInviteDeleteModal && <DeleteModal disabled={isLoading} userDelete={true} onDeleteClick={deleteAdminInvite} onHide={closeModal} />}
    </Row>
  );
}

export default ListUsers;
