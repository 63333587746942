import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import CTA from "~/src/components/ctaBox";
import { PAC_EMAIL } from '~/src/constants/common';
import { UN_CONFIRMED } from '~/src/constants/subpage';
import { reverse } from '~/src/api/urls';
import { POST, GET, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import appConfig from "~/config";
import './unconfirmed.scss';

const CancelledSubscriptionContainer = (props) => {
  const { t, i18n } = useTranslation();
  const { handleAuthError, setInitialHeader, userData} = React.useContext(AuthContext);

  const [org, setSelectedOrg] = React.useState('');

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setSelectedOrg(result.payload[0]);
      }
    }
  };

  const getOrganization = () => {
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };

  const paymentHandler = async (error, result) => {
    if (error) {
      // TBD Handle errors
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        window.open(result.payload.url);
      }
    }
  };

  const manageSubscription = () => {
    const requestData = {
      organization_id: org.pk,
      success_url: `${appConfig.apiUrl}/dashboard/plus${reverse('app:organization_page')}`  
    }
    makeAPIcall(reverse('api:manage_subscription'), POST, paymentHandler, requestData);
  }



  React.useEffect(() => {
    window.setWindowTitle(t('launch_guide.subscription_status'));
    setInitialHeader({ mainHeader: UN_CONFIRMED, subHeader: null });
    getOrganization();
  }, []);

  return (
    <Row className="unconfirmed-page justify-content-center align-items-start">
      <Col xs={12} md={8} className="d-flex justify-content-center">
        <CTA>
          <h1 className="invited_title">{t('launch_guide.subscription_status')}</h1>
          <p className="invited_text">
            {t('launch_guide.subscription_desc')}
          </p>
          <div className="invite-button-atea">
            <p className="find-email-text">{t('launch_guide.choose_plan')}</p>
            <Button
              variant="primary"
              className="button-purple invite-button"
              onClick={manageSubscription}
            >
              {t('launch_guide.manage_subscription')}
            </Button>
            <div className="contact-area">
            <a className="contactus-link" href={`mailto:${PAC_EMAIL}`}>{t('common.contact_us_small')}</a>
              <span className="contactus-extra-text"> {t('auth.need_help')}</span>
            </div>
          </div>
        </CTA>
      </Col>
    </Row>
  );
};

export default CancelledSubscriptionContainer;
