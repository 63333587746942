// This is used to determine if a user is logged in and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Redirect, Route, Switch, Routes } from 'react-router-dom';
import { reverse } from '~/src/api/urls';
import AuthContext from '~/src/context/authContext';
import StepsContainer from '~/src/onboarding/steps';

const OnboardingContainers = (props) => {
  React.useEffect(() => {
  }, []);
  return (
    <Routes>
      <Route
        path={'step1'}
        component={StepsContainer}
      />
    </Routes>
  )
}

export default OnboardingContainers