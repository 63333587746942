import React from 'react';

import OrganizationContext from "~/src/context/organizationContext";
import MyOrganization from "./index";
import './style.scss';

const OrganizationContainer = props => (
    <OrganizationContext.Wrapper {...props}>
        <MyOrganization />
    </OrganizationContext.Wrapper>
);

export default OrganizationContainer;
