import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { reverse } from '~/src/api/urls';
import { POST, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from "~/src/context/organizationContext";
import AdminContext from '~/src/context/adminContext';
import ModalBox from "~/src/components/modal/index";
import Assets from '~/src/constants/assets';

import '~/src/components/modal/style.scss';

function InviteAdminModal(props) {
  const { t, i18n } = useTranslation();

  const [inProgress, setInProgress] = React.useState(false);
  const { onHide } = props;
  const { handleAuthError, setToastMsg } = React.useContext(AuthContext);
  const { selectedOrganization } = React.useContext(OrganizationContext);
  const { resetAdminData } = React.useContext(AdminContext);
  const existingAdmins = React.useRef([]);
  const existingInvites = React.useRef([]);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("common.enter_valid_email")).required(t("common.required_email"))
    }),
    validate: (values) => {
      let errors = {};
      if (existingAdmins.current.includes(values.email)) {
        errors.email = t("admin.admin_exists")
      }
      if (existingInvites.current.includes(values.email)) {
        errors.email = t("admin.invite_exists")
      }
      return errors;
    },
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      const requestData = {
        email: values.email,
        organization_id: selectedOrganization.pk
      };
      makeAPIcall(reverse('api:admin_invite_create'), POST, (error, result) => {
        addAdminInviteResultHandler(error, result, values, setFieldError);
      }, requestData);
    }
  });

  const addAdminInviteResultHandler = async (error, result, values, setFieldError) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }
      const hasJsonErrorCode = Boolean(result.responseJson && Array.isArray(result.responseJson.errors) && result.responseJson.errors.length);
      if ( result.response.status == 400 && hasJsonErrorCode ) {
        if (result.responseJson.errors[0]["code"] == 'member_already_exists') {
          setFieldError('email', t("admin.admin_exists"));
          existingAdmins.current.push(values.email);
          return;
        }
        if (result.responseJson.errors[0]["code"] == 'invite_already_exists') {
          setFieldError('email', t("admin.invite_exists"));
          existingInvites.current.push(values.email);
          return;
        }
      }
    } else {
      setToastMsg(t("admin.admin_added"), false);
      resetAdminData();
      onHide();
    }
  };

  return (
    <ModalBox onHide={onHide} showicon={true} messageIcon={true}>
      <React.Fragment>
        <h4 className="modal-title">{t("admin.invite_an_admin")}</h4>

        <p className="describe-text-1">
          {t("admin.invite_an_admin_description")}
        </p>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Row className="admin-email-input">
            <Form.Control
              name="email"
              disabled={inProgress}
              placeholder={t("admin.invite_placeholder")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={formik.touched.email && formik.errors.email && "highlight-input"}
            />
            { formik.touched.email && formik.errors.email ?
              (
                <p className="form-error-text">{formik.errors.email}</p>
              ) : null
            }
          </Form.Row>
          <Form.Row style={{ textAlign: "center" }}>
            <Col sm={12}>
              { formik.submitCount > 0 && !formik.isValid ?
                <span className="form-final-error-text">
                  <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                  {t("common.correct_errors_above")}
                </span>
                : null
              }
            </Col>
            <Col sm={12}>
              <Button
                size="lg"
                disabled={inProgress}
                style={{ marginTop: "20px" }}
                className="button-purple"
                type="submit"
              >
                {t("admin.invite_admin")}
                {
                  inProgress &&
                  <Spinner animation="border" role="status">
                    <span className="sr-only">{t("dashboard.loading")}</span>
                  </Spinner>
                }
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </React.Fragment>
    </ModalBox>
  );
}

export default InviteAdminModal;

InviteAdminModal.defaultProps = {
  onInvite: () => {
    console.log("invite admin api function clicked");
  },
};
