import { reverse } from '~/src/api/urls';

export const LANDING = 'landing';
export const EDIT = 'edit';
export const ADD = 'add';
export const CHANGE_PASSWORD = 'changePassword';
export const LOGOUT = 'logout';
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const UN_CONFIRMED = 'unconfirmed';
export const UN_INVITED = 'uninvited';

export const GROUPS_LANDING = 'group_landing';
export const ADMIN_LANDING = 'admin_landing';
export const TEAMS_LANDING = 'teams_landing';

export const PROFILE_LANDING_PAGE = 'profile'
export const ORGANIZATION_LANDING_PAGE = 'organization'

export const PROFILE_VIEW = 'view'
export const ORGANIZATION_VIEW = 'view'

export const GROUPS_LANDING_PAGE = 'groups'
export const TEAMS_LANDING_PAGE = 'teams'
export const LAUNCH_GUIDE_LANDING_PAGE = 'launch-guide'
export const ADMIN_LANDING_PAGE = 'admin'

export const DASHBOARD_LANDING_PAGE = 'dashboard'
export const RESOURCE_LANDING_PAGE = 'resource'
export const CHALLENGE_REPORT_LANDING_PAGE = 'challenge-report'

export const profile_link_array = [
    {
        name: 'common.manage_profile',
        subHeader: PROFILE_VIEW,
        mainHeader: PROFILE_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:profile_page'),
        isLink: false
    },
    {
        name: 'common.change_my_password',
        subHeader: 'change_password',
        mainHeader: PROFILE_LANDING_PAGE,
        subpage: CHANGE_PASSWORD,
        link: reverse('app:profile_page'),
        isLink: false
     },
    {
        name: 'common.logout',
        subHeader: 'logout',
        mainHeader: PROFILE_LANDING_PAGE,
        subpage: LOGOUT,
        // link: reverse('app:logout_page'),
        isLink: false,
    }
];

export const manage_org_array = [
    {
        name: 'common.manage_my_org',
        subHeader: PROFILE_VIEW,
        mainHeader: ORGANIZATION_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:organization_page'),
        isLink: true
     },
    {
        name: 'common.manage_groups',
        subHeader: GROUPS_LANDING_PAGE,
        mainHeader: ORGANIZATION_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:group_page'),
        isLink: false
    },
    {
        name: 'common.manage_admin',
        subHeader: ADMIN_LANDING_PAGE,
        mainHeader: ORGANIZATION_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:admin_page'),
        isLink: false
     },
    {
        name: 'common.view_team',
        subHeader: TEAMS_LANDING_PAGE,
        mainHeader: ORGANIZATION_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:team_page'),
        isLink: false
     },
     {
        name: 'common.launch_guide',
        subHeader: LAUNCH_GUIDE_LANDING_PAGE,
        mainHeader: ORGANIZATION_LANDING_PAGE,
        subpage: LANDING,
        link: reverse('app:launch_guide_page'),
        isLink: false
     },
     {
        name: 'common.manage_subs',
        isExternalLink: true
     }
];


