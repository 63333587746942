import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { pushToPageLayer, pushEventToDataLayer } from '~/src/utils/GA';

import { PAC_EMAIL, LEGAL_FR, LEGAL_EN, CONTACT_US_INFO } from '~/src/constants/common';
import appConfig from '~/config';

import './style.scss';

const LanguageMapping = {
  fr: 'FR',
  en: 'EN',
};

function Footer(props) {
  const { changeLanguage, appUserId } = props;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const newLanguage = i18n.language === 'en' ? 'fr' : 'en';

  return (
    <Container fluid className="footer-container">
      <Row>
        <Col xs={12} sm={8} className="trademark-text">
          ({appConfig.displayVersion})
          &nbsp;
          {t('common.register_trademark')}
        </Col>
        <Col xs={12} sm={4} className="footer-links-area">
          <a
            className="footer-links"
            href={newLanguage === 'en' ? LEGAL_FR : LEGAL_EN}
          >
            {t('common.legal')}
          </a>
          <a
            className="footer-links"
            href={CONTACT_US_INFO[`FOOTER_${i18n.language}`]}
            onClick={() => pushEventToDataLayer('Footer', 'Contact Us clicked', 'Contact Us', '1', appUserId)}
          >
            {t('common.contact_us')}
          </a>
          <Link
            to="#"
            onClick={() => changeLanguage(newLanguage)}
            className="footer-links fr-lang-text"
          >
            {LanguageMapping[newLanguage]}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
