import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { reverse } from '~/src/api/urls';
import { RESET_PASSWORD } from "~/src/constants/subpage";
import { sendForgotPasswordEmail, getProviderFromEmail } from "~/src/utils/auth";
import CTA from "~/src/components/ctaBox";
import Assets from '~/src/constants/assets';
import ButtonWithProgress from '~/src/components/buttonWithProgress';

const ForgotPasswordPage = props => {
  const { t, i18n } = useTranslation();
  /**
   * Store previous error messages for emails.
   * Use a function to store as we would have to translate or do some changes on the messages.
   * Example: previousErrorMessages.current[values.email] = () => t('auth.use_social_login').replace('%s', emailProvider)
   */
  const previousErrorMessages = React.useRef({});
  const [genericError, setGenericError] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('common.enter_valid_email')).required(t('common.required_email'))
    }),
    validate: (values) => {
      let errors = {};
      try {
        if (previousErrorMessages.current[values.email.toLowerCase()]) {
          errors.email = previousErrorMessages.current[values.email.toLowerCase()]();
        }
      } catch (ex) { /* ignored */ }
      return errors;
    },
    onSubmit: async (values, { setErrors, setFieldError }) => {
      setInProgress(true);
      setErrors({})

      /* try to see if we get social account login else reset the password */
      try {
        values.email = values.email.toLowerCase();

        const emailProvider = await getProviderFromEmail(values.email);
        if (emailProvider) {
          setInProgress(false);
          previousErrorMessages.current[values.email] = () => t('auth.use_social_login').replace('%s', emailProvider);
          setFieldError('email', t('auth.use_social_login').replace('%s', emailProvider));
          return;
        }
      } catch (ex) {
        /* errors are ignored */
      }

      try {
        await sendForgotPasswordEmail(values.email);
        props.setEmail(values.email);
        props.setCurrentPage(RESET_PASSWORD);
      } catch (ex) {
        if (ex.code === 'UserNotFoundException') {
          previousErrorMessages.current[values.email] = () => t('common.user_does_not_exist');
          setFieldError('email', t('common.user_does_not_exist'));
        } else if (ex.code === 'LimitExceededException') {
          setGenericError(t("common.limit_exceed"));
        } else {
          setFieldError('form', t('common.generic_error_message'));
        }
      }
      setInProgress(false);
    }
  });

  const getGenericFormError = () => {
    if (genericError) {
      return (
        <span className="form-final-error-text">
          <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
          {genericError}
        </span>
      );
    } else if (!formik.errors.form && formik.submitCount > 0 && !formik.isValid) {
      return (
        <span className="form-final-error-text">
          <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
          {t('common.correct_errors_above')}
        </span>
      );
    } else if (formik.errors.form) {
      return (<p className="form-error-text">{formik.errors.form}</p>)
    }
    return null;
  };

  return (
    <CTA
      miniPadding
      text={t('auth.back_login')}
      link={reverse('app:home_page')}
      className="forgot-password-page"
    >
      <div className="forgot-password-form-container">
        <Form id="forgotPasswordForm" name="forgotPassword" onSubmit={formik.handleSubmit}>
          <p className="forgot-pasw-form-title">{t('auth.forgot_password')}</p>
          <p className="form-describe">
            {t("auth.forgot_password_description")}
          </p>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className="input-label">{t('auth.email')}</Form.Label>
            <Form.Control
              name="email"
              autoComplete="email"
              disabled={inProgress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={formik.touched.email && formik.errors.email && 'highlight-input'}
            />
            { formik.touched.email && formik.errors.email ?
              (
                <p className="form-error-text">{formik.errors.email}</p>
              ) : null
            }
          </Form.Group>
          <Form.Row>
            <Col sm={12}>
              { getGenericFormError() }
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={12}>
              <ButtonWithProgress
                inProgress={inProgress}
                text={t('auth.send_request')}
              />

              <Button
                disabled={inProgress}
                className="have-verification-code-link"
                variant="link"
                onClick={() => {props.setCurrentPage(RESET_PASSWORD);}}
              >
                {t('auth.i_have_verification_code')}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </CTA>
  );

}

export default ForgotPasswordPage;
