import React  from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
} from 'react-bootstrap';
import Select from 'react-select'

import ToolTip from '~/src/components/Tooltip';
import Constants from '~/src/constants/challengeReport';

import Utils from './utils';
import './style.scss';


const BreakdownTable = React.memo(({
  title,
  helpText,
  type,
  data,
  challengeData,
  dropDownOption,
  selectedGroup,
  setSelectedGroup,
  orgTotal,
  sortObj,
  setSort,
  challengeName,
  isChallengeNotStarted
}) => {
  const { t, i18n } = useTranslation();
  
  React.useEffect(() => {
    dropDownOption && setSelectedGroup(dropDownOption.find(d => d.value === selectedGroup.value));
  }, [i18n.language])

  const teamTotals = {
    member_count: 0,
    move_minutes: 0,
    active_minutes: 0,
    steps: 0,
    prize_entries: 0
  };

  const getChallengeRowData = (challengeName, data) => {
    let dataToFill = 0;
    switch(challengeName) {
      case "APR_2022":
        dataToFill = data.steps;
        break;
      case "APR_2023":
        dataToFill = data.steps;
        break;
      case "AUG_2022":
        dataToFill = data.active_minutes;
        break;
      default:
        dataToFill = data.move_minutes;
        break;
    }
    return dataToFill;
  }

  const getChallengeNameForColumnHeader = (challengeName) => {
    let challengeCopy = "";
    switch(challengeName) {
      case "APR_2022":
        challengeCopy = t('challenge_report.steps');
        break;
      case "APR_2023":
        challengeCopy = t('challenge_report.steps');
        break;
      case "AUG_2022":
        challengeCopy = t('challenge_report.active_minutes');
        break;
      case "default_page":
        challengeCopy = t('challenge_report.move_minutes');
        break;
      case "GBM_2022":
        challengeCopy = t('challenge_report.move_minutes');
        break;
      default:
        challengeCopy = t('challenge_report.move_min');
        break;
    }
    return challengeCopy;
  }

  const getChallengeSortType = (challengeName) => {
    let challengeSort = "";
    switch(challengeName) {
      case "APR_2022":
        challengeSort = Constants.PERFORMANCE_TABLE_KEY.STEPS;
        break;
      case "APR_2023":
        challengeSort = Constants.PERFORMANCE_TABLE_KEY.STEPS;
        break;
      case "AUG_2022":
        challengeSort = Constants.PERFORMANCE_TABLE_KEY.ACTIVE_MINUTES;
        break;
      default:
        challengeSort = Constants.PERFORMANCE_TABLE_KEY.MOVE_MINUTES;
        break;
    }
    return challengeSort;
  }

  const renderPrizeValue = (item, type) => {
    let val = 0;

    switch (challengeName) {
      case "CBC_2021":
        val = item.prize_entries;
        break;
      case "AUG_2021":
        val = item.prize_entries;
        break;
      case "GBM_2021":
        if (type == "team-performance") {
          const milestoneEarned = item.milestone_earned || 0;
          val =
            milestoneEarned >= challengeData.total_milestones_exclude_bonus
              ? challengeData.total_milestones_exclude_bonus
              : milestoneEarned;
        } else {
          item.milestone_earned &&
            item.milestone_earned.map((data) => {
              val +=
                data >= challengeData.total_milestones_exclude_bonus
                  ? challengeData.total_milestones_exclude_bonus
                  : data;
            });
        }
        break;
      case "DEC_2021":
        if (type == "team-performance") {
          const milestoneEarned = item.milestone_earned || 0;
          val =
            milestoneEarned >= challengeData.total_milestones
              ? challengeData.total_milestones
              : milestoneEarned;
        } else {
          item.milestone_earned &&
            item.milestone_earned.map((data) => {
              val +=
                data >= challengeData.total_milestones
                  ? challengeData.total_milestones
                  : data;
            });
        }
        break;
      case "TRUENORTH_2022":
        if (type == "team-performance") {
          const milestoneEarned = item.milestone_earned || 0;
          val =
            milestoneEarned >= challengeData.total_milestones
              ? challengeData.total_milestones
              : milestoneEarned;
        } else {
          item.milestone_earned &&
            item.milestone_earned.map((data) => {
              val +=
                data >= challengeData.total_milestones
                  ? challengeData.total_milestones
                  : data;
            });
        }
        break;
      case "APR_2022":
        val = item.prize_entries;
        break;
      case "APR_2023":
        val = item.prize_entries;
        break;
      case "CBC_2022":
        val = item.prize_entries;
        break;
      case "CBC_2023":
        val = item.prize_entries;
        break;
      case "AUG_2022":
        val = item.prize_entries;
        break;
      case "GBM_2022":
        if (type == "team-performance") {
          const milestoneEarned = item.milestone_earned || 0;
          val = milestoneEarned
        } else {
          item.milestone_earned &&
            item.milestone_earned.map((data) => {
              val += data
            });
        }
        break;
      case "MFYM_2023":
        val = item.prize_entries;
        break;
      case "default_page":
        val = 0;
        break;
      default:
        break;
    }
    return val;
  }

  const renderPrizeHeader = () => {
    switch (challengeName) {
      case "CBC_2021":
        return t("challenge_report.entries_earned");
        break;
      case "AUG_2021":
        return t("challenge_report.entries_earned");
        break;
      case "GBM_2021":
        return t("challenge_report.milestones_earned");
        break;
      case "DEC_2021":
        return t("challenge_report.milestones_earned");
        break;
      case "TRUENORTH_2022":
        return t("challenge_report.milestones_earned");
        break;
      case "APR_2022":
        return t("challenge_report.entries_earned");
        break;
      case "APR_2023":
        return t("challenge_report.entries_earned");
        break;
      case "CBC_2022":
        return t("challenge_report.levels_achieved");
        break;
      case "CBC_2023":
        return t("challenge_report.levels_achieved");
        break;
      case "AUG_2022":
        return t("challenge_report.levels_achieved");
        break;
      case "GBM_2022":
        return t("challenge_report.levels_achieved");
        break;
      case "MFYM_2023":
        return t("challenge_report.levels_achieved");
        break;
      case "default_page":
        return t("challenge_report.levels_achieved");
        break;
      default:
        return "";
        break;
    }
  }

  const renderPrizeOrgTotal = (orgtotal) => {
    switch(challengeName) {
      case 'CBC_2021':
        return orgTotal.total_entries 
        break;
      case 'AUG_2021':
        return orgTotal.total_entries 
        break;
      case 'GBM_2021':
        let total = 0;
        data && data.map((item) => {
          item.milestone_earned && item.milestone_earned.map((data) => {
            total += (data >= challengeData.total_milestones_exclude_bonus ? challengeData.total_milestones_exclude_bonus : data)
          })
        });
        return total;
        break;
      case 'DEC_2021':
        let total = 0;
        data && data.map((item) => {
          item.milestone_earned && item.milestone_earned.map((data) => {
            total += (data >= challengeData.total_milestones ? challengeData.total_milestones : data)
          })
        });
        return total;
        break;
      case 'TRUENORTH_2022':
        let total = 0;
        data && data.map((item) => {
          item.milestone_earned && item.milestone_earned.map((data) => {
            total += (data >= challengeData.total_milestones ? challengeData.total_milestones : data)
          })
        });
        return total;
        break;
      case 'APR_2022':
        return orgTotal.total_entries 
        break;
      case 'APR_2023':
        return orgTotal.total_entries 
        break;
      case 'CBC_2022':
        return orgTotal.total_entries 
        break;
      case 'CBC_2023':
        return orgTotal.total_entries 
        break;
      case 'AUG_2022':
        return orgTotal.total_entries 
        break;
      case 'GBM_2022':
        let total = 0;
        data && data.map((item) => {
          item.milestone_earned && item.milestone_earned.map((data) => {
            // total += (data >= challengeData.total_milestones_exclude_bonus ? challengeData.total_milestones_exclude_bonus : data)
            total += data
          })
        });
        return total;
        break;
      case 'MFYM_2023':
        return orgTotal.total_entries;
        break;
      case 'default_page':
        return 0
        break;
      default: 
        return orgTotal.total_entries;
        break;
    }
    return val;
  }


  if (type === 'team-performance') {
    if(!isChallengeNotStarted) {
      data && data.map((item) => {
        if (challengeName == 'GBM_2021') {
          const milestoneEarned = item.milestone_earned || 0;
          teamTotals.prize_entries += (milestoneEarned >= challengeData.total_milestones_exclude_bonus ? challengeData.total_milestones_exclude_bonus : milestoneEarned);
        } else if(challengeName == 'GBM_2022') {
          const milestoneEarned = item.milestone_earned || 0;
          teamTotals.prize_entries += milestoneEarned;
        } else if (challengeName == 'DEC_2021') {
          const milestoneEarned = item.milestone_earned || 0;
          teamTotals.prize_entries += (milestoneEarned >= challengeData.total_milestones ? challengeData.total_milestones : milestoneEarned);
        } else if (challengeName == 'TRUENORTH_2022') {
          const milestoneEarned = item.milestone_earned || 0;
          teamTotals.prize_entries += (milestoneEarned >= challengeData.total_milestones ? challengeData.total_milestones : milestoneEarned);
        } else {
           teamTotals.prize_entries += item.prize_entries;
        }
        teamTotals.member_count += item.member_count;
        teamTotals.move_minutes += item.move_minutes;
        teamTotals.active_minutes += item.active_minutes;
        teamTotals.steps += item.steps;
      });
    }
  }

  return (
    <div className="performance-breakdown">
      <div className="title-bar">
        <div className={`title ${dropDownOption ? 'dropdown' : 'no-dropdown'}`}>
          {title}
          {helpText && <ToolTip
            className="info"
            text={helpText}
          />}
        </div>
        {dropDownOption &&
          <div className="dropdown-div">
            <Select
              className="group-selector"
              options={dropDownOption}
              value={selectedGroup}
              onChange={setSelectedGroup}
              maxMenuHeight={170}
            />
          </div>
        }
      </div>
      <Table hover>
        <thead>
          <tr className="dark">
            <th className="col-title">
              {type === 'team-performance' ? t('challenge_report.team') : t('challenge_report.group')} ({data.length})
            </th>
            <th>
              {Utils.getSortColHeaderButton(
                type === 'team-performance' ? t('challenge_report.team_member') : t('challenge_report.member'),
                Constants.PERFORMANCE_TABLE_KEY.MEMBERS,
                sortObj.sortCol,
                sortObj.sortMode,
                setSort
              )}
            </th>
            <th>
              {Utils.getSortColHeaderButton(getChallengeNameForColumnHeader(challengeName),
                getChallengeSortType(challengeName),
                sortObj.sortCol,
                sortObj.sortMode,
                setSort
              )}
            </th>
            <th>
              {Utils.getSortColHeaderButton(
                renderPrizeHeader(),
                Constants.PERFORMANCE_TABLE_KEY.ENTRIES,
                sortObj.sortCol,
                sortObj.sortMode,
                setSort
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {type === 'team-performance' ?
            <tr className="org-total">
              <td className="row-title">{t('challenge_report.team_total')}</td>
              <td>{teamTotals.member_count}</td>
              <td>{isChallengeNotStarted ? 0 : orgTotal.total_score}</td>
              <td>{teamTotals.prize_entries}</td>
            </tr>
            :
            <tr className="org-total">
              <td className="row-title">{t('challenge_report.org_total')}</td>
              <td>{isChallengeNotStarted ? 0 : orgTotal.total_members}</td>
              <td>{isChallengeNotStarted ? 0 : orgTotal.total_score}</td>
              <td>{renderPrizeOrgTotal(orgTotal)}</td>
            </tr>
          }
          {data && data.map((item, index) => {
            return(
              <tr key={index}>
                <td className="row-title">
                  {type === 'team-performance' ? item.team_name : item.division_name}
                </td>
                <td>{item.member_count}</td>
                <td>{getChallengeRowData(challengeName, item)}</td>
                <td>{renderPrizeValue(item, type)}</td>
            </tr>)
          })
          }
        </tbody>
      </Table>
      {type === 'team-performance' && !data.length &&
        <div className="no-rows">{t('challenge_report.team_not_available1')}{dropDownOption.length == 2 ? dropDownOption[1].label : selectedGroup.label} {t('challenge_report.team_not_available2')}</div>
      }
    </div>
  );
});

BreakdownTable.defaultProps = {

};

export default BreakdownTable;
