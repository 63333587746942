import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import AboutChallenge from '../components/aboutChallenge';
import ParticipationCard from '../components/participationCard';
import CounterCard from '../components/counterCard';

import './style.scss';

const CBCTopSection = (props) => {
  const { t, i18n } = useTranslation();
  const { currentCard, currentReport } = props;

  return (
    <Row className={'top-section'}>
      <Col xs={12} sm={6} lg={3} className="top-card about-this-challenge">
        <AboutChallenge
          currentCard={currentCard}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card counter-card-container">
        <CounterCard
          icon={'runnerMove'}
          count={(currentReport.activity_data && Math.abs(Math.round(currentReport.activity_data.total_move_minutes))) || 0}
          title={t('challenge_report.total_activity')}
          countTitle={t('challenge_report.move_minutes')}
          helpText={t('challenge_report.total_activity_info')}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className={`top-card counter-card-container avg-activity ${i18n.language === 'fr' && 'french'}`}>
        <CounterCard
          icon={'runnerSingle'}
          count={(currentReport.activity_data && Math.abs(Math.round(currentReport.activity_data.avg_move_minutes))) || 0}
          title={t('challenge_report.avg_per_member')}
          countTitle={t('challenge_report.move_minutes_per_day')}
          helpText={t('challenge_report.avg_info')}
          hasNationalAverage={true}
          nationalAverageValue={(currentReport.national_data && Math.abs(Math.round(currentReport.national_data.avg_move_minutes))) || 0}
        />
      </Col>
      <Col xs={12} sm={6} lg={3} className="top-card participation-card-container">
          <div className="team">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerMultiple'}
              text={'challenge_report.participated_team'}
              helpText={'challenge_report.team_info'}
              count={(currentReport.engagement_data && currentReport.engagement_data.participating_teams) || 0}
            />
          </div>
          <div className="member">
            <ParticipationCard
              cardView={'normal'}
              icon={'runnerSingle'}
              text={'challenge_report.participated_member'}
              helpText={'challenge_report.member_info'}
              count={(currentReport.engagement_data && currentReport.engagement_data.participating_individuals) || 0}
            />
          </div>
      </Col>
    </Row>
  );
};

export default CBCTopSection;
