export default {
  apiUrl: window.location.origin, // "https://paction-plus-qa.galepartners.com"

  userPoolId: window.cognito_pool_id, //60hqua6tcml36go4269ep77mmf

  clientId: window.cognito_client_id, //'ca-central-1_MVQW067Ta'

  gtmId: window.gtm_id,

  region: window.cognito_region_name, //'ca-central-1'

  cognitoHostedUIDomain: window.cognito_hostedui_domain, // 'participaction-qa.auth.ca-central-1.amazoncognito.com',

  displayVersion: '2.5.7',
}
	
/* Remove it from window as it is easy to access from dev console */
delete window.cognito_pool_id;
delete window.cognito_client_id;
delete window.cognito_region_name;
delete window.cognito_hostedui_domain;
