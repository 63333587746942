import React from "react";
import { Button } from 'react-bootstrap';

import Assets from '~/src/constants/assets';

import './style.scss';

function SocialLoginButton(props) {
  if (!props.text) return null;
  const { text, imagePath, onClick ,style} = props;

  return (
    <Button
      variant="secondary"
      onClick={onClick}
      className="button-social"
    >
      <img
        src={imagePath || Assets.LogoPic1}
        alt="social-image"
        style={style}
        className="social-image"
      />
      <div className="social-button-text">{text}</div>
    </Button>
  );
}


SocialLoginButton.defaultProps = {
};

export default SocialLoginButton;
