import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import authentication_en from './locale/en/authentication';
import authentication_fr from './locale/fr/authentication';

import common_en from './locale/en/common';
import common_fr from './locale/fr/common';

import admin_en from './locale/en/admin';
import admin_fr from './locale/fr/admin';

import team_en from './locale/en/teams';
import team_fr from './locale/fr/teams';

import dashboard_en from './locale/en/dashboard';
import dashboard_fr from './locale/fr/dashboard';

import groups_en from './locale/en/groups';
import groups_fr from './locale/fr/groups';

import organization_en from './locale/en/organization';
import organization_fr from './locale/fr/organization';

import resource_en from './locale/en/resource';
import resource_fr from './locale/fr/resource';

import challenge_report_en from './locale/en/challenge_report';
import challenge_report_fr from './locale/fr/challenge_report';

import launch_guide_en from './locale/en/launch_guide';
import launch_guide_fr from './locale/fr/launch_guide';


const resources = {
  en: {
    translation: {
      auth: authentication_en,
      common: common_en,
      admin: admin_en,
      dashboard: dashboard_en,
      groups: groups_en,
      organization: organization_en,
      resource: resource_en,
      team: team_en,
      challenge_report: challenge_report_en,
      launch_guide: launch_guide_en
    }
  },
  fr: {
    translation: {
      auth: authentication_fr,
      common: common_fr,
      admin: admin_fr,
      dashboard: dashboard_fr,
      groups: groups_fr,
      organization: organization_fr,
      resource: resource_fr,
      team: team_fr,
      challenge_report: challenge_report_fr,
      launch_guide: launch_guide_fr
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: localStorage.getItem('language', 'en'),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;