import React from 'react';

import Constants from '~/src/constants/challengeReport';
import { useTranslation } from 'react-i18next';
import { reverse } from "~/src/api/urls";
import { GET, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from '~/src/context/organizationContext';
import Utils from '~/src/challengeReport/utils';

const ChallengeReportContext = React.createContext({});


const ChallengeReportContextWrapper = (props) => {
  const { t, i18n } = useTranslation();
  const { handleAuthError  } = React.useContext(AuthContext);
  const { selectedOrganization }  = React.useContext(OrganizationContext)
  const [ carouselList, setCarouselList ] = React.useState([]);
  const [ reportsObject, setReportsObject ] = React.useState({});
  const [ apiComplete, setApiComplete ] = React.useState(false);
  const [ currentCarousel, setCurrentCarousel ] = React.useState('');
  const [ newChallenge, setNewChallenge ] = React.useState('');
  const [ selectedGroup, setSelectedGroup ] = React.useState({ value: 'default', label: t('challenge_report.select_group')});
  const [ teamSort, setTeamSort ] = React.useState({ sortCol: Constants.PERFORMANCE_TABLE_KEY.MEMBERS, sortMode: 'desc'})
  const [ stepSort, setStepSort ] = React.useState({ sortCol: Constants.PERFORMANCE_TABLE_KEY.STEPS, sortMode: 'desc'})
  const [ moveMinsSort, setMoveMinsSort ] = React.useState({ sortCol: Constants.PERFORMANCE_TABLE_KEY.MOVE_MINUTES, sortMode: 'desc'})
  const [ activeMinsSort, setActiveMinsSort ] = React.useState({ sortCol: Constants.PERFORMANCE_TABLE_KEY.ACTIVE_MINUTES, sortMode: 'desc'})
  const [ groupSort, setGroupSort] = React.useState({ sortCol: Constants.PERFORMANCE_TABLE_KEY.MEMBERS, sortMode: 'desc'})
  

  const getNewChallenge = (data) => {
    const currentDate = new Date();
    let currentChallenge = {};
    let challengeArray = data;
    const finalResults = challengeArray.filter((data) => {
      const endDate = new Date(data.end_at);
      if(currentDate >= endDate) {
        return data.pk;   
      }
    });
    const lastCompletedChallenge = finalResults[finalResults.length - 1];
    data.forEach(item => {
      const available_at = new Date(item['available_at']);
      const launch_at = new Date(item['launch_at']);
      const end_at = new Date(item['end_at']);
      if((currentDate >= available_at && currentDate <= launch_at) || (currentDate >= launch_at && currentDate <= end_at)){
        currentChallenge = item;
      }else if(item.pk === lastCompletedChallenge.pk) {
        currentChallenge = item;
      }
    });
    return currentChallenge;
  }

  const challengeListHandler = (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        const carouselList = Utils.sortandFilterCarouselList(result.payload);
        setCarouselList(carouselList);
        const reportObjects = {};
        let currentCard = '';
        newChallenge = getNewChallenge(carouselList)
        setNewChallenge(newChallenge)
        carouselList && carouselList.map((data) => {
        let selectedCarousel = '';
          makeAPIcall(
            reverse('api:report_detail', data.pk, selectedOrganization.pk),
            GET,
            (error, result) => {
              if (error) {
                if (isAuthRelatedError(result)) {
                  handleAuthError(result);
                }
              } else {
                if (result.payload && Object.keys(result.payload).length) {
                  reportObjects[data.pk] = result.payload;
                  currentCard = data.pk;
                } else {
                  reportObjects[data.pk] = {};
                }
                setCurrentCarousel(currentCard);
                if (Object.keys(reportObjects).length === carouselList.length) {
                  // required to update and re-render
                  if(Object.keys(newChallenge).length !== 0 && currentCard !== newChallenge.pk) {
                    currentCard = newChallenge.pk;
                    setCurrentCarousel(currentCard);
                  }else{
                    setCurrentCarousel(currentCard);
                  }
                  setApiComplete(true);
                }
                setReportsObject(reportObjects);
              }
            }
          );
        });
      }
    }
  };

  const resetChallengeReport = () => {
    selectedOrganization && selectedOrganization.pk && makeAPIcall(reverse('api:report_list'), GET, challengeListHandler);
  };


  const challengeReportDetailCall = () => {
    makeAPIcall(
      reverse('api:report_detail', currentCarousel, selectedOrganization.pk),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          if (result.payload && Object.keys(result.payload).length) {
            reportsObject[currentCarousel] = result.payload;
          } else {
            reportsObject[currentCarousel] = {};
          }
          setReportsObject(reportsObject);
        }
      }
    );
  }

  React.useEffect(() => {
    currentCarousel && challengeReportDetailCall();
    /// 
  }, [currentCarousel]);

  React.useEffect(() => {
    resetChallengeReport();
  }, [selectedOrganization]);

  const finalValue = {
    carouselList,
    currentCarousel,
    loader: !carouselList || Object.keys(reportsObject).length !== carouselList.length,
    reportsObject,
    setCurrentCarousel,
    selectedGroup,
    setSelectedGroup,
    teamSort,
    groupSort,
    stepSort,
    moveMinsSort,
    activeMinsSort,
    setActiveMinsSort,
    setMoveMinsSort,
    setStepSort,
    setTeamSort,
    setGroupSort,
    newChallenge,
    setNewChallenge
  };

  return (
    <ChallengeReportContext.Provider
      key="challenge-report-context"
      value={finalValue}
    >
      {props.children}
    </ChallengeReportContext.Provider>
  );
}

ChallengeReportContext.Wrapper = ChallengeReportContextWrapper;
export default ChallengeReportContext;
