import React from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import {
    Spinner,
    Button
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Assets from '~/src/constants/assets';
import { reverse } from "~/src/api/urls";
import Constants from '~/src/constants/challengeReport';
import Utils from '~/src/challengeReport/utils';

import './style.scss';

const AboutChallenge = ({ currentCard }) => {
  const { t, i18n } = useTranslation();

  const dateFormat = (data) => {
    const create = new Date(data['launch_at']);
    return Utils.formatDate(create, 'MMMM yyyy');
  }

return (
    <div className='about-this-challenge-card'>
        <div className="title-text">{t('challenge_report.about_this_challenge_title')}</div>
        <div className="icon-container">
            <div className="first-section title">
              <img
                className="card-icon"
                src={Constants.IMAGE_MAP[currentCard.name][i18n.language]}
              />
            </div>
            <div className="second-section">{Constants.ABOUT_CHALLENGE_DATE_MAP[currentCard.name] && t(Constants.ABOUT_CHALLENGE_DATE_MAP[currentCard.name].cardDates) || dateFormat(currentCard)}</div>
        </div>
        <div className="icon-container">
            {currentCard &&
                <div className="icon-row first-section">
                    <img
                        className="card-icon"
                        src={Constants.CHALLENGE_TYPE[currentCard.challenge_type].image}
                    />
                </div>
            }
            {currentCard &&
                <div className="icon-text second-section">{t(Constants.CHALLENGE_TYPE[currentCard.challenge_type].title)}</div>
            }
        </div>
        <div className='view-resource-div'>
            <Button variant="link" className="view-resource">
                <Link to={reverse("app:resources")}>{t("challenge_report.view_resource")}</Link>
            </Button>
        </div>
    </div>
  );
};
    
AboutChallenge.defaultProps = {
};

export default AboutChallenge;
