import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Button,
  Spinner,
} from 'react-bootstrap';
import Select from 'react-select'

/* print page imports starts */
import DomToImage from 'dom-to-image';
import jsPDF from 'jspdf';
/* print page imports ends */

import Constants from '~/src/constants/dashboard';
import CounterCard from '../components/counterCard';

import Assets from '~/src/constants/assets';

import Utils from './utils';
import Section from './section';

import './style.scss';

const AllTimeMetrics = React.memo(({
  title,
  data,
  meta,
  noData
}) => {
  const { t, i18n } = useTranslation();
  const [isPrinting, setIsPrinting] = React.useState(false);
  let allMetricsDate = '';
  if (data[meta[0].name] && data[meta[0].name].created_at) {
    allMetricsDate = Utils.formatDate(new Date(data[meta[0].name].created_at));
  }

  return (
    <Section
      title={t(title)}
      className={`all-time-metric-container ${noData && 'add-opacity'}`}
      subTitle={<><b>{t("dashboard.last_updated")} </b> {allMetricsDate}</>}
      actions={
        <Button
          disabled={isPrinting}
          variant="light"
          onClick={() => {
            setIsPrinting(true);

            const nodeToPrint = document.querySelector('#app > .page-container');
            DomToImage.toPng(
              nodeToPrint,
              {
                width: nodeToPrint.scrollWidth,
                height: nodeToPrint.scrollHeight,
                filter: (node) => {
                  if (node.classList && node.classList.contains('print-page-button')) {
                    return false;
                  }
                  return true;
                },
              }
            )
              .then(function (dataUrl) {
                const link = document.createElement('a');
                link.download = `participActionPlus-${t('dashboard.dashboard')}.png`;
                link.href = dataUrl;
                link.click();
                setIsPrinting(false);
              })
              .catch(function (error) {
                console.error(error);
                window.alert(t('dashboard.print_failed'));
                setIsPrinting(false);
              });
          }}
          className="grey-button-outline print-page-button"
        >
          <img src={Assets.PrinterIcon} /> {t('dashboard.print')}
          {
            isPrinting &&
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          }
        </Button>
      }
    >
      {
        meta.map(metric => (
          <Col xs={12} sm={6} lg={3} key={metric.title} className="counter-card-container">
            <CounterCard
              icon={metric.icon}
              count={metric.getCount(data[metric.name], data)}
              title={metric.title}
              countTitle={metric.countTitle}
              helpText={metric.helpText}
            />
          </Col>
        ))
      }
    </Section>
  );
});

AllTimeMetrics.defaultProps = {
  title: 'all_metric',
  meta: Constants.ALL_TIME_METRICS_META,
  data: {},
};

export default AllTimeMetrics;
