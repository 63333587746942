import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';
import TagManager from 'react-gtm-module'
import App from './src/app';
import appConfig from "./config";

const tagManagerArgs = { gtmId: appConfig.gtmId };

window.dataLayer = window.dataLayer || [];
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('app'));
