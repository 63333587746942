import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ProfileContext from "~/src/context/profileContext";
import AuthContext from "~/src/context/authContext";
import { reverse } from "~/src/api/urls";

import CTA from "~/src/components/ctaBox";
import MyProfileEdit from "./edit";
import ChangePassword from "./password_change";
import { EDIT, CHANGE_PASSWORD, LANDING, PROFILE_LANDING_PAGE, PROFILE_VIEW } from "~/src/constants/subpage";

const MyProfile = () => {
  const { t, i18n } = useTranslation();

  const { userData } = React.useContext(ProfileContext);
  const { currentPage, setInitialHeader, setCurrentPage } = React.useContext(AuthContext);

  React.useEffect(() => {
    // To set the header componenet
    window.setWindowTitle(t('auth.manage_my_profile'));
    setInitialHeader({ mainHeader: PROFILE_LANDING_PAGE, subHeader: PROFILE_VIEW });
  }, []);

  const updateCurrentPage = (page) => {
    if (page === LANDING) {
      setInitialHeader({ mainHeader: PROFILE_LANDING_PAGE, subHeader: PROFILE_VIEW });
    }
    setCurrentPage(page);
  };

  return (
    <Row className="profile-page-wrapper align-items-center justify-content-center">
      {currentPage === LANDING && (
        <Col xs={12} sm={8} md={6} lg={5} className="d-flex justify-content-center">
          <CTA miniPadding className="profile-page-container">
            <p className="profile-title">{t("auth.manage_my_profile")}</p>
            <p>
              {t("auth.update_email_1")}
              <span>
                <Link to={reverse('app:admin_page')} className="orange">
                  {t('auth.update_email_2')}
                </Link>
              </span>
              {t("auth.update_email_3")}
            </p>
            <p className="prfile-text">
              {t("auth.email_address")}: <strong>{userData.email}</strong>
            </p>
            <p className="prfile-text">
              {t("auth.first_name")}: {userData.first_name}
            </p>
            <p className="prfile-text">
              {t("auth.last_name")}: {userData.last_name}
            </p>
            <div className="profile-edit-button-area">
              <Button
                variant="primary"
                className="button-purple"
                onClick={() => updateCurrentPage(EDIT)}
              >
                {t("auth.edit_profile")}
              </Button>
            </div>
          </CTA>
        </Col>
      )}
      {currentPage === EDIT && <MyProfileEdit updateCurrentPage={updateCurrentPage}/>}
      {currentPage === CHANGE_PASSWORD && <ChangePassword updateCurrentPage={updateCurrentPage}/>}
    </Row>
  );
};

export default MyProfile;
