import React from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Col,
} from 'react-bootstrap';

import CTA from '~/src/components/ctaBox';
import { LANDING } from '~/src/constants/subpage';
import { reverse } from '~/src/api/urls';
import {
  signInViaUsernamePassword,
  getUserDataFromSession,
  getProviderFromEmail,
} from '~/src/utils/auth';
import Assets from '~/src/constants/assets';
import { POST, makeAPIcall } from '~/src/api/utils';
import AuthContext from '~/src/context/authContext';
import { isAuthRelatedError } from '~/src/api/errors';
import ButtonWithProgress from '~/src/components/buttonWithProgress';

const LoginPage = ({ setCurrentPage }) => {
  const { t, i18n } = useTranslation();

  const { handleAuthError, setAuthenticatedUser } = React.useContext(AuthContext);
  const [inProgress, setInProgress] = React.useState(false);

  const nonExistentEmails = React.useRef([]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('common.enter_valid_email')).required(t('common.email_required')),
      password: Yup.string().trim().required(t('common.required_password'))
    }),
    validate: (values) => {
      let errors = {};
      if (nonExistentEmails.current.includes(values.email.toLowerCase())) {
        errors.email = t('common.user_does_not_exist');
      }
      return errors;
    },
    onSubmit: async (values, { setErrors, setFieldError }) => {
      setInProgress(true);
      setErrors({});
      localStorage.setItem('loginHook', 'login');
      try {
        values.email = values.email.toLowerCase();
        const session = await signInViaUsernamePassword(values.email, values.password);
        setAuthenticatedUser(getUserDataFromSession(session));
        if (localStorage.getItem('loginHook') == 'login') {
          makeAPIcall(reverse('api:update_login'), POST, (error, result) => { if (!error) { localStorage.setItem('loginHook', '');} }, []);
        }
      } catch (ex) {
        console.error(ex);
        if (ex.code === 'UserNotFoundException') {
          const emailProvider = await getProviderFromEmail(values.email);
          if (emailProvider) {
            setFieldError('email', `${t('auth.account_already_exists')} ${t('auth.account_already_exists_desc')}`);
          } else {
            setFieldError('email', t('common.user_does_not_exist'));
          }
        } else if (ex.code === 'NotAuthorizedException') {
          setFieldError('email', t('common.email_password_mismatch'));
        } else {
          setFieldError('form', t('common.generic_error_message'));
        }
        setInProgress(false);
      }
    }
  });

  return (
    <CTA
      miniPadding
      text={t('auth.back_home')}
      onClick={() => setCurrentPage(LANDING)}
      className="email-login-form-container"
    >
      <Form id="loginForm" name="login" onSubmit={formik.handleSubmit}>
        <p className="email-login-form-title">{t('auth.log_in_with_email')}</p>
        <Form.Group controlId="emailInput">
          <Form.Label className="input-label">{t('auth.email')}</Form.Label>
          <Form.Control
            name="email"
            disabled={inProgress}
            autoComplete="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={formik.touched.email && formik.errors.email && 'highlight-input'}
          />
          {
            formik.touched.email && formik.errors.email &&
            (<p className="form-error-text">
              {formik.errors.email}
              {
                formik.errors.email === t('common.email_password_mismatch') &&
                <Link to={reverse('app:reset_password_page')} className="forget-password-button-text">
                  {t('common.reset_your_password')}
                </Link>
              }
            </p>)

          }
        </Form.Group>

        <div className="forgot-password-area">
          <Form.Label className="input-label">{t('auth.password')}</Form.Label>

          <Link to={reverse('app:reset_password_page')} className="forget-password-button-text">
            {t('auth.forgot_password_q')}
          </Link>
        </div>

        <Form.Group controlId="passwordInput">
          <Form.Control
            type="password"
            name="password"
            disabled={inProgress}
            autoComplete="current-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className={formik.touched.password && formik.errors.password && "highlight-input"}
          />
          { formik.touched.password && formik.errors.password ?
            (
              <p className="form-error-text">{formik.errors.password}</p>
            ) : null
          }
        </Form.Group>
        <Form.Row>
          <Col sm={12}>
            { !formik.errors.form && formik.submitCount > 0 && !formik.isValid ?
              <span className="form-final-error-text">
                <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                {t('common.correct_errors_above')}
              </span>
              : null
            }
            {
              formik.errors.form &&
                <p className="form-error-text">{formik.errors.form}</p>
            }
          </Col>
          <Col sm={12}>
            <ButtonWithProgress
              inProgress={inProgress}
              text={t('auth.login')}
            />
          </Col>
          <Col sm={12}></Col>
        </Form.Row>
      </Form>
    </CTA>
  );
};

export default LoginPage;
