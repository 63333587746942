import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalBox from "./index";
import './style.scss';

function AddedInvitedModal(props) {
  const { onHide, isAdded, organizationName } = props;
  const { t, i18n } = useTranslation();

  return (
    <ModalBox className="modal-container" onHide={onHide} showicon={isAdded} >
      <React.Fragment>
        <h4 className="modal-title">
          { isAdded ? t("auth.admin_added") : t("auth.admin_invited") }
        </h4>
        <p className="describe-text-1">
          { isAdded ? t("auth.added_org") : t("auth.accept_invitation") }
        </p>

        <p className="gale-text">
          {organizationName}
        </p>
        <div className="button-area" style={{ textAlign: "center" }}>
          <Button
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={onHide}
            className="button-outline "
          >
            {t("common.close")}
          </Button>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

export default AddedInvitedModal;
