import React from "react";
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalBox from "./index";
import './style.scss';

function InvalidTokenModal(props) {
  const { t, i18n } = useTranslation();
  const { onHide } = props;

  return (
    <ModalBox className="modal-container" onHide={onHide} showicon={true} exclamationicon={true} >
      <React.Fragment>
        <h4 className="modal-title">{t("common.invalid_invite")}</h4>
        <p className="describe-text-1">
          {t("common.invalid_desciption")}
        </p>

        <div className="button-area" style={{ textAlign: "center" }}>
          <Button
            size="lg"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            onClick={onHide}
            className="button-purple"
          >
            {t("auth.okay")}
          </Button>
        </div>
      </React.Fragment>
    </ModalBox>
  );
}

export default InvalidTokenModal;
