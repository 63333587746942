import React from 'react';
import { reverse } from '~/src/api/urls';
import { GET, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from '~/src/api/errors';
import AuthContext from '~/src/context/authContext';

const AdminContext = React.createContext({});

const AdminContextWrapper = (props) => {
  const { handleAuthError } = React.useContext(AuthContext);
  const [admins, setAdmins] = React.useState([]);
  const [adminInvites, setAdminInvites] = React.useState([]);
  const [selectedAdmin, setSelectedAdmin] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const adminListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setAdmins(result.payload);
    }
  };

  const adminInviteListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setAdminInvites(result.payload);
    }
    setIsLoading(false);
  };

  const resetAdminData = async () => {
    setIsLoading(true);
    setSelectedAdmin({});
    await makeAPIcall(reverse('api:admin_member_list'), GET, adminListHandler);
    await makeAPIcall(reverse('api:admin_invite_list'), GET, adminInviteListHandler);
  };

  React.useEffect(() => {
    resetAdminData();
  }, []);

  return (
    <AdminContext.Provider
      key="admin-context"
      value={{
        admins,
        adminInvites,
        selectedAdmin,
        setSelectedAdmin,
        resetAdminData,
        isLoading,
        setIsLoading
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
}

AdminContext.Wrapper = AdminContextWrapper;
export default AdminContext;
