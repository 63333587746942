import React from 'react';
import { reverse } from '~/src/api/urls';
import { GET, makeAPIcall } from '~/src/api/utils';
import { isAuthRelatedError } from '~/src/api/errors';
import AuthContext from '~/src/context/authContext';

const OrganizationContext = React.createContext({});

const OrganizationContextWrapper = (props) => {
  const { handleAuthError, setOrganization, userData  } = React.useContext(AuthContext);
  const [allOrganization, setAllOrganization] = React.useState([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState({});
  const [organizationList, setorganizationList] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setAllOrganization(result.payload);
        setSelectedOrganization(result.payload[0]);
        setOrganization(result.payload[0])
      }
      setIsLoading(false);
    }
  };

  const resetOrganization = () => {
    setIsLoading(true);
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };


  const organizationCheckList = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setorganizationList(result.payload);
      }
    }
  }

  if (selectedOrganization.pk && !Object.keys(organizationList).length) {
    makeAPIcall(reverse('api:organization_checklist_list', selectedOrganization.pk), GET, organizationCheckList);
  }

  React.useEffect(() => {
    resetOrganization();
  }, []);

  return (
    <OrganizationContext.Provider
      key="auth-context"
      value={{
        selectedOrganization,
        resetOrganization,
        organizationList,
        isLoading,
        setIsLoading
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
}

OrganizationContext.Wrapper = OrganizationContextWrapper;
export default OrganizationContext;
