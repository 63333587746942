/* fetch call helper functions */
import { SESSION_ERROR, UNCONFIRMED_USER_ERROR, APIError, handleErrors } from './errors';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';


const getFetchParams = (token, method, data={}, upload=false) => {
  // default params for fetch = method + (Content-Type)
  const paramsObj = {
    method,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    }
  };

  if (token) {
    paramsObj.headers.Authorization = `Bearer ${token}`;
  }

  if (method === GET) {
    // GET doesn't have data, so send as queryParams
    data = null;
  }

  if (data && upload) {
    paramsObj.body = data;
    delete paramsObj.headers['Content-type'];
  } else if (data) {
    data = JSON.stringify(data);
    paramsObj.body = data;
  }

  return paramsObj;
};


function objToQueryString(obj) {
  const keyValuePairs = [];
  for (let i = 0; i < Object.keys(obj).length; i += 1) {
    keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`);
  }
  return keyValuePairs.join('&');
}


export function makeAPIcall(url, method, cb, data={}, queryParams={}, isAuthOptional=false, download=false, name="", upload=false) {
  if (method === GET) {
    // GET doesn't have data, so send as queryParams
    queryParams = {...queryParams, ...data };
    data = {};
  }

  if (!(Object.keys(queryParams).length === 0 && queryParams.constructor === Object)) {
    const queryString = objToQueryString(queryParams);
    url = `${url}?${queryString}`;
  }

  let token = null;
  if (!isAuthOptional) {
    token = window._accessToken;
    if (!token) {
      const apiError = new APIError({response: {}, responseJson: {}});
      apiError.standardErrorCode = SESSION_ERROR;
      cb(true, apiError);
      return;
    }
  }

  return fetch(url, getFetchParams(token, method, data, upload))
  .then(response => handleErrors(response, download))
  .then(response => {
    cb(false, response, (download && name));
  })
  .catch(error => cb(true, error))
};
