import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LANDING } from "~/src/constants/subpage";
import { reverse } from '~/src/api/urls';
import { POST, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import ProfileContext from "~/src/context/profileContext";
import AdminContext from '~/src/context/adminContext';
import ToolTip from "../components/Tooltip";
import CTA from "~/src/components/ctaBox";

import Assets from '~/src/constants/assets';

const EditUser = (props) => {
  const { t, i18n } = useTranslation();

  const { handleDeleteAdminClick } = props;

  const [inProgress, setInProgress] = React.useState(false);
  const { handleAuthError, setToastMsg } = React.useContext(AuthContext);
  const { userData } = React.useContext(ProfileContext);
  const { selectedAdmin, resetAdminData } = React.useContext(AdminContext);
  const existingAdmins = React.useRef([]);
  const existingInvites = React.useRef([]);

  const formik = useFormik({
    initialValues: {
      email: selectedAdmin.preferred_email,
      subscriptionPreference: selectedAdmin.is_subscribed_for_communication ? 'subscribe' : 'unsubscribe'
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("common.enter_valid_email")).required(t("common.required_email"))
    }),
    validate: (values) => {
      let errors = {};
      if (existingAdmins.current.includes(values.email)) {
        errors.email = t("admin.admin_exists")
      }
      if (existingInvites.current.includes(values.email)) {
        errors.email = t("admin.invite_exists")
      }
      return errors;
    },
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      const requestData = {
        email: values.email,
        is_subscribed_for_communication: values.subscriptionPreference === 'subscribe'
      };
      makeAPIcall(reverse('api:admin_member_update', selectedAdmin.pk), POST, (error, result) => {
        updateAdminResultHandler(error, result, values, setFieldError);
      }, requestData);
    }
  });

  const updateAdminResultHandler = async (error, result, values, setFieldError) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }
      const hasJsonErrorCode = Boolean(result.responseJson && Array.isArray(result.responseJson.errors) && result.responseJson.errors.length);
      if ( result.response.status == 400 && hasJsonErrorCode ) {
        if (result.responseJson.errors[0]["code"] == 'member_already_exists') {
          setFieldError('email', t("admin.admin_exists"));
          existingAdmins.current.push(values.email);
          return;
        }
        if (result.responseJson.errors[0]["code"] == 'invite_already_exists') {
          setFieldError('email', t("admin.invite_exists"));
          existingInvites.current.push(values.email);
          return;
        }
      }
    } else {
      setToastMsg(t("admin.admin_saved"), false);
      resetAdminData();
      props.updateCurrentPage(LANDING);
    }
  };

  return (
    <Col>
      { selectedAdmin.profile && selectedAdmin.profile.pk === userData.pk ? null :
        <div className="admin-edit-delete-btn-area">
          <a
            className="admin-edit-page-delete-btn"
            onClick={() => handleDeleteAdminClick(selectedAdmin, 'edit')}
          >
            <img
            className="admin-edit-delete-icon"
            src={Assets.DeleteIcon}
            alt="delete-icon"
            />
            {t("admin.delete_this_user")}
          </a>
        </div>
      }
      <Row className="admin-edit-page justify-content-center">
        <Col xs={12} md={6} lg={5}>
          <CTA
            miniPadding
            text={t("admin.back_to_manage_org")}
            onClick={() => props.updateCurrentPage(LANDING)}
          >
            <div className="admin-edit-form-container">
              <div className="admin-edit-container">
                <Form onSubmit={formik.handleSubmit}>
                  <p className="admin-edit-form-title">{t("admin.edit_admin")}</p>
                  <Form.Group controlId="emailGroup">
                    <Form.Label className="input-label">
                      {t("admin.edit_admin_description")}
                    </Form.Label>
                    <Form.Control
                      name="email"
                      disabled={inProgress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className={formik.touched.email && formik.errors.email && "highlight-input"}
                    />
                    { formik.touched.email && formik.errors.email ?
                      (
                        <p className="form-error-text">{formik.errors.email}</p>
                      ) : null
                    }
                  </Form.Group>
                  <Form.Group controlId="communicationPreferenceGroup">
                    <Form.Label className="input-label">{t("admin.communication_preference")}</Form.Label>
                    <span className="custom-radio">
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        disabled={inProgress}
                        label={t("admin.subscribe_for_notifications")}
                        name="subscriptionPreference"
                        id="formHorizontalRadios1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.subscriptionPreference === 'subscribe'}
                        value={'subscribe'}
                      />
                    </span>
                    <span className="custom-radio">
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        disabled={inProgress}
                        label={t("admin.unsubscribe")}
                        name="subscriptionPreference"
                        id="formHorizontalRadios2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.subscriptionPreference === 'unsubscribe'}
                        value={'unsubscribe'}
                      />
                    </span>
                  </Form.Group>

                  <Form.Row>
                    <Col sm={12}>
                      {
                        formik.submitCount > 0 && !formik.isValid ?
                          <span className="form-final-error-text">
                            <img
                              src={Assets.ErrorIcon}
                              alt="error-icon"
                              className="final-error-icon"
                            />
                            {t("common.correct_errors_above")}
                          </span>
                          : null
                      }
                    </Col>
                    <Col sm={12}>
                      <Button
                        disabled={inProgress}
                        variant="primary"
                        type="submit"
                        className="button-purple"
                      >
                         {t("admin.save")}
                         {
                            inProgress &&
                            <Spinner animation="border" role="status">
                              <span className="sr-only">{t("dashboard.loading")}</span>
                            </Spinner>
                          }
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            </div>
          </CTA>
        </Col>
      </Row>
    </Col>
  );
};

export default EditUser;
