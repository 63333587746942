"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _typeof1;
var _typeof = function(obj) {
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
};
function _typeof1(obj) {
    return obj && obj.constructor === Symbol ? "symbol" : _typeof(obj);
}
;
