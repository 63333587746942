import React from 'react';

import ProfileContext from "~/src/context/profileContext";
import OrganizationContext from "~/src/context/organizationContext";
import AdminContext from "~/src/context/adminContext";
import ListUsers from "./index";

import './style.scss';

const AdminContainer = props => (
    <ProfileContext.Wrapper {...props}>
      <OrganizationContext.Wrapper {...props}>
        <AdminContext.Wrapper {...props}>
          <ListUsers />
        </AdminContext.Wrapper>
      </OrganizationContext.Wrapper>
    </ProfileContext.Wrapper>
);

export default AdminContainer;
