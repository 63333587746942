import React from "react";
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { POST, GET, makeAPIcall } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import { isAuthRelatedError } from "~/src/api/errors";
import EditOrg from "./editOrganization"
import { UN_INVITED } from '~/src/constants/subpage';
import { reverse } from "~/src/api/urls";
import './createOrg.scss';

const EditOrgContainer = (props) => {
  const { t, i18n } = useTranslation();
  const [orgDetail, setOrgDetail] = React.useState('');
  const { setInitialHeader, handleAuthError, setOrganization, userData } = React.useContext(AuthContext);

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setOrganization(result.payload[0]);
        setOrgDetail(result.payload[0]);
      }
    }
  };

  const getOrganization = () => {
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };

  React.useEffect(() => {
    window.setWindowTitle(t("launch_guide.org_creation"));
    setInitialHeader({ mainHeader: UN_INVITED, subHeader: null });
    getOrganization();
  }, []);

  if (orgDetail && orgDetail.name) {
    return (
      <EditOrg orgDetail={orgDetail} {...props} />
    );
  }
  return null
};

export default EditOrgContainer;
