import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { reverse } from '~/src/api/urls';
import {
  setPasswordForResetCode,
  getProviderFromEmail,
} from "~/src/utils/auth";
import { FORGOT_PASSWORD } from "~/src/constants/subpage";
import AuthContext from '~/src/context/authContext';
import CTA from "~/src/components/ctaBox";
import Assets from '~/src/constants/assets';
import ButtonWithProgress from '~/src/components/buttonWithProgress';
import '../style.scss';

const ResetPasswordPage = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { setToastMsg } = React.useContext(AuthContext);

  /**
   * Store previous error messages for emails.
   * Use a function to store as we would have to translate or do some changes on the messages.
   * Example: previousErrorMessages.current[values.email] = () => t('auth.use_social_login').replace('%s', emailProvider)
   */
  const previousErrorMessages = React.useRef({});
  const [inProgress, setInProgress] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: props.email,
      code: null,
      newPassword: '',
      newPasswordReTyped: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('common.enter_valid_email')).required(t('common.required_email')),
      code: Yup.number().typeError(t('common.code_validation')).required(t('common.incorrect_verification')),
      newPassword: Yup.string().required(t('common.new_password_guide')).matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t('common.password_suggestion_mismatch')
        //Checks for 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character
      ),
      newPasswordReTyped: Yup.string().required(t('common.required_password')).oneOf(
        [Yup.ref('newPassword'), null],
        t('common.password_dont_match'),
      ),
    }),
    validate: (values) => {
      let errors = {};
      try {
        if (previousErrorMessages.current[values.email.toLowerCase()]) {
          errors.email = previousErrorMessages.current[values.email.toLowerCase()]();
        }
      } catch (ex) { /* ignored */ }
      return errors;
    },
    onSubmit: async (values, { setErrors, setFieldError }) => {
      setInProgress(true);
      setErrors({});

      try {
        values.email = values.email.toLowerCase();
        await setPasswordForResetCode(values.email, values.code, values.newPassword);
        setToastMsg(t('common.password_reset_success'), false);
        history.push(reverse("app:login_page"));
      } catch (ex) {
        switch (ex.code) {
          case 'InvalidPasswordException':
            setFieldError('newPassword', t('common.password_suggestion_mismatch'));
            break;

          case 'ExpiredCodeException':
            setFieldError('code', t('common.code_expired'));
            break;

          case 'CodeMismatchException':
            setFieldError('code', t('common.incorrect_verification'));
            break;

          case 'UserNotFoundException': {
              const emailProvider = await getProviderFromEmail(values.email);
              if (emailProvider) {
                previousErrorMessages.current[values.email] = () => t('auth.use_social_login').replace('%s', emailProvider);
                setFieldError('email', t('auth.use_social_login').replace('%s', emailProvider));
              } else {
                previousErrorMessages.current[values.email] = () => t('common.user_does_not_exist');
                setFieldError('email', t('common.user_does_not_exist'));
              }
              break;
            }

          default:
            setFieldError('form', t('common.generic_error_message'));
        }
      }
      setInProgress(false);
    }
  });

  return (
    <CTA
      miniPadding
      link={reverse("app:login_page")}
      text={t('auth.back_login')}
      className="reset-password-page"
    >
      <div className="reset-form-container">
        <Form name="resetPassword" onSubmit={formik.handleSubmit}>
          <p className="reset-form-title">{t('auth.reset_password')}</p>
          {
            props.email ? null :
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="input-label">{t('auth.email')}</Form.Label>
              <Form.Control
                name="email"
                autoComplete="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email || ''}
                className={formik.touched.email && formik.errors.email && 'highlight-input'}
              />
              { formik.touched.email && formik.errors.email ?
                (
                  <p className="form-error-text">{formik.errors.email}</p>
                ) : null
              }
            </Form.Group>
          }
          <Form.Group controlId="codeInput">
            <p className="form-describe">
              {t('auth.enter_verification_description')}
            </p>
            <Form.Label className="input-label">{t('auth.verification_code')}</Form.Label>
            <Form.Control
              name="code"
              autoComplete="one-time-code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code || ''}
              className={formik.touched.code && formik.errors.code && 'highlight-input'}
            />
            { formik.touched.code && formik.errors.code ?
              (
                <p className="form-error-text">{formik.errors.code}</p>
              ) : null
            }
          </Form.Group>
          <div>
            <p className="form-describe">
              {t('auth.password_rules_description')}
            </p>
            <ul>
              <li className="password-suggestion-li"><span style={{marginRight:5, display: 'inline-block'}}>•</span>{t('common.min_8')}</li>
              <li className="password-suggestion-li"><span style={{marginRight:5, display: 'inline-block'}}>•</span>{t('common.special_char')}</li>
              <li className="password-suggestion-li"><span style={{marginRight:5, display: 'inline-block'}}>•</span>{t('common.uppercase')}</li>
              <li className="password-suggestion-li"><span style={{marginRight:5, display: 'inline-block'}}>•</span>{t('common.lowercase')}</li>
              <li className="password-suggestion-li"><span style={{marginRight:5, display: 'inline-block'}}>•</span>{t('common.number_1')}</li>
            </ul>
          </div>
          <Form.Group controlId="newPasswordInput">
            <Form.Label className="input-label">{t('auth.new_password')}</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              autoComplete="new-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword || ''}
              className={formik.touched.newPassword && formik.errors.newPassword && 'highlight-input'}
            />
            { formik.touched.newPassword && formik.errors.newPassword ?
              (
                <p className="form-error-text">{formik.errors.newPassword}</p>
              ) : null
            }
          </Form.Group>
          <Form.Group controlId="confirmedPasswordInput">
            <Form.Label className="input-label">{t('auth.new_password_again')}</Form.Label>
            <Form.Control
              type="password"
              name="newPasswordReTyped"
              autoComplete="new-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPasswordReTyped || ''}
              className={formik.touched.newPasswordReTyped && formik.errors.newPasswordReTyped && 'highlight-input'}
            />
            { formik.touched.newPasswordReTyped && formik.errors.newPasswordReTyped ?
              (
                <p className="form-error-text">{formik.errors.newPasswordReTyped}</p>
              ) : null
            }
          </Form.Group>
          <Form.Row>
            <Col xs={12}>
              { !formik.errors.form && formik.submitCount > 0 && !formik.isValid ?
                <div className="form-final-error-text">
                  <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                  {t('common.correct_errors_above')}
                </div>
                : null
              }
              {
                formik.errors.form &&
                  <p className="form-error-text">{formik.errors.form}</p>
              }
            </Col>
            <Col xs={12}>
              <ButtonWithProgress
                inProgress={inProgress}
                text={t('auth.reset_password_button')}
              />
            </Col>
          </Form.Row>
        </Form>
      </div>
    </CTA>
  );
}

export default ResetPasswordPage;
