import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  Button,
  Form,
  Col,
  Row,
  Spinner,
} from 'react-bootstrap';

import { LANDING } from ".././constants/subpage";
import { isAuthRelatedError } from "~/src/api/errors";
import { reverse } from '~/src/api/urls';
import { POST, makeAPIcall } from '~/src/api/utils';
import AuthContext from '~/src/context/authContext';
import ProfileContext from '~/src/context/profileContext';
import CTA from "~/src/components/ctaBox";
import Assets from '~/src/constants/assets';

const MyProfileEdit = (props) => {
  const { t, i18n } = useTranslation();

  const [inProgress, setInProgress] = React.useState(false);
  const { handleAuthError, setToastMsg } = React.useContext(AuthContext);
  const { userData, setUserData } = React.useContext(ProfileContext);

  const formik = useFormik({
    initialValues: {
      email: userData.email,
      first_name: userData.first_name ? userData.first_name : '',
      last_name: userData.last_name ? userData.last_name : ''
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim().required(t("auth.first_name_required")),
      last_name: Yup.string()
    }),
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      const requestData = {
        first_name: values.first_name,
        last_name: values.last_name
      };
      makeAPIcall(reverse('api:user_edit'), POST, userDataUpdateAPIHandler, requestData);
    }
  });

  const userDataUpdateAPIHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setUserData(result.payload);
      setToastMsg(t('auth.profile_saved'), false);
      props.updateCurrentPage(LANDING);
    }
    setInProgress(false);
  };

  return (
    <Col xs={12} md={5} className="profile-edit-page d-flex justify-content-center">
      <CTA
        className="profile-edit-form-area"
        text={t("auth.back_profile")}
        onClick={() => props.updateCurrentPage(LANDING)}
      >
        <div className="profile-edit-form-container">
          <Form onSubmit={formik.handleSubmit}>
            <p className="password-form-title">{t("auth.manage_my_profile")}</p>
            <div>
              <h5 className="email-data">{t("auth.user_detail")}</h5>
            </div>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="input-label">
                {t("auth.email_address_edit_profile")}
              </Form.Label>
              <Form.Control
                type="email"
                disabled
                value={formik.values.email}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("auth.first_name")}<span>*</span></Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                className={formik.touched.first_name && formik.errors.first_name && "highlight-input"}
              />
              { formik.touched.first_name && formik.errors.first_name ?
                (
                  <p className="form-error-text">{formik.errors.first_name}</p>
                ) : null
              }
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("auth.last_name")}</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                className={formik.touched.last_name && formik.errors.last_name && "highlight-input"}
              />
              { formik.touched.last_name && formik.errors.last_name ?
                (
                  <p className="form-error-text">{formik.errors.last_name}</p>
                ) : null
              }
            </Form.Group>
            <Form.Row>
              <Col sm={12}>
                { formik.submitCount > 0 && !formik.isValid ?
                  <span className="form-final-error-text">
                    <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                    {t("common.correct_errors_above")}
                  </span>
                  : null
                }
              </Col>
              <Col sm={12}>
                <Button
                  disabled={inProgress}
                  variant="primary"
                  type="submit"
                  className="button-purple"
                >
                  {t("auth.save_password")}
                  {
                    inProgress &&
                    <Spinner animation="border" role="status">
                      <span className="sr-only">{t("dashboard.loading")}</span>
                    </Spinner>
                  }
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </CTA>
    </Col>
  );
};

export default MyProfileEdit;
