import React from "react";
import { useTranslation } from 'react-i18next';
import LaunchGuide from "~/src/components/launchGuide";
import { Row } from 'react-bootstrap';
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall, GET } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import { UN_INVITED } from '~/src/constants/subpage';
import StepsContainer from '~/src/onboarding/steps';
import './createOrg.scss';

const LaunchContainer = () => {
  const { t, i18n } = useTranslation();

  const { setInitialHeader, handleAuthError, setToastMsg, setOrganization, userData } = React.useContext(AuthContext);
  const [selectedPlan, setSelectedPlan] = React.useState('');
  const [org, setSelectedOrg] = React.useState('');
  const [organizationList, setorganizationList] = React.useState({});

  const organizationCheckList = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setorganizationList(result.payload);
      }
    }
  }

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        setOrganization(result.payload[0]);
        setSelectedPlan(result.payload[0].organization_size);
        setSelectedOrg(result.payload[0]);
        makeAPIcall(reverse('api:organization_checklist_list', result.payload[0].pk), GET, organizationCheckList);
      }
    }
  };

  const getOrganization = () => {
    makeAPIcall(reverse('api:organization_list_user', userData.pk), GET, organizationListHandler);
  };

  const updateOrgCheckList = (items) => {
    setorganizationList(items);
  }

  React.useEffect(() => {
    setToastMsg(t('launch_guide.payment_saved'), false);
    window.setWindowTitle(t("launch_guide.step3_title"));
    setInitialHeader({ mainHeader: UN_INVITED, subHeader: null });
    getOrganization();
  }, []);

  const { invite_admin, create_group, launch, promote, join_team } = organizationList;
  const launchGuideComplete = Object.values({ invite_admin, create_group, launch, promote, join_team }).every(Boolean);

  return (
    <Row className="create-org-page justify-content-center align-items-start">
      <StepsContainer currentStep='step3' launchGuideComplete={launchGuideComplete} />
      <LaunchGuide
        organizationList={organizationList}
        organization={org}
        onCheckboxChange={updateOrgCheckList}
      />
    </Row>
  );
};

export default LaunchContainer;
