import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Spinner,
    Button
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import ToolTip from '~/src/components/Tooltip';
import Assets from '~/src/constants/assets';
import { reverse } from "~/src/api/urls";

import './style.scss';

const Icons = {
  runnerSingle: Assets.RunnerSingleIcon,
  runnerMultiple: Assets.RunnerMultipleIcon,
  flag: Assets.Flag,
  cup: Assets.CupIcon,
  stand: Assets.MoveIcon
};

const ParticipationCard = ({ icon, text, count, helpText, cardView, title, nationalAverageValue, isChallengeNotStarted }) => {
  const { t, i18n } = useTranslation();

  const renderBody = (cardView) => {
    switch (cardView) {
      case "normal":
      case "average_milestone":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="icon-container">
              {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
            </div>
            <div className="title">{t(text)}</div>
            <div className="count">{(isChallengeNotStarted ? 0 : count)}</div>
          </div>
        );
        break;
      case "completed_challenge":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="count">{(isChallengeNotStarted ? 0 : count)}</div>
            <div className="title">{t(text)}</div>
          </div>
        );
        break;
      case "average_per_person":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="row1">
              {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
              {t(title)}
            </div>
            <div className="row2">
              <div className="move-min">
                <div className="count-num">{(isChallengeNotStarted ? 0 : count)}</div>
                <div className="text-val">{t(text)}</div>
              </div>
              <div className="national-avg">
                <div className="icon">
                  <img className="card-icon" src={Assets.NATIONAL_AVERAGE} />
                </div>
                <div className="text-val">
                  {t("challenge_report.national_avg")}
                </div>
                <div className="count-num">{(isChallengeNotStarted ? 0 :nationalAverageValue)}</div>
              </div>
            </div>
          </div>
        );
        break;
      case "total_activity":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="row1">
              {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
              {t(title)}
            </div>
            <div className="row2">
              <div className="move-min">
                <div className="count-num">{(isChallengeNotStarted ? 0 : count)}</div>
                <div className="text-val">{t(text)}</div>
              </div>
            </div>
          </div>
        );
        break;
      case "dec_average_milestone":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="icon-container">
              {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
            </div>
            <div className="title">{t(text)}</div>
            <div className="count">{(isChallengeNotStarted ? 0 : count)}</div>
          </div>
        );
        break;
      case "dec_average_per_person":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="icon-title-container">
              <div className="icon-container">
                {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
              </div>
              <div className="title">{t(title)}</div>
            </div>

            <div className="col2">
              <div className="row1">
                <div className="move-min">
                  <div className="count-num">{(isChallengeNotStarted ? 0 : count)}</div>
                </div>
              </div>
              <div className="row2">
                <div className="national-avg">
                  <div className="icon">
                    <img className="card-icon" src={Assets.NATIONAL_AVERAGE} />
                  </div>
                  <div className="text-val">
                    {t("challenge_report.national_avg")}
                  </div>
                  <div className="count-num">{(isChallengeNotStarted ? 0 :nationalAverageValue)}</div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "dec_total_activity":
        return (
          <div className={(isChallengeNotStarted ? `add-opacity-for-upcoming-challenge ${cardView}` : cardView)}>
            <div className="icon-container">
              {Icons[icon] && <img className="card-icon" src={Icons[icon]} />}
            </div>
            <div className="dec_total_activity_col">
              <div className="row1">{t(title)}</div>
              <div className="row2">
                <div className="move-min">
                  <div className="count-num">{(isChallengeNotStarted ? 0 : count)}</div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
  }

  return (
    <div className='participation-card'>
      <ToolTip
        className="info-icon"
        text={t(helpText)}
      />
      {renderBody(cardView)}
    </div>
  );
};
    

export default ParticipationCard;
