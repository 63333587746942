import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Form, Col, Row, Spinner, Overlay, Tooltip } from 'react-bootstrap';
import { reverse } from "~/src/api/urls";
import { POST, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from "~/src/context/authContext";
import CTA from "~/src/components/ctaBox";
import { ORGANIZATION_LANDING_PAGE, LAUNCH_GUIDE_LANDING_PAGE } from "~/src/constants/subpage";
import { Link } from "react-router-dom";
import Assets from '~/src/constants/assets';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ButtonWithProgress from '~/src/components/buttonWithProgress';
import LaunchGuideModal from "~/src/components/modal/LaunchGuideModal";
import { pushToPageLayer } from '~/src/utils/GA';
import "./style.scss";

const LaunchGuide = ({ organizationList, organization, onCheckboxChange }) => {

  const { invite_admin, create_group, launch, promote, join_team } = organizationList;
  const { t, i18n } = useTranslation();

  const { handleAuthError } = React.useContext(AuthContext);
  const [show, setShow] = React.useState(false);
  const [checkboxList, setCheckboxList] = React.useState({ invite_admin, create_group, launch, promote, join_team });

  const copyCodeRef = React.useRef();
  const copyCodeTimeoutRef = React.useRef();
  const [isCopiedTooltipVisible, setCopiedTooltipVisible] = React.useState();

  const handleCheckboxChange = (event) => {
    const updatedCheckList = {...checkboxList, [event.target.value]: event.target.checked };
    if (onCheckboxChange) {
      onCheckboxChange(updatedCheckList);
    }
    setCheckboxList(updatedCheckList);
    makeAPIcall(reverse('api:organization_checklist_update', organization.pk), POST, (error, result) => {
        checklistDataAPIHandler(error, result, updatedCheckList);
      }, updatedCheckList);
  }

  const hide = () => {
    setShow(false)
  };


  const checklistDataAPIHandler = async (error, result, updatedCheckList) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setCheckboxList(updatedCheckList);
      const boolVal = Object.values(updatedCheckList).every(Boolean);
      if (boolVal) {
        setShow(true)
      }
    }
  };

  React.useEffect(() => {
    const { invite_admin, create_group, launch, promote, join_team } = organizationList;
    setCheckboxList({ invite_admin, create_group, launch, promote, join_team });
    pushToPageLayer(t("launch_guide.step3_title"));

  }, [organizationList && organizationList.invite_admin]);

  return (
    <Col lg={12} className="launch-guide-section">
      <CTA
        miniPadding
        className="content"
      >
        <div className="org-edit-title-area">
          <p className="org-edit-form-title">{t("launch_guide.step3_title")}</p>
        </div>
        <div className="pac-code">
          <div className="icon-container">
            <img
              className="card-icon"
              src={Assets.BulbIcon}
            />
          </div>
          <div className="description-container">
            <div className="title">{t('launch_guide.step3_pac_code_title')}</div>
            <div className="desc">
              <div className="pac_code_desc">
                <span>{t('launch_guide.pac_code_desc1')}</span>
                <span className="orange">
                  <a href="https://www.participaction.com/en-ca/programs/app" target="_blank">{t('launch_guide.pac_code_desc1_link')}!</a></span>
              </div>
              <div className="pac_code_desc copy_code">
                <span>{t('launch_guide.pac_code_desc2')}</span>
                <span className="orange copy-code-container">
                  <CopyToClipboard
                    text={organization.invite_code}
                    onCopy={() => {
                      /* Show tooltip and hide the tooltip after sometime */
                      setCopiedTooltipVisible(true);
                      window.clearTimeout(copyCodeTimeoutRef.current);
                      copyCodeTimeoutRef.current = window.setTimeout(() => {
                        setCopiedTooltipVisible(false);
                      }, 3000);
                    }}
                  >
                    <span
                      className="copy-code"
                      ref={copyCodeRef}
                    >
                      {organization.invite_code}
                      <img
                        src={Assets.CopyIcon}
                        title="Copy invite code"
                      />
                    </span>
                  </CopyToClipboard>
                  <Overlay target={copyCodeRef} show={isCopiedTooltipVisible} placement="bottom">
                    {(props) => (
                      <Tooltip {...props}>
                        Code Copied!
                      </Tooltip>
                    )}
                  </Overlay>
                </span>
              </div>
              <div className="pac_code_desc">
                <span>{t('launch_guide.pac_code_desc3')}</span>
                <Link target="_blank" to={reverse('app:organization_page')} className="orange">
                  {t('launch_guide.pac_code_desc3_link')}
                </Link>
              </div>

            </div>
          </div>
        </div>
        <div className="title-description">
          {t("launch_guide.step3_descr")}
        </div>
        <div className="numbered-list">
          <ol className="list-group list-group-numbered">
            <li className="list-group-item">{t('launch_guide.point1')}</li>
            <Form.Group controlId="invite_admin">
              <span className="custom-checkbox">
                <Form.Check
                  inline
                  custom
                  type="checkbox"
                  label={
                    <span>
                      <span>
                        <Link target="_blank" to={reverse('app:admin_page')} className="orange">
                          {t('launch_guide.label1_2')}
                        </Link>
                      </span>
                      {t('launch_guide.label1_3')}
                    </span>
                  }
                  onChange={handleCheckboxChange}
                  checked={checkboxList.invite_admin}
                  value={'invite_admin'}
                />
              </span>
            </Form.Group>
            <li className="list-group-item">{t('launch_guide.point2')}</li>
            <Form.Group controlId="create_group">
              <span className="custom-checkbox">
                <Form.Check
                  inline
                  custom
                  type="checkbox"
                  label={
                    <span>
                      <span>
                        <Link target="_blank" to={reverse('app:group_page')} className="orange">
                          {t('launch_guide.label2_2')}
                        </Link>
                      </span>
                      {t('launch_guide.label2_3')}
                    </span>
                  }
                  onChange={handleCheckboxChange}
                  checked={checkboxList.create_group}
                  value={'create_group'}
                />
              </span>
            </Form.Group>
            <li className="list-group-item">{t('launch_guide.point3')}</li>
            <Form.Group controlId="template">
              <span className="custom-checkbox">
                <Form.Check
                  inline
                  custom
                  type="checkbox"
                  label={
                    <span>
                      {t("launch_guide.label3_1")}
                      <span>
                        <Link target="_blank" to={reverse('app:resources')} className="orange">
                          {t("launch_guide.label3_2")}
                        </Link>
                      </span>
                      {t('launch_guide.label3_3')}
                      {t('launch_guide.label_new_1')}
                      <span className="orange video-link">
                        <a href={t('launch_guide.label_new_2_link')} target="_blank">{t('launch_guide.label_new_2')}</a>
                      </span>
                      {t('launch_guide.label_new_3')}
                    </span>
                  }
                  onChange={handleCheckboxChange}
                  checked={checkboxList.launch}
                  value={'launch'}
                />
              </span>
            </Form.Group>
            <li className="list-group-item">{t('launch_guide.point4')}</li>
            <Form.Group controlId="advertise">
              <span className="custom-checkbox">
                <Form.Check
                  inline
                  custom
                  type="checkbox"
                  label={
                    <span>
                      {t("launch_guide.label4_1")}
                      <span>
                        <Link target="_blank" to={reverse('app:resources')} className="orange">
                          {t("launch_guide.label4_2")}
                        </Link>
                      </span>
                      {t('launch_guide.label4_3')}
                    </span>
                  }
                  onChange={handleCheckboxChange}
                  checked={checkboxList.promote}
                  value={'promote'}
                />
              </span>
            </Form.Group>
            <li className="list-group-item">{t('launch_guide.point5')}</li>
            <Form.Group controlId="join_team">
              <span className="custom-checkbox">
                <Form.Check
                  inline
                  custom
                  type="checkbox"
                  label={
                    <span>
                      {t("launch_guide.label5_1")}
                      <span>
                        <Link target="_blank" to={reverse('app:team_page')} className="orange">
                          {t("launch_guide.label5_2")}
                        </Link>
                      </span>
                      {t('launch_guide.label5_3')}
                    </span>
                  }
                  onChange={handleCheckboxChange}
                  checked={checkboxList['join_team']}
                  value={'join_team'}
                />
              </span>
            </Form.Group>
          </ol>
        </div>
        <div className="org-edit-save-btn-area">
          <Link to={reverse('app:home_page')}>
            <ButtonWithProgress
              text={t('launch_guide.dashboard_link')}
            />
          </Link>
        </div>
      </CTA>
      {show && <LaunchGuideModal onHide={hide}/>}
    </Col>
  );
};

export default LaunchGuide;
