import React from 'react';

import OrganizationContext from '~/src/context/organizationContext';
import ChallengeReportContext from '~/src/context/challengeReportContext';
import Home from './index';

const ChallengeReportContainer = props => (
  <OrganizationContext.Wrapper {...props}>
    <ChallengeReportContext.Wrapper {...props}>
      <Home />
    </ChallengeReportContext.Wrapper>
  </OrganizationContext.Wrapper>
);

export default ChallengeReportContainer;
