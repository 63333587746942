/**
 * This file is used to do various setup that is needed before any route is mounted.
 */

/* Extends line chart to show line on hover */

import { Chart } from 'chart.js';

import gradient from '~/src/utils/chartjsGradient';

Chart.register([
  gradient,
]);


/* Sets window title dynamically from components */
window.setWindowTitle = (title) => {
  try {
    document.querySelector('head > title').innerText = `${title} | ParticipACTION+`;
  } catch(ex) { /* ignored */ }
};


/* initialize AWS Amplify  */
require('./awsConfigure');
