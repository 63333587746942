import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { FORGOT_PASSWORD, RESET_PASSWORD } from '~/src/constants/subpage';
import ForgotPasswordPage from './forgotPassword';
import ResetPasswordPage from './resetPassword';

import '../style.scss';


const ResetPasswordContainer = props => {
  const [currentPage, setCurrentPage] = React.useState(FORGOT_PASSWORD);
  const [email, setEmail] = React.useState('');

  return (
    <Row className="auth-container align-items-center">
      <Col xs={12} md={6} className="d-flex justify-content-center">
        {currentPage === FORGOT_PASSWORD ?
          <ForgotPasswordPage setCurrentPage={setCurrentPage} setEmail={setEmail} /> :
          <ResetPasswordPage setCurrentPage={setCurrentPage} email={email} />
        }
      </Col>
    </Row>
  );
};

export default ResetPasswordContainer;
