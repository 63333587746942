import React from 'react';
import { Button } from 'react-bootstrap';
import Assets from '~/src/constants/assets';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

const sortPerformanceTable = (data, sortObj) => {
  const col = sortObj.sortCol;
  const mode = sortObj.sortMode;
  return data.sort(function(a, b) {
    const valueOne = a[col];
    const valueTwo = b[col];

    if (valueOne === valueTwo) {
      return 0;
    }

    if (mode === 'asc') {
      return (valueOne > valueTwo) ?  1 : -1;
    }

    return (valueTwo > valueOne) ? 1 : -1;
  });
};

const filterSortPerformanceTable = (data, filterKey, sortObj) => {
  const tempData = data && filterKey && data.filter((item) => { return item.division_id === filterKey.value });
  const finalList = sortPerformanceTable((filterKey.value === 'default' ?  data : tempData), sortObj);
  return finalList;
}

const sortandFilterCarouselList = (data) => {
  return data.sort(function(a, b) {
    const valueOne = new Date(a['launch_at']);
    const valueTwo = new Date(b['launch_at']);
    if (valueOne === valueTwo) {
      return 0;
    }
    // ascending order
    if (valueOne > valueTwo) {
      return 1;
    }
    return -1;
  }).filter((item) => {
    return (item.challenge_cadence === "quarterly");
  });
};

const getGroupOption = (data, defaultLable) => {
  if (!data) return;
  const groupsData = data && data.division_breakdown_data && data.division_breakdown_data.breakdown;
  const groupOptions = [];
  groupsData && groupsData.map((item) => {
    const obj = { value: item.division_id, label: item.division_name };
    groupOptions.push(obj);
  });
  groupOptions.unshift({ value: 'default', label: defaultLable });
  return groupOptions;
}

const getSortIndicator = (selectedSort, selectedSortMode, currentCol) => {
  if (currentCol !== selectedSort) {
    return null;
  }

  return (
    <img
      className={`sort-indicator ${selectedSortMode}`}
      src={Assets.WhiteIndicatorIcon}
    />
  );
}

const getSortColHeaderButton = (title, column, currentSortCol, currentSortMode, setSort) => (
  <Button
    variant="link"
    onClick={() => setSort({ sortCol: column, sortMode: currentSortMode === 'asc' ? 'desc' : 'asc' }) }
  >
    {title}
    {
      getSortIndicator(
        currentSortCol,
        currentSortMode,
        column,
      )
    }
  </Button>
);

const formatDate = (dateVal, formatString, tz='UTC',) => {
  return format(utcToZonedTime(dateVal, tz), formatString);
}

const averageMilestoneAchievedAug = (currentReport) => {
  let overallMilestonesAchieved = 0;
  let totalTeamContributed = 0;

  const totalMilestone = (currentReport.challenge_data && currentReport.challenge_data.total_milestones) || 0;
  const totalParticipatingTeam = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_teams) || 1;
  const totalTeamCompletedAllMilestone = (currentReport.engagement_data && currentReport.engagement_data.total_teams_that_completed_the_challenge) || 0;
  const participatingTeamWithZeroMoveMin = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_team_with_zero_move_min) || 0;

  const breakdown = (currentReport.engagement_data && currentReport.engagement_data.team_milestone_breakdown) || [];
  breakdown.map((item) => {
    if (item.sequence_number == 0) {
      // If the seq_num is 0, dont consider team who achieved 0 move_min
      let tempTeam = (item.total_teams_on_this_milestone - participatingTeamWithZeroMoveMin);
      totalTeamContributed += tempTeam;
    } else if (item.sequence_number + 1 == totalMilestone) {
      // If the seq_num is 1 less than total milestone then
      // consider splitting the milestone earned between total milestone earned & one less than total milestone earned
      const totalTeamCompletedOneLessThanAllMilestone = item.total_teams_on_this_milestone - totalTeamCompletedAllMilestone;
      overallMilestonesAchieved += (((totalMilestone - 1) * totalTeamCompletedOneLessThanAllMilestone) + (totalMilestone * totalTeamCompletedAllMilestone));
      totalTeamContributed += item.total_teams_on_this_milestone;
    } else {
      const seqNum = item.sequence_number
      overallMilestonesAchieved += ((item.sequence_number) * (item.total_teams_on_this_milestone));
      totalTeamContributed += item.total_teams_on_this_milestone;
    }

  });
  averageVal = overallMilestonesAchieved/totalTeamContributed;
  return Math.abs(Math.round(averageVal)) || 0;
}

const averageMilestoneAchievedGBM = (currentReport) => {
  let overallMilestonesAchieved = 0;
  let totalTeamContributed = 0;

  // need to add this as column to Challenge Milestone Model
  const nonBonusMilestone = 13;
  const participatingTeamWithZeroMoveMin = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_team_with_zero_move_min) || 0;

  const breakdown = (currentReport.engagement_data && currentReport.engagement_data.team_milestone_breakdown) || [];

  breakdown.map((item) => {
    if (item.sequence_number == 0) {
      // If the seq_num is 0, dont consider team who achieved 0 move_min
      let tempTeam = (item.total_teams_on_this_milestone - participatingTeamWithZeroMoveMin);
      totalTeamContributed += tempTeam;
    } else if (item.sequence_number >= nonBonusMilestone) {
      // seq number greater than or equal to 13 (in case of GBM) should be considered as completed milestone 13
      overallMilestonesAchieved += (nonBonusMilestone * item.total_teams_on_this_milestone);
      totalTeamContributed += item.total_teams_on_this_milestone;
    } else {
      overallMilestonesAchieved += ((item.sequence_number) * (item.total_teams_on_this_milestone));
      totalTeamContributed += item.total_teams_on_this_milestone;
    }

  });
  averageVal = overallMilestonesAchieved/totalTeamContributed;
  return Math.abs(Math.round(averageVal)) || 0;
}

const averageMilestoneAchievedDEC = (currentReport) => {
  let overallMilestonesAchieved = 0;
  let totalTeamContributed = 0;

  // need to add this as column to Challenge Milestone Model
  const nonBonusMilestone = currentReport.challenge_data.total_milestones;
  const participatingTeamWithZeroMoveMin = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_team_with_zero_move_min) || 0;

  const breakdown = (currentReport.engagement_data && currentReport.engagement_data.team_milestone_breakdown) || [];

  breakdown.map((item) => {
    if (item.sequence_number == 0) {
      // If the seq_num is 0, dont consider team who achieved 0 move_min
      let tempTeam = (item.total_teams_on_this_milestone - participatingTeamWithZeroMoveMin);
      totalTeamContributed += tempTeam;
    } else if (item.sequence_number + 1 == nonBonusMilestone) {
      // seq number greater than or equal to 12 (in case of Decemeber Challenge) should be considered as completed milestone 12
      overallMilestonesAchieved += (nonBonusMilestone * item.total_teams_on_this_milestone);
      totalTeamContributed += item.total_teams_on_this_milestone;
    } else {
      overallMilestonesAchieved += ((item.sequence_number) * (item.total_teams_on_this_milestone));
      totalTeamContributed += item.total_teams_on_this_milestone;
    }

  });
  averageVal = overallMilestonesAchieved/totalTeamContributed;
  return Math.abs(Math.round(averageVal)) || 0;
}

const averageMilestoneAchievedTrueNorth = (currentReport) => {
  let overallMilestonesAchieved = 0;
  let totalTeamContributed = 0;

  // need to add this as column to Challenge Milestone Model
  const nonBonusMilestone = currentReport.challenge_data.total_milestones;
  const participatingTeamWithZeroMoveMin = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_team_with_zero_move_min) || 0;

  const breakdown = (currentReport.engagement_data && currentReport.engagement_data.team_milestone_breakdown) || [];

  breakdown.map((item) => {
    if (item.sequence_number == 0) {
      // If the seq_num is 0, dont consider team who achieved 0 move_min
      let tempTeam = (item.total_teams_on_this_milestone - participatingTeamWithZeroMoveMin);
      totalTeamContributed += tempTeam;
    } else if (item.sequence_number + 1 == nonBonusMilestone) {
      // seq number greater than or equal to 10 (in case of True North Challenge) should be considered as completed milestone 10
      overallMilestonesAchieved += (nonBonusMilestone * item.total_teams_on_this_milestone);
      totalTeamContributed += item.total_teams_on_this_milestone;
    } else {
      overallMilestonesAchieved += ((item.sequence_number) * (item.total_teams_on_this_milestone));
      totalTeamContributed += item.total_teams_on_this_milestone;
    }

  });
  averageVal = overallMilestonesAchieved/totalTeamContributed;
  return Math.abs(Math.round(averageVal)) || 0;
}

const completedChallengePercentage = (currentReport) => {
  const totalTeam = (currentReport && currentReport.engagement_data && currentReport.engagement_data.participating_teams) || 1;
  const completed = (currentReport && currentReport.engagement_data && currentReport.engagement_data.total_teams_that_completed_the_challenge) || 0;
  percentageVal = (100*completed)/totalTeam;
  return `${Math.abs(Math.round(percentageVal))}%`;
}

export default {
  sortPerformanceTable,
  sortandFilterCarouselList,
  getGroupOption,
  filterSortPerformanceTable,
  getSortColHeaderButton,
  formatDate,
  averageMilestoneAchievedAug,
  averageMilestoneAchievedGBM,
  averageMilestoneAchievedDEC,
  averageMilestoneAchievedTrueNorth,
  completedChallengePercentage
};