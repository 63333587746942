import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import Constants from '~/src/constants/dashboard';
import ToolTip from '~/src/components/Tooltip';
import './style.scss';

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const Graph = React.memo(({
  data,
  labels,
  dataMeta,
  helpText,
}) => {
  const { t, i18n } = useTranslation();
  const finalGraphData = {
    labels,
    datasets: data.map((graphData, idx) => ({
      label: t(dataMeta[idx].name),
      data: graphData,
      fill: true,
      borderColor: dataMeta[idx].borderColor,
      yAxisID: dataMeta[idx].yAxisID,
      pointBorderColor: 'transparent',
      pointBackgroundColor: 'transparent',
      gradient: {
        backgroundColor: {
          axis: 'y',
          angle: 187,
          colors: {
            0.5: dataMeta[idx].colorRangeEnd,
            100: dataMeta[idx].colorRangeStart,
          },
        },
      },
    })),
  };

  return (
    <div className="graph-container">
      <ToolTip
        className="info-icon"
        text={t(helpText)}
      />
      <div id="legend-container" />
      <Line
        plugins={[
          {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
              const ul = getOrCreateLegendList(chart, options.containerID);

              // Remove old legend items
              while (ul.firstChild) {
                ul.firstChild.remove();
              }

              // Reuse the built-in legendItems generator
              const items = chart.options.plugins.legend.labels.generateLabels(chart);

              items.forEach(item => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                  chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                  chart.update();
                };

                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.strokeStyle;
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '17px';
                boxSpan.style.width = '17px';
                boxSpan.style.marginRight = '10px';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                textContainer.style.fontFamily = 'Open Sans, sans-serif';
                textContainer.style.fontSize = '1rem';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
              });
            }
          }
        ]}
        data={finalGraphData}
        options={Constants.GRAPH_OPTIONS}
      />
    </div>
  );
});

Graph.defaultProps = {
};

export default Graph;
