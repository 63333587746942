import React from 'react';

import OrganizationContext from '~/src/context/organizationContext';
import Resource from './index';

const ResourceContainer = props => ( 
  <OrganizationContext.Wrapper>
    <Resource />
  </OrganizationContext.Wrapper>
);

export default ResourceContainer;
