import React from 'react';

import OrganizationContext from "~/src/context/organizationContext";
import TeamList from "./index";
import './style.scss';

const TeamContainer = props => (
  <OrganizationContext.Wrapper {...props}>
      <TeamList />
  </OrganizationContext.Wrapper>
);

export default TeamContainer;
