export function pushEventToDataLayer(category, action, label, value, userId) {
  const pushData =  {
    event: 'event',
    userID: window.userID,
    eventProps: {
        category: category,
        action: action,
        label: label,
        value: value,
    }
  };
  dataLayer.push(pushData);
  console.log('Analytics event data:', pushData); // eslint-disable-line
}

export function pushToPageLayer(title) {
  let pushData = {
    event: 'pageview',
    userID: window.userID,
    page: {
      url: document.location.origin + document.location.pathname + document.location.search
    }
  };

  if (title) {
    pushData.page.title = title;
  }

  dataLayer.push(pushData);
  console.log('Analytics Page data:', pushData); // eslint-disable-line
}


export function pushLoginOrRegistrationEventToDataLayer(category, action, userID) {
  const pushData =  {
    event: 'login',
    userID: userID,
    eventProps: {
        category: category,
        action: action,
    }
  };
  dataLayer.push(pushData);
  console.log(' Login or Registration event data:', pushData); // eslint-disable-line
}

export default {
  pushEventToDataLayer,
  pushToPageLayer
}