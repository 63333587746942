import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
} from 'react-bootstrap';
import ChallengeReportContext from "~/src/context/challengeReportContext";
import BreakdownTable from './breakdownTable';
import Utils from '~/src/challengeReport/utils';

import Constants from '~/src/constants/challengeReport';
import { CHALLENGE_REPORT_LANDING_PAGE } from "~/src/constants/subpage";

import './style.scss'; 


const TableBreakdownSection = (props) => {
  const { t, i18n } = useTranslation();
  const { currentReport, challengeName, isChallengeNotStarted } = props;
  const { selectedGroup, setSelectedGroup, teamSort, setTeamSort, groupSort, setGroupSort, stepSort, setStepSort, moveMinsSort, setMoveMinsSort, activeMinsSort, setActiveMinsSort } = React.useContext(ChallengeReportContext);


  const getChallengeSort = (challengeName) => {
    let challengeSort = "";
    switch(challengeName) {
      case "APR_2022":
        challengeSort = stepSort;
        break;
      case "APR_2023":
        challengeSort = stepSort;
        break;
      case "AUG_2022":
        challengeSort = activeMinsSort;
        break;
      default:
        challengeSort = moveMinsSort;
        break;
    }
    return challengeSort;
  }

  return (
    <Row className={isChallengeNotStarted ? 'add-opacity-when-challenge-upcoming breakdown-section' : 'breakdown-section'}>
        <Col xs={12} sm={12} lg={6} className="breakdown-table">
          <BreakdownTable
            type="team-performance"
            title={t("challenge_report.team_performance_title")}
            helpText={t("challenge_report.team_performance_info")}
            data={isChallengeNotStarted ? [] : (currentReport.team_breakdown_data && Utils.filterSortPerformanceTable(currentReport.team_breakdown_data.breakdown, selectedGroup, getChallengeSort(challengeName)))}
            challengeData={currentReport.challenge_data}
            orgTotal={currentReport.team_breakdown_data && currentReport.team_breakdown_data.totals}
            dropDownOption={Utils.getGroupOption(currentReport, t('challenge_report.select_group'))}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            sortObj={getChallengeSort(challengeName)}
            setSort={challengeName === "APR_2022" ? setStepSort : challengeName === "APR_2023" ? setStepSort : challengeName === "AUG_2022" ? setActiveMinsSort : setMoveMinsSort}
            challengeName={challengeName}
            isChallengeNotStarted={isChallengeNotStarted}
          />
        </Col>
        <Col xs={12} sm={12} lg={6} className="breakdown-table">
          <BreakdownTable
            type="group-performance"
            title={t("challenge_report.group_performance_title")}
            helpText={ challengeName === 'APR_2022' ? t("challenge_report.april_total_activity_info") : challengeName === 'APR_2023' ? t("challenge_report.april_total_activity_info") : challengeName === 'AUG_2022' ? t("challenge_report.mazda_total_activity_info") : t("challenge_report.group_performance_info")}
            data={isChallengeNotStarted ? [] : (currentReport.division_breakdown_data && Utils.sortPerformanceTable(currentReport.division_breakdown_data.breakdown, groupSort))}
            challengeData={currentReport.challenge_data}
            orgTotal={currentReport.team_breakdown_data && currentReport.division_breakdown_data.totals}
            sortObj={groupSort}
            setSort={setGroupSort}
            challengeName={challengeName}
            isChallengeNotStarted={isChallengeNotStarted}
          />
        </Col>
    </Row>
  );
};

export default TableBreakdownSection;
