import React from 'react';
import {
    Button,
} from 'react-bootstrap';
import format from 'date-fns/format'
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';

import Assets from '~/src/constants/assets';
import Constants from '~/src/constants/dashboard';

/**
 * Gets the increase between val1 and val2 for display in `CounterCard`
 */
const getDelta = (val1, val2) => {
  if (!val2) {
    return {};
  }

  const differenceInPercentage = (((val1 - val2) / val2) * 100);
  const deltaSign = differenceInPercentage > 0 ? 1 : -1;
  const roundedDiffeerenceInPercentage = Math.abs(Math.round(differenceInPercentage));
  return {
    deltaPercentage: roundedDiffeerenceInPercentage,
    delta: `${roundedDiffeerenceInPercentage}%`,
    deltaSign,
  }
};

const getSumForMetricType = (metric, type) => {
  return new Intl.NumberFormat('en-US').format((metric && metric[type] && metric[type].valueSum && metric[type].valueSum.toFixed(1)) || 0);
}

const getAvgForMetricType = (metric, type) => {
  return new Intl.NumberFormat('en-US').format((metric && metric[type] && metric[type].valueAvg && metric[type].valueAvg.toFixed(1)) || 0);
}

const formateDate = (dte, formatStr = 'yyyy-MM-dd') => {
  return format(dte, formatStr);
};

const formateDateToYearMonthDay = (dte) => {
  return formateDate(dte, 'yyyy-MM-dd');
};

const formatDate = (dte) => {
  try {
    return format(dte, 'eeee, MMM dd, yyyy \'at\' hh:mm:ss a')
    return (new Intl.DateTimeFormat('en-US',{ dateStyle: 'full', timeStyle: 'medium' }).format(new Date(dte)));
  } catch (ex) {
    console.error('Error in formatting data', ex);
  }
  return '';
}

const getRow = (data, className) => (
   <tr key={data.name} className={className}>
    <td>{data.name}</td>
    <td>{getSumForMetricType(data, Constants.PULSE_CHECK_KEY.MOVE_MINUTES.SUM)}</td>
    <td>{getAvgForMetricType(data, Constants.PULSE_CHECK_KEY.MOVE_MINUTES.AVG)}</td>
    <td>{getSumForMetricType(data, Constants.PULSE_CHECK_KEY.ACTIVE_MINUTES.SUM)}</td>
    <td>{getAvgForMetricType(data, Constants.PULSE_CHECK_KEY.ACTIVE_MINUTES.AVG)}</td>
    <td>{getSumForMetricType(data, Constants.PULSE_CHECK_KEY.STEPS.SUM)}</td>
    <td>{getAvgForMetricType(data, Constants.PULSE_CHECK_KEY.STEPS.AVG)}</td>
    <td>{getSumForMetricType(data, Constants.PULSE_CHECK_KEY.STREAK.SUM)}</td>
  </tr>
);

const getSortIndicator = (selectedSort, selectedSortMode, currentCol) => {
  if (currentCol !== selectedSort) {
    return <span className="sort-indicator" />;
  }

  return (
    <img
      className={`sort-indicator ${selectedSortMode}`}
      src={Assets.WhiteIndicatorIcon}
    />
  );
}

const getSortColHeaderButton = (title, column, currentSortCol, currentSortMode, setOrgActivityBreakdownSort) => (
  <Button
    variant="link"
    onClick={() => setOrgActivityBreakdownSort(
      column,
      currentSortMode === 'asc' ? 'desc' : 'asc',
    )}
  >
    {title}
    {
      getSortIndicator(
        currentSortCol,
        currentSortMode,
        column,
      )
    }
  </Button>
);

const sortOrganizationActiviytBreakdownData = (data, col, mode) => {
  return data.sort(function(a, b) {
    const fieldName = col.includes('__avg__') ? 'valueAvg' : 'valueSum';
    const valueOne = a[col][fieldName];
    const valueTwo = b[col][fieldName];

    if (valueOne === valueTwo) {
      return 0;
    }

    if (mode === 'asc') {
      return (valueOne > valueTwo) ?  1 : -1;
    }

    return (valueTwo > valueOne) ? 1 : -1;
  });
};

/**
 * Calculates organization header data for the give range data
 * Input range data is an object of below structure
 * rangeData = {
    "activeMinutes": [
        {
            "metric_name": "pulse_check__count__tracked_any_active_minutes",
            "metric_value": "1.000",
            "metric_type": "daily",
            "for_date": "2021-05-06",
            "created_at": "2021-05-06T20:16:19.076290Z",
            "lastmodified_at": "2021-05-19T05:55:37.238552Z"
        },
    ],
    "moveMinutes": [
        {
            "metric_name": "pulse_check__count__tracked_any_move_minutes",
            "metric_value": "1.000",
            "metric_type": "daily",
            "for_date": "2021-05-06",
            "created_at": "2021-05-06T20:16:19.049780Z",
            "lastmodified_at": "2021-05-19T05:55:37.055374Z"
        },
    ],
    "countMembers": [
        {
            "metric_name": "pulse_check__count__members",
            "metric_value": "10.000",
            "metric_type": "all_time",
            "for_date": "2021-05-06",
            "created_at": "2021-05-06T00:16:18.472533Z",
            "lastmodified_at": "2021-05-06T23:16:18.376728Z"
        },
    ],
    "steps": [
        {
            "metric_name": "pulse_check__count__tracked_any_steps",
            "metric_value": "2.000",
            "metric_type": "daily",
            "for_date": "2021-05-06",
            "created_at": "2021-05-06T20:16:19.102366Z",
            "lastmodified_at": "2021-05-19T05:55:37.429235Z"
        },
    ],
    "streakBadge": [
        {
            "metric_name": "pulse_check__count__completed_any_streak_badges",
            "metric_value": "0.000",
            "metric_type": "daily",
            "for_date": "2021-05-06",
            "created_at": "2021-05-06T20:16:19.128563Z",
            "lastmodified_at": "2021-05-19T05:55:37.657347Z"
        },
    ]
  }
 *
 * return finalValues = { "activeMinutes": "6%", "moveMinutes": "6%", "steps": "5%", "streakBadge": "1%"}
 */
const calculateOrganizationHeaderData = (rangeData) => {
  const finalValues = {};
  try {
    /* Get all the data by day */
    const rangeDataByDate = {};
    const previousRangeDataByDate = {};

    Object.keys(rangeData).forEach(type => {
      rangeDataByDate[type] = {};
      rangeData[type].forEach(d => {
        rangeDataByDate[type][d.for_date] = parseInt(d.metric_value, 10) || 0;;
      });
    });

    const countOfMembersPerDay = rangeDataByDate[Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.countMembers];
    Object.keys(rangeDataByDate).forEach(type => {
      /* Count members is not a header. Instead it is used to calculate for every header. So skipping it */
      if (type === Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.countMembers) {
        return;
      }

      const current = rangeDataByDate[type];
      const percentagePerDay = Object.keys(current).map(day => {

        // Do we have a non-zero denominator? (to avoid division by zero)
        if (countOfMembersPerDay[day]) {

          // Make sure that we don't get more than 100% (the backend slidding
          // window function may make the current[day] value larger than the
          // count of members for that day)
          const numerator = Math.min(current[day], countOfMembersPerDay[day])
          const denominator = countOfMembersPerDay[day]
          return numerator / denominator;
        }

        return 0
      });

      const sumOfPercentagePerDay = percentagePerDay.reduce((sum, currentPercentage) => (sum + currentPercentage), 0);
      const currentRangeValuePercentage = percentagePerDay.length ? Math.round(((sumOfPercentagePerDay / percentagePerDay.length) || 0) * 100) : 0;

      finalValues[type] = currentRangeValuePercentage;
    });
  } catch (ex) {
    console.error('Error in calculating Organization header data', ex);
  }
  return finalValues;
};

/**
 * Prepares Graph data. Fills in the dates with no data as 0
 * Keeps the allDates(labels from start to end date) in memory so future generation is faster
 * @param startDate String "2021-05-16"
 * @param endDate  String  "2021-05-20"
 * @param data object
 *
 *  [
 *     {
 *         "metric_name": "pulse_check__count__app_opens",
 *         "metric_value": "4.000",
 *         "metric_type": "daily",
 *         "for_date": "2021-05-16",
 *         "created_at": "2021-05-16T20:16:18.825534Z",
 *         "lastmodified_at": "2021-05-16T20:16:18.834336Z"
 *     }
 *   ]
 */
const allDateCache = {};
const allLabelCache = {};
const getGraphDataForMetrics = (startDate, endDate, data) => {
  try {
    /* Check for cache if the full dates are already calculated */
    const cacheKey = `${startDate}-${endDate}`;
    let allDates = allDateCache[cacheKey];
    let allLabels = allLabelCache[cacheKey];
    /* Generate All dates with in the start and end date */
    if (!allDates) {
      allDates = [];
      allLabels = [];
      let loopStartDate = parse(startDate, 'yyyy-MM-dd', new Date(startDate));
      let loopEndDate = parse(endDate, 'yyyy-MM-dd', new Date(endDate));
      let loopDate = loopStartDate;
      while (!isAfter(loopDate, loopEndDate)) {
        allDates.push(formateDateToYearMonthDay(loopDate));
        allLabels.push(formateDate(loopDate, 'MM/dd'));
        loopDate = addDays(loopDate, 1);
      }
      allDateCache[cacheKey] = allDates;
      allLabelCache[cacheKey] = allLabels;
    }


    const dataByDates = {};
    data.forEach(d => {
      dataByDates[d.for_date] = parseInt(d.metric_value, 10) || 0;
    });

    const dataWithParsedValues = [];
    allDates.forEach(dte => {
      dataWithParsedValues.push({
        y: dataByDates[dte] || 0,
        x: formateDate(parse(dte, 'yyyy-MM-dd', new Date(dte)), 'MM/dd'),
      });
    });

    return {
      labels: allLabels,
      values: dataWithParsedValues,
    };
  } catch (ex) {
    console.error(ex);
  }

  return {
    labels: [],
    values: [],
  };
}

export default {
  getDelta,
  formatDate,
  getGraphDataForMetrics,
  formateDateToYearMonthDay,
  getSumForMetricType,
  getAvgForMetricType,
  /* Organization Activity Breakdown */
  getRow,
  getSortIndicator,
  getSortColHeaderButton,
  calculateOrganizationHeaderData,
  sortOrganizationActiviytBreakdownData,
};
