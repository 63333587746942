import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { LANDING } from "../constants/subpage";
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from '~/src/context/organizationContext';
import GroupContext from "~/src/context/groupContext";
import CTA from "~/src/components/ctaBox";
import ToolTip from "../components/Tooltip";
import Assets from '~/src/constants/assets';

const AddGroup = (props) => {
  const { t, i18n } = useTranslation();
  const [inProgress, setInProgress] = React.useState(false);
  const { handleAuthError, setToastMsg } = React.useContext(AuthContext);
  const { resetGroupData, groups } = React.useContext(GroupContext);
  const { selectedOrganization } = React.useContext(OrganizationContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t("groups.name_Required")),
      description: Yup.string()
    }),
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      const orgId = groups[0].organization
      const requestData = {
        name: values.name,
        description: values.description,
        organization_id: orgId
      };
      makeAPIcall(reverse('api:group_create'), POST, createGroupResultHandler, requestData);
    }
  });

  const createGroupResultHandler = async (error, result) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      setToastMsg(t("groups.group_added"), false);
      resetGroupData();
      props.updateCurrentPage(LANDING);
    }
  };

  return (
    <Col xs={12} md={6} className="d-flex add-group-page-container justify-content-center">
      <CTA
        text={t('groups.back_to_manage_group')}
        onClick= {()=> {props.updateCurrentPage(LANDING)}}
      >
        <div className="group-add-edit-form-container">
          <div className="profile-form-container">
            <Form onSubmit={formik.handleSubmit}>
              <p className="group-form-title">{t("groups.add_a_group")}</p>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="input-label">{t("groups.group_name")}<span>*</span></Form.Label>
                <Form.Control
                  placeholder={t("groups.group_name_placeholder")}
                  type="text"
                  name="name"
                  disabled={inProgress}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className={formik.touched.name && formik.errors.name && "highlight-input"}
                />
                { formik.touched.name && formik.errors.name ?
                  (
                    <p className="form-error-text">{formik.errors.name}</p>
                  ) : null
                }
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label className="input-label">
                  {t("groups.group_description")}
                  <ToolTip text={t("groups.group_description_tooltip")} />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  rows={4}
                  disabled={inProgress}
                  placeholder={t("groups.montreal_office_description_placeholder")}
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  className={formik.touched.description && formik.errors.description && "highlight-input"}
                />
                {
                  formik.touched.description && formik.errors.description ?
                    (
                      <p className="form-error-text">{formik.errors.description}</p>
                    ) : null
                }
              </Form.Group>

              <Form.Row>
                <Col sm={12}>
                  {
                    formik.submitCount > 0 && !formik.isValid ?
                      <span className="form-final-error-text">
                        <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon"/>
                        {t("common.correct_errors_above")}
                      </span>
                      : null
                  }
                </Col>
                <Col sm={12}>
                  <Button
                    disabled={inProgress}
                    variant="primary"
                    type="submit"
                    className="button-purple"
                  >
                    {t("groups.add_group")}
                    {
                      inProgress &&
                      <Spinner animation="border" role="status">
                        <span className="sr-only">{t("dashboard.loading")}</span>
                      </Spinner>
                    }
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </div>
        </div>
      </CTA>
    </Col>
  );
};

export default AddGroup;
