import React from 'react';
import {
  Col,
  Row,
} from 'react-bootstrap';

import './style.scss';

const Section = React.memo(({
  title,
  subTitle,
  actions,
  children,
  className,
}) => (
  <div className={`section-container ${className}`}>
    <Row className="header">
      <Col xs={12} md={9} className="order-1 order-md-0">
        <div className="section-title">{title}</div>
        <div className="section-sub-title">
          {subTitle}
        </div>
      </Col>
      {
        actions &&
        <Col xs={12} md={3} className="text-right">
        {actions}
        </Col>
      }
    </Row>
    <Row className="content-container">
      {children}
    </Row>
  </div>
));

export default Section;
