import React  from "react";
import { useTranslation } from "react-i18next";
import { reverse } from '~/src/api/urls';
import { Row, Col } from "react-bootstrap";
import CTA from "~/src/components/ctaBox";

import './style.scss';

const NotFoundPage = () => {
  const { t, i18n } = useTranslation();

  React.useState(() => {
    window.setWindowTitle(t('common.404_title'));
  }, []);

  return (
    <Row className="not-found-page justify-content-center">
      <Col xs={12} md={5}>
        <CTA
          text={t("common.return_home")}
          link={reverse('app:home_page')}
        >
          <div className="not-found-container">
            <h3 className="not-found-title">{t("common.404_tiitle_oops")}<br/>{t("common.404_title")}</h3>
            <p className="not-found-describe">{t("common.404_desc")}</p>
          </div>
        </CTA>
      </Col>
    </Row>
  );
};

export default NotFoundPage;
