export const URL_BASE_NAME = 'dashboard/plus/';
export const PAC_EMAIL = 'plus_support@participaction.com';

export const PROVIDER_MAPPING = {
  1: 'Google',
  2: 'Apple',
  3: 'Facebook',
  // 4: 'Cognito',
};

export const LEGAL_FR = "https://www.participaction.com/fr-ca/info/conditions-de-vente";
export const LEGAL_EN = "https://www.participaction.com/en-ca/info/terms-of-sale";


export const CONTACT_US_INFO = {
  STEP1_en: "mailto:plus_support@participaction.com?subject=ParticipACTION%2B%20Onboarding%20Support%20Request&body=Help!%20I%20am%20looking%20for%20support%20with%20managing%20my%20ParticipACTION%2B%20subscription%2C%20specifically%20assistance%20with%3A",
  STEP1_fr: "mailto:plus_support@participaction.com?subject=Demande%20de%20soutien%20%C3%A0%20l'implantation%20de%20ParticipACTION%2B&body=Besoin%20d'aide!%20J'ai%20besoin%20de%20soutien%20pour%20g%C3%A9rer%20mon%20abonnement%20ParticipACTION%2B%2C%20et%20j'ai%20pr%C3%A9cis%C3%A9ment%20besoin%20d'aide%20pour%3A",
  STEP2_ENT_en: "mailto:plus@participaction.com?subject=ParticipACTION%2B%20Subscription%20Inquiry&body=I%20am%20interested%20in%20getting%20my%20organization%20started!%20We%20are%20500%2B%20members%2C%20and%20I%20am%20inquiring%20regarding%20special%20enterprise%20pricing.", 
  STEP2_ENT_fr: "mailto:plus@participaction.com?subject=Demande%20d'abonnement%20%C3%A0%20ParticipACTION%2B&body=Je%20voudrais%20commencer%20le%20processus%20pour%20mon%20organisation!%20Nous%20avons%20plus%20de%20500%20membres%2C%20et%20je%20me%20demande%20s'il%20y%20a%20des%20prix%20sp%C3%A9ciaux%20pour%20les%20entreprises.",
  MANAGE_ORG_en: "mailto:plus_support@participaction.com?subject=ParticipACTION%2B%20Subscription%20Update&body=I%20am%20looking%20for%20support%20with%20managing%20an%20existing%20ParticipACTION%2B%20subscription.%20%20I%20would%20like%20assistance%20with%3A%20%0Aa)%20changing%20my%20plan%20size%0Ab)%20cancelling%20automatic%20subscription%20renewal%20%0Ac)%20other%20change",
  MANAGE_ORG_fr: "mailto:plus_support@participaction.com?subject=Mise%20%C3%A0%20jour%20de%20l'abonnement%20ParticipACTION%2B&body=J'ai%20besoin%20de%20soutien%20pour%20g%C3%A9rer%20un%20abonnement%20ParticipACTION%2B%20existant.%20J'ai%20besoin%20d'aide%20pour%20%3A%20%0Aa)%20changer%20la%20taille%20de%20mon%20plan%0Ab)%20annuler%20le%20renouvellement%20automatique%20de%20l'abonnement%20%0Ac)%20d'autres%20changements",
  FOOTER_en: "mailto:plus_support@participaction.com?subject=ParticipACTION%2B%20Client%20Portal%20Support&body=Help!%20I%20am%20looking%20for%20support%20with%20ParticipACTION%2B%2C%20specifically%20assistance%20with%3A",
  FOOTER_fr: "mailto:plus_support@participaction.com?subject=Soutien%20pour%20le%20portail%20client%20de%20ParticipACTION%2B&body=Besoin%20d'aide!%20J'ai%20besoin%20de%20soutien%20pour%20ParticipACTION%2B%2C%20et%20plus%20pr%C3%A9cis%C3%A9ment%20pour%20%3A"
}
