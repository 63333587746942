import React, {useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Spinner
} from 'react-bootstrap';
import DomToImage from 'dom-to-image';
import Slider from "react-slick";
import AuthContext from "~/src/context/authContext";
import ChallengeReportContext from "~/src/context/challengeReportContext";
import AboutChallenge from '../components/aboutChallenge';
import CBCTopSection from './cbcTopSection';
import AugTopSection from './augTopSection';
import GBMTopSection from './gbmTopSection';
import MFYMTopSection from './mfymTopSection';
import DECTopSection from './decTopSection';
import TrueNorthTopSection from './trueNorthTopSection';
import DefaultTopSection from './defaultTopSection';
import TableBreakdownSection from './tableBreakdownSection'
import ParticipationCard from '../components/participationCard';
import BreakdownTable from './breakdownTable';
import CounterCard from '../components/counterCard';
import Assets from '~/src/constants/assets';
import Utils from '~/src/challengeReport/utils';

import Constants from '~/src/constants/challengeReport';
import { CHALLENGE_REPORT_LANDING_PAGE } from "~/src/constants/subpage";
import { pushToPageLayer } from '~/src/utils/GA';

import './style.scss';
import AprilTopSection from './aprilTopSection';
import MazdaTopSection from './mazdaTopSection';

const Home = () => {
  const { t, i18n } = useTranslation();

  const { carouselList, reportsObject, currentCarousel, onCardSwitch,
    loader, setCurrentCarousel, selectedGroup, setSelectedGroup,
    teamSort, groupSort, setTeamSort, setGroupSort, newChallenge, setNewChallenge } = React.useContext(ChallengeReportContext);

  const { setInitialHeader } = React.useContext(AuthContext);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [list, setList] = React.useState(Constants.NAV_BAR);
  const currentReport = reportsObject && reportsObject[currentCarousel];
  const currentCard = carouselList && carouselList.find(data => data.pk === currentCarousel);

  // Slider Reference
  const slideRef = useRef(null)

  // TBD: Hardcoded Challenge name for filter purpose recommended by Zelimer F. 
  const exclude_challenge_list = ['fall challenge (test)', 'True North', 'Fall Challenge 2020', 'Testing challenge', 'DEC_2022', 'TRUENORTH_2023'];
  const finalCarouselList = carouselList?.filter(d => !exclude_challenge_list.includes(d.name)) || [];

  React.useEffect(() => {
    setTimeout(() => {
      if (slideRef.current && newChallenge !== null) {
        if(currentCarousel === newChallenge.pk) {
          slideRef.current.slickGoTo(finalCarouselList.findIndex(e => e.pk === newChallenge.pk) || 0);
        }
      }
    }, 1000);
  }, [currentCarousel]);

  React.useEffect(() => {
    // To set the header component
    setInitialHeader({ mainHeader: CHALLENGE_REPORT_LANDING_PAGE, subHeader: null });
    window.setWindowTitle(t('common.challenge_report_menu'));
    pushToPageLayer(t('common.challenge_report_menu'));
  }, []);

  const settings = {
    speed: 500,
    slidesToShow: 6,
    dots: false,
    infinite: false,
    focusOnSelect: true,
    arrows: true,
    className: 'slides',
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const isCardDisabled = (data) => {
    const available_at = new Date(data['available_at'])
    const launch_at = new Date(data['launch_at'])
    const currentDate = new Date()
    if(currentDate >= available_at && currentDate <= launch_at) {
      return false
    }else {
      const challengeId = data.pk;
      const challengeIdList = Object.keys(reportsObject);
      const details = challengeIdList.includes(challengeId) ? (reportsObject[challengeId] || {}) : {};
      return Object.keys(details).length === 0 ? true : false;
    }
  }

  const isCardSelected = (data) => {
    return data.pk === currentCarousel;
  }

  const dateFormat = (data) => {
    const create = new Date(data['launch_at']);
    const end = new Date(data['end_at']);
    if (isCardDisabled(data)) {
      return Utils.formatDate(create, 'MMMM yyyy')
    } else {
      // June 1 - 30, 2021
      let start_date = Utils.formatDate(create, 'MMMM d');
      let end_date = Utils.formatDate(end, 'MMMM d');
      const year = Utils.formatDate(end, 'yyyy');

      const start_date_month = Utils.formatDate(create, 'MMMM');
      const end_date_month = Utils.formatDate(end, 'MMMM');

      if (start_date_month === end_date_month) {
        start_date = Utils.formatDate(create, 'd');
        end_date = Utils.formatDate(end, 'd');
        // June 1 - July 30, 2021
        return `${start_date_month} ${start_date} - ${end_date}, ${year}`;
      }
      // June 1 - 30, 2021
      return `${start_date} - ${end_date}, ${year}`;
    }
  }

  isReportObjectEmpty = () => {
    if (Object.keys(reportsObject || {}).length &&  Object.keys(reportsObject || {}).length == (carouselList ? carouselList.length : 0)) {
      if (Object.values(reportsObject || {}).some((data) => Object.keys(data).length !== 0)) {
        return false;
      }
      return true;
    }
    return false
  }

  const navSection = (noData) => {
    return (
      <>
        <div className={"nav-section"}>
          <Slider {...settings} ref={slideRef}>
            {carouselList.map((data, index) => {
              if (!exclude_challenge_list.includes(data.name)) {
                return(
                  <Button
                    variant="link"
                    key={index}
                    className={`${isCardDisabled(data) ? 'nav-card-nonclickable' : 'nav-card'} ${isCardSelected(data) ? 'selected' : ''}`}
                    onClick={() => {
                        pushToPageLayer(`${t('common.challenge_report_menu')} | ${t(Constants.NAME_MAP[data.name].title)}`);
                        setCurrentCarousel(data.pk);
                      }
                    }
                  >
                    {Constants.IMAGE_MAP[data.name] &&
                      <div className="image-div">
                        <img
                          className="card-icon"
                          src={Constants.IMAGE_MAP[data.name][i18n.language]}
                        />
                      </div>
                    }
                    <div className="date">{Constants.DATE_MAP[data.name] && t(Constants.DATE_MAP[data.name].dates) || dateFormat(data)}</div>
                    {!isCardDisabled(data) && <Button 
                      className={` dot ${isCardSelected(data) ? 'selected' : ''}`}
                      onClick={() => { 
                          pushToPageLayer(`${t('common.challenge_report_menu')} | ${t(Constants.NAME_MAP[data.name].title)}`)
                          setCurrentCarousel(data.pk); 
                        }
                      }
                    />}
                  </Button>
                )
              }
            })
            }
          </Slider>
        </div>
        {noData &&
          <div className={'no-data-section'}>{t('challenge_report.no_data')}</div>
        }
      </>
    );
  }

  if (isReportObjectEmpty()) {
    return (
      <Row className="challenge-report-page-home">
        <Col xs={12} className={'challenge-section'}>
          {navSection(true)}
        </Col>
      </Row>
    );
  }

  const checkChallengeNotStarted = (data) => {
    let isChallengeNotStarted = false
    const launch_at = new Date(data['launch_at'])
    const available_at = new Date(data['available_at'])
    const currentDate = new Date()
    if(currentDate >= available_at && currentDate <= launch_at) {
      isChallengeNotStarted = true
    }else {
      isChallengeNotStarted = false
    }
    return isChallengeNotStarted
  }

  renderBody = (name) => {
    switch (name) {
      case "CBC_2021":
        return (
          <>
            <CBCTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "AUG_2021":
        return (
          <>
            <AugTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "GBM_2021":
        return (
          <>
            <GBMTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "DEC_2021":
        return (
          <>
            <DECTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "TRUENORTH_2022":
        return (
          <>
            <TrueNorthTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "APR_2022":
        return (
          <>
            <AprilTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        )
      case "APR_2023":
        return (
          <>
            <AprilTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        )
      case "CBC_2022":
        return (
          <>
            <CBCTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "CBC_2023":
        return (
          <>
            <CBCTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "AUG_2022":
        return (
          <>
            <MazdaTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "GBM_2022":
        return (
          <>
            <GBMTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
        break;
      case "MFYM_2023":
        return (
          <>
            <MFYMTopSection
              currentCard={currentCard}
              currentReport={currentReport}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
            />
          </>
        );
      case "default_page":
        return (
          <>
            <DefaultTopSection
              currentCard={currentCard}
              currentReport={currentReport}
              isChallengeNotStarted={currentCard && checkChallengeNotStarted(currentCard)}
            />
            <TableBreakdownSection
              currentReport={currentReport}
              challengeName={name}
              isChallengeNotStarted={currentCard && checkChallengeNotStarted(currentCard)}
            />
          </>
        );
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Row className="challenge-report-page-home">
        {loader || !currentReport || !currentCard ? (
          <Col xs={12} className={"challenge-section-spinner"}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        ) : (
          <Col xs={12} className={"challenge-section"}>
            {navSection(false)}
            <Col className="page-title-container">
              <Row>
                <Col xs={12} lg={9} sm={8} className="title-section">
                  <div className="title">
                    {t(
                      Constants.NAME_MAP[currentCard.name] &&
                        Constants.NAME_MAP[currentCard.name].title
                    )}
                  </div>
                  <div className="sub-title">
                    {t(
                      Constants.NAME_MAP[currentCard.name] &&
                        Constants.NAME_MAP[currentCard.name].subtitle
                    )}
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={3}
                  sm={4}
                  className={(currentCard && checkChallengeNotStarted(currentCard) ? "add-opacity-for-upcoming-challenge print-page-container d-flex align-items-end justify-content-end" : "print-page-container d-flex align-items-end justify-content-end")}
                >
                  <Button
                    disabled={isPrinting}
                    variant="light"
                    onClick={() => {
                      setIsPrinting(true);

                      const nodeToPrint = document.querySelector(
                        "#app > .page-container"
                      );
                      DomToImage.toPng(nodeToPrint, {
                        width: nodeToPrint.scrollWidth,
                        height: nodeToPrint.scrollHeight,
                        filter: (node) => {
                          if (
                            node.classList &&
                            node.classList.contains("print-page-container")
                          ) {
                            return false;
                          }
                          return true;
                        },
                      })
                        .then(function (dataUrl) {
                          const link = document.createElement("a");
                          link.download = `participActionPlus-${t(
                            "common.challenge_report_menu"
                          )}.png`;
                          link.href = dataUrl;
                          link.click();
                          setIsPrinting(false);
                        })
                        .catch(function (error) {
                          console.error(error);
                          window.alert(t("dashboard.print_failed"));
                          setIsPrinting(false);
                        });
                    }}
                    className="grey-button-outline print-page-button"
                  >
                    <img src={Assets.PrinterIcon} /> {t("dashboard.print")}
                    {isPrinting && (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>

            {currentCard && checkChallengeNotStarted(currentCard) ? renderBody('default_page') : renderBody(currentCard.name)}
          </Col>
        )}
      </Row>
      {currentCard && checkChallengeNotStarted(currentCard) && (
        <div className="modal-preChallenge">
          <div className="modal-image-container">
            <img src={Assets.WARNING} title="warning" />
          </div>
          <div className="modal-header-text">
            <p>{t("challenge_report.no_data_title")}</p>
          </div>
          <div className="modal-desc">
            <p>{t("challenge_report.no_data_description")}</p>
          </div>
        </div>
      )}
    </>
  );
};


Home.defaultProps = {

};

export default Home;
