import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import Assets from '~/src/constants/assets';

import './style.scss';

function ModalBox(props) {
  const {
    showicon,
    exclamationicon,
    onHide,
    messageIcon,
    style,
    dialogClassName,
    ...restProps
  } = props;

  return (
    <Modal
      {...restProps}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-container"
      dialogClassName={dialogClassName}
      backdrop="static"
    >
      {/* <div className="modal-close-icon-area"> */}
        <img
          className="modal-close-icon"
          src={Assets.CloseRoundIcon}
          alt="modal-close-icon"
          // style={{ height: 30, width: 30, cursor: "pointer", position: "absolute", right: 20 }}
          onClick={onHide}
        />
      {/* </div> */}
      <Modal.Body>
        <div
          className="modal-message-icon-area"
          style={{ textAlign: 'center', marginBottom: 10 }}
        >
          {showicon && (
            <img
              style={style}
              alt="modal-message-icon"
              src={exclamationicon ? Assets.ExclamationTriangleIcon : messageIcon ? Assets.UserMailIcon  : Assets.GreenRoundCheckIcon}
              className={exclamationicon ? 'show-icon-exclamation' : messageIcon ? 'show-icon-message'  : 'show-icon'}
            />
          )}
        </div>
        {/* {modalContent} */}
        {props.children}
      </Modal.Body>
    </Modal>
  );
}

export default ModalBox;

ModalBox.defaultProps = {
  show: true,
};
