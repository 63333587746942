import React from 'react'; 
import { useTranslation } from 'react-i18next';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subWeeks from 'date-fns/subWeeks';

import { reverse } from "~/src/api/urls";
import { GET, POST, makeAPIcall } from "~/src/api/utils";
import { isAuthRelatedError } from "~/src/api/errors";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from '~/src/context/organizationContext';
import Constants from '~/src/constants/dashboard';
import Utils from '~/src/dashboard/utils';

const DashboardContext = React.createContext({});

const tempStore = {};

const DashboardContextWrapper = (props) => {

  const {
    handleAuthError,
  } = React.useContext(AuthContext);

  const {
    selectedOrganization,
    organizationList
  }  = React.useContext(OrganizationContext)

  const { invite_admin, create_group, launch, promote, join_team } = organizationList;

  const { t } = useTranslation();

  // stores all the data from API for all time metrics
  const [allTimeMetrics, setAlltimeMetrics] = React.useState({});
  const [graphActiveChallenges, setGraphActiveChallenges] = React.useState({});
  const [graphAverageMoveMinutes, setGraphAverageMoveMinutes] = React.useState({});
  const [graphAppUserUsage, setGraphAppUserUsage] = React.useState([]);
  const [totalOrgMemeber, setTotalOrgMember] = React.useState('');

  const [orgActivityBreakdownHeader, setOrgActivityBreakdownHeader] = React.useState({});
  const [orgActivityBreakdown, setOrgActivityBreakdown] = React.useState({
    sortCol: Constants.PULSE_CHECK_KEY.MOVE_MINUTES.SUM, /* Default sort col */
    sortMode: 'desc', /* Default sort order */
    data: [],
    orgTotals: {
      name: t('dashboard.org_total'),
    },
  });
  const [dateRange, setDateRange] = React.useState({
    ...Constants.TIME_OPTION[0],
    label: t(Constants.TIME_OPTION[0].label),
  });


  const getGraphActiveChallenges = (
    startDate,
    endDate,
    previousRangeStart,
    previousRangeEnd,
    currentToken,
  ) => {
    let data = {};
    setGraphActiveChallenges({});
    const checkForDataCompletion = () => {
      // console.log('Token: getGraphActiveChallenges - Processing Current token out', currentToken);
      if (data.currentRange && data.previousRange && currentToken === tempStore.dateBasedCallsToken) {
        // console.log('Token: getGraphActiveChallenges - Processing Current token in', currentToken);
        const currentChallengesCount = data.currentRange.reduce((sum, obj) => sum + parseInt(obj.value), 0);
        const previousChallengesCount = data.previousRange.reduce((sum, obj) => sum + parseInt(obj.value), 0);

        setGraphActiveChallenges({
          currentChallengesCount,
          previousChallengesCount,
          ...Utils.getDelta(currentChallengesCount, previousChallengesCount),
        });
      }
    };

    makeAPIcall(
      reverse(
        'api:dashboard:active_challenges',
        selectedOrganization.pk,
        startDate,
        endDate,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          if (result.payload) {
            data.currentRange = result.payload;
            checkForDataCompletion();
          }
        }
      }
    );

    makeAPIcall(
      reverse(
        'api:dashboard:active_challenges',
        selectedOrganization.pk,
        previousRangeStart,
        previousRangeEnd,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          if (result.payload) {
            data.previousRange = result.payload;
            checkForDataCompletion();
          }
        }
      }
    );
  };

  const getGraphAppUserUsage = (startDate, endDate, currentToken) => {
    setGraphAppUserUsage([]);
    makeAPIcall(
      reverse(
        'api:dashboard:graph_app_user_usage',
        selectedOrganization.pk,
        startDate,
        endDate,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          // console.log('Token: getGraphAppUserUsage - Processing Current token out', currentToken);
          if (result.payload && currentToken === tempStore.dateBasedCallsToken) {
            // console.log('Token: getGraphAppUserUsage - Processing Current token in', currentToken);
            const data = result.payload;

            const {
              values,
            } = Utils.getGraphDataForMetrics(startDate, endDate, data);

            setGraphAppUserUsage(values);
          }
        }
      }
    );
  };

  const getGraphAverageMoveMinutes = (
    startDate,
    endDate,
    previousRangeStart,
    previousRangeEnd,
    currentToken,
  ) => {
    let data = {};
    setGraphAverageMoveMinutes({});
    const checkForDataCompletion = () => {
      // console.log('Token: getGraphAverageMoveMinutes - Processing Current token out', currentToken);
      if (data.currentRange && data.previousRange && currentToken === tempStore.dateBasedCallsToken) {
        // console.log('Token: getGraphAverageMoveMinutes - Processing Current token in', currentToken);
        const currentAvgMoveMinutes = Math.round((data.currentRange.reduce((sum, obj) => sum + parseInt(obj.metric_value, 10), 0) / data.currentRange.length) || 0);
        const previousAvgMoveMinutes = Math.round((data.previousRange.reduce((sum, obj) => sum + parseInt(obj.metric_value, 10), 0)  / data.previousRange.length) || 0);

        const {
          labels: graphLabels,
          values: graphData,
        } = Utils.getGraphDataForMetrics(startDate, endDate, data.currentRange);

        setGraphAverageMoveMinutes({
          currentAvgMoveMinutes,
          previousAvgMoveMinutes,
          ...Utils.getDelta(currentAvgMoveMinutes, previousAvgMoveMinutes),
          graphLabels,
          graphData,
        });
      }
    };

    makeAPIcall(
      reverse(
        'api:dashboard:graph_avg_minutes',
        selectedOrganization.pk,
        startDate,
        endDate,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          if (result.payload) {
            data.currentRange = result.payload;
            checkForDataCompletion();
          }
        }
      }
    );

    makeAPIcall(
      reverse(
        'api:dashboard:graph_avg_minutes',
        selectedOrganization.pk,
        previousRangeStart,
        previousRangeEnd,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          if (result.payload) {
            data.previousRange = result.payload;
            checkForDataCompletion();
          }
        }
      }
    );
  };

  const getAllTimeMetricsData = async () => {
    if (selectedOrganization && selectedOrganization.pk) {
      setAlltimeMetrics({});
      /* call `All time metric apis and set to context` */
      const allTimeMetricUrlMap = {
        [Constants.ALL_TIME_METRCS.organizationMembers]: 'api:dashboard:all_time_metrics_total_org_members',
        [Constants.ALL_TIME_METRCS.organizationTeam]: 'api:dashboard:all_time_metrics_pulse_check__count__teams',
        [Constants.ALL_TIME_METRCS.moveMinutes]: 'api:dashboard:all_time_metrics_avg_move_per_member',
        [Constants.ALL_TIME_METRCS.withFitnessTrackers]: 'api:dashboard:all_time_metrics_user_with_fitness_trackers',
      };

      let newData = {};
      const metricsResultHandler = (type) => {
        return (error, result) => {
          if (error) {
            if (isAuthRelatedError(result)) {
              handleAuthError(result);
            }
          } else {
            if (result.payload) {
              newData[type] = result.payload;
            }
          }

          if (Object.keys(newData).length === 4) {
            const totalOrgMembers = parseInt(newData[Constants.ALL_TIME_METRCS.organizationMembers].metric_value, 10);
            setTotalOrgMember(totalOrgMembers);
            setAlltimeMetrics(newData);
          }
        };
      };

      Object.keys(allTimeMetricUrlMap).forEach(metricName =>
        makeAPIcall(
          reverse(
            allTimeMetricUrlMap[metricName],
            selectedOrganization.pk,
          ),
          GET,
          metricsResultHandler(metricName),
      ));
    }
  };

  const getOrgActivityBreakDownHeader = (
    startDate,
    endDate,
    previousRangeStart,
    previousRangeEnd,
    currentToken,
  ) => {
    setOrgActivityBreakdownHeader({});
    const totalOrgMembers = parseInt(allTimeMetrics[Constants.ALL_TIME_METRCS.organizationMembers].metric_value, 10);
    const currentRangeData = {};
    const previousRangeData = {};

    const rangeUrlMapping = {
      [Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.countMembers]: 'api:dashboard:organization_ativity_breakdown_header_count_members',
      [Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.moveMinutes]: 'api:dashboard:organization_ativity_breakdown_header_move_minutes',
      [Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.activeMinutes]: 'api:dashboard:organization_ativity_breakdown_header_active_minutes',
      [Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.steps]: 'api:dashboard:organization_ativity_breakdown_header_steps',
      [Constants.ORGANIZATION_ACTIVITY_BREAKDOWN_HEADER_TYPES.streakBadge]: 'api:dashboard:organization_ativity_breakdown_header_streak',
    };

    const checkForDataCompletion = () => {
      const totalCalls = Object.keys(rangeUrlMapping).length;
      const currentRangeDataLength = Object.keys(currentRangeData).length;
      const previousRangeDataLength = Object.keys(previousRangeData).length;
      // console.log('Token: getOrgActivityBreakDownHeader - Processing Current token out', currentToken);

      if (currentRangeDataLength === totalCalls &&
          previousRangeDataLength === totalCalls &&
          currentToken === tempStore.dateBasedCallsToken) {
          const currentRangePercentages = Utils.calculateOrganizationHeaderData(currentRangeData);
          const previousRangePercentages = Utils.calculateOrganizationHeaderData(previousRangeData);

          const finalData = {};
          Object.keys(currentRangePercentages).forEach(type => {
            finalData[type] = {
              current: currentRangePercentages[type],
              previous: previousRangePercentages[type],
            }

            /* only if we have some previous data then show delta */
            if (finalData[type].previous > 0) {
              finalData[type].delta = finalData[type].current - finalData[type].previous;
              finalData[type].deltaSign = finalData[type].delta > 0 ? 1 : -1;
              finalData[type].delta = `${Math.abs(finalData[type].delta)}%`;
            }
            // value should be in %
            finalData[type].current = `${finalData[type].current}%`;
          });

          setOrgActivityBreakdownHeader(finalData);
      }
    };

    Object.keys(rangeUrlMapping).forEach(key => {
      /* Current range API calls */
      makeAPIcall(
        reverse(
          rangeUrlMapping[key],
          selectedOrganization.pk,
          startDate,
          endDate,
        ),
        GET,
        (error, result) => {
          if (error) {
            if (isAuthRelatedError(result)) {
              handleAuthError(result);
            }
          } else {
            if (result.payload) {
              currentRangeData[key] = result.payload;
            }
            checkForDataCompletion();
          }
      });

      /* Previous range API calls */
      makeAPIcall(
        reverse(
          rangeUrlMapping[key],
          selectedOrganization.pk,
          previousRangeStart,
          previousRangeEnd,
        ),
        GET,
        (error, result) => {
          if (error) {
            if (isAuthRelatedError(result)) {
              handleAuthError(result);
            }
          } else {
            if (result.payload) {
              previousRangeData[key] = result.payload;
            }
            checkForDataCompletion();
          }
      });
    });
  };

  const getOrgActivityBreakDown = (startDate, endDate, currentToken) => {
    setOrgActivityBreakdown({});
    makeAPIcall(
      reverse(
        'api:dashboard:organization_ativity_breakdown',
        selectedOrganization.pk,
        startDate,
        endDate,
      ),
      GET,
      (error, result) => {
        if (error) {
          if (isAuthRelatedError(result)) {
            handleAuthError(result);
          }
        } else {
          // console.log('Token: getOrgActivityBreakDown - Processing Current token out', currentToken);
          if (result.payload && currentToken === tempStore.dateBasedCallsToken) {
            // console.log('Token: getOrgActivityBreakDown - Processing Current token in', currentToken);
            const data = result.payload;
            let orgTotals = {
              name: t('dashboard.org_total'),
            };
            const tempData = {};

            data.forEach((d, idx) => {
              const currentData = {
                name: d.object_name,
              };

              currentData[d.metric_name] = {
                valueAvg: d.value_avg,
                valueSum: d.value_sum,
              };

              if (d.object_type === 'organization') {
                orgTotals = {
                  ...orgTotals,
                  ...currentData,
                };
              } else {
                tempData[currentData.name] = {
                  ...(tempData[currentData.name] || {}),
                  ...currentData,
                };
              }
            });

            const newData = Utils.sortOrganizationActiviytBreakdownData(
               Object.values(tempData),
               orgActivityBreakdown.sortCol,
               orgActivityBreakdown.sortMode,
            );
            setOrgActivityBreakdown({
              ...orgActivityBreakdown,
              data: newData,
              orgTotals,
            });
          }
        }
      },
    );
  };

  /* on sorting order change, sort the data and send it to the components */
  React.useEffect(() => {
    if (orgActivityBreakdown.data && orgActivityBreakdown.data.length) {
      const newData = Utils.sortOrganizationActiviytBreakdownData(
        orgActivityBreakdown.data,
        orgActivityBreakdown.sortCol,
        orgActivityBreakdown.sortMode,
      );

      setOrgActivityBreakdown({
        ...orgActivityBreakdown,
        data: newData,
      });
    }
  },[orgActivityBreakdown.sortCol, orgActivityBreakdown.sortMode]);

  /* Call only one or during the change of organization */
  React.useEffect(() => {
    if (selectedOrganization && selectedOrganization.pk) {
      getAllTimeMetricsData();
    }
  }, [selectedOrganization]);

  /* call everytime when date range is changed */
  React.useEffect(() => {
    if (selectedOrganization && selectedOrganization.pk && allTimeMetrics && Object.keys(allTimeMetrics).length) {
      tempStore.dateBasedCallsToken = `datebased-token-${Math.random()}`;
      // console.log('Token: New Token', tempStore.dateBasedCallsToken);

      const currentRange = {};
      const previousRange = {};

      switch (dateRange.value) {
        case Constants.TIME_OPTION_KEY.last7days:
          currentRange.end = new Date(); /* End is today */
          currentRange.start = subDays(currentRange.end, 6);

          previousRange.end = subDays(currentRange.start, 1);
          previousRange.start = subDays(previousRange.end, 6);
          break;

        case Constants.TIME_OPTION_KEY.last14Days:
          currentRange.end = new Date(); /* End is today */
          currentRange.start = subDays(currentRange.end, 13);

          previousRange.end = subDays(currentRange.start, 1);
          previousRange.start = subDays(previousRange.end, 13);
          break;

        case Constants.TIME_OPTION_KEY.last30Days:
          currentRange.end = new Date(); /* End is today */
          currentRange.start = subDays(currentRange.end, 29);

          previousRange.end = subDays(currentRange.start, 1);
          previousRange.start = subDays(previousRange.end, 29);
          break;

        case Constants.TIME_OPTION_KEY.previousMonth:
          currentRange.end = endOfMonth(subMonths(new Date(), 1));
          currentRange.start = startOfMonth(currentRange.end);

          previousRange.end = endOfMonth(subMonths(currentRange.start, 1));
          previousRange.start = startOfMonth(previousRange.end);
          break;

        default:
          console.error('To be fixed. NO SUCH TIME VALUE DETECTED');
          /* No break for this default case. This is intentional */

        case Constants.TIME_OPTION_KEY.weekToDate:
          currentRange.end = new Date();
          currentRange.start = startOfWeek(currentRange.end);

          previousRange.end = subWeeks(currentRange.end, 1);
          previousRange.start = startOfWeek(previousRange.end);
          break;
      }

      /* Format the date to string for API calls */
      currentRange.start = Utils.formateDateToYearMonthDay(currentRange.start);
      currentRange.end = Utils.formateDateToYearMonthDay(currentRange.end);
      previousRange.start = Utils.formateDateToYearMonthDay(previousRange.start);
      previousRange.end = Utils.formateDateToYearMonthDay(previousRange.end);

      /* Call API's */
      getGraphActiveChallenges(
        currentRange.start,
        currentRange.end,
        previousRange.start,
        previousRange.end,
        tempStore.dateBasedCallsToken,
      );

      getGraphAppUserUsage(
        currentRange.start,
        currentRange.end,
        tempStore.dateBasedCallsToken,
      );

      getGraphAverageMoveMinutes(
        currentRange.start,
        currentRange.end,
        previousRange.start,
        previousRange.end,
        tempStore.dateBasedCallsToken,
      );

      getOrgActivityBreakDown(
        currentRange.start,
        currentRange.end,
        tempStore.dateBasedCallsToken,
      );

      getOrgActivityBreakDownHeader(
        currentRange.start,
        currentRange.end,
        previousRange.start,
        previousRange.end,
        tempStore.dateBasedCallsToken,
      );
    }
  }, [dateRange, selectedOrganization, allTimeMetrics]);
  const finalValue = {
    organizationInviteCode: selectedOrganization && selectedOrganization.invite_code,
    allTimeMetrics,
    orgActivityBreakdown,
    orgActivityBreakdownHeader,
    setOrgActivityBreakdownSort: (col, mode) => setOrgActivityBreakdown({
      ...orgActivityBreakdown,
      sortCol: col,
      sortMode: mode,
    }),
    dateRange,
    setDateRange,
    graphAppUserUsage,
    graphActiveChallenges,
    graphAverageMoveMinutes,
    noData: [0, NaN].includes(totalOrgMemeber),
    launchGuideComplete: Object.values({ invite_admin, create_group, launch, promote, join_team }).every(Boolean)
  };

  return (
    <DashboardContext.Provider
      key="dashboard-context"
      value={finalValue}
    >
      {(props.children instanceof Function) ? props.children(finalValue) : props.children}
    </DashboardContext.Provider>
  );
}

DashboardContext.Wrapper = DashboardContextWrapper;
export default DashboardContext;
