import React from 'react';
import { Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import { Container, Spinner } from 'react-bootstrap';
import { pushLoginOrRegistrationEventToDataLayer } from '~/src/utils/GA';

import { AUTH_ERROR, PERMISSION_ERROR, SESSION_ERROR, isAuthRelatedError } from '~/src/api/errors';
import { GUEST, UNVERIFIED, NON_ADMIN, ADMIN, CANCELLED_SUBSCRIPTION, NON_PAYMENT_ADMIN } from '~/src/constants/userTypes';
import { LANDING, DASHBOARD_LANDING_PAGE, LOGOUT } from '~/src/constants/subpage';
import { PILOT_ORG_SIZE, DASHBOARD_SUBSCRIPTION_STATUS } from "../constants/organization";
import {
  signOut,
  getUserDataFromSession,
  getProviderFromEmail,
  getInviteCode,
} from '~/src/utils/auth';
import { reverse } from '~/src/api/urls';
import { GET, POST, makeAPIcall } from '~/src/api/utils';
import GenericModal from '~/src/components/modal/genericModal';
import SessionTimeoutModal from '~/src/components/modal/sessionTimeout';
import AddedInvitedModal from '~/src/components/modal/addedInvitedModal';
import InvalidTokenModal from '~/src/components/modal/invalidToken';
import Header from '~/src/components/header';
import Footer from '~/src/components/footer';
import Toaster from '~/src/components/toaster';
import i18n from '~/i18n';

const AuthContext = React.createContext({});

const AuthContextWrapper = (props) => {
  const { t, i18n } = useTranslation();

  const [isInitializing, setIsInitializing] = React.useState(true);
  const [isSessionExpired, setIsSessionExpired] = React.useState(false);
  const [currentUserType, setCurrentUserType] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const [orgData, setOrgData] = React.useState('');
  const [inviteData, setInviteData] = React.useState({});
  const [showInvitedModal, setShowInvitedModal] = React.useState(false);
  const [showInvalidTokenModal, setShowInvalidTokenModal] = React.useState(false);
  const [showInviteSuccessModal, setShowInviteSuccessModal] = React.useState(false);
  const [initialHeader, setInitialHeader] = React.useState({ mainHeader: DASHBOARD_LANDING_PAGE, subHeader: '' });
  const [currentPage, setCurrentPage] = React.useState(LANDING);
  const [currentLandingPage, setCurrentLandingPage] = React.useState('');
  const [selectedOrganization, setOrganization] = React.useState(null);
  const [toastData, setToastData] = React.useState({ msg: '', showMsg: false, is_error: false});
  const [modalData, setModalData] = React.useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = React.useState([]);
  const inviteCode = React.useRef(getInviteCode(props.history.location.search));

  const handleAuthError = (error) => {
    if (error.standardErrorCode === AUTH_ERROR) {
      setIsSessionExpired(true);
      props.history.push(reverse('app:logout_page'));
    } else if (error.standardErrorCode === PERMISSION_ERROR) {
      if (error.responseJson.errors[0]['message'] === 'NON_ADMIN') {
        setCurrentUserType(NON_ADMIN);
      } else {
        setCurrentUserType(UNVERIFIED);
      }
    } else if (error.standardErrorCode === SESSION_ERROR) {
      setIsSessionExpired(true);
      props.history.push(reverse('app:logout_page'));
    }
  };

  /**
   *  Sets the token globally so we can access it from anywhere.
   */
  const setTokens = async (data = {}) => {
    window._accessToken = data.accessToken;
    window._idToken = data.idToken;
    updateTokenToAPI();
  };

  /**
   * Updates ID token or access token received from Cognito to our API.
   * The handler is nothing coz, we except it to always be success.
   */
  const updateTokenToAPI = () => {
    makeAPIcall(
      reverse('api:user_update_token'),
      POST,
      (error, result) => {},
      { id_token: window._idToken || window._accessToken },
    );
  };

  const subscriptionHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      const isAdmin = false;
      if (result.payload && result.payload.length) {
        setSubscriptionDetail(result.payload);
        result.payload.map((data) => {
          if (DASHBOARD_SUBSCRIPTION_STATUS.includes(data.status)) {
            isAdmin = true;
          }
          if (isAdmin) {
            setCurrentUserType(ADMIN);
          } else {
            setCurrentUserType(CANCELLED_SUBSCRIPTION);
          }
        })
      } else {
        setCurrentUserType(NON_PAYMENT_ADMIN);
      }
    }
  }

  const organizationListHandler = async (error, result) => {
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
      }
    } else {
      if (result.payload) {
        const size = result.payload[0].organization_size;
        const orgId = result.payload[0].pk;
        const name = result.payload[0];
        setOrgData(result.payload[0].pk)
        setOrganization(result.payload[0]);
        if (size === PILOT_ORG_SIZE) {
          setCurrentUserType(ADMIN);
        } else {
          // subscription detail
          makeAPIcall(reverse('api:subscription_detail', orgId), GET, subscriptionHandler);
        }
      }
    }
  };

  const checkForSubscriptionDetail = (data) => {
    makeAPIcall(reverse('api:organization_list_user', data.payload.pk), GET, organizationListHandler);
  }


  const refreshUser = async (data) => {
    return new Promise((resolve, reject) => {
      makeAPIcall(reverse('api:user_detail'), GET, async (error, result) => {
        if (error) {
          setIsSessionExpired(true);
          signOut();
          setCurrentUserType(GUEST);
        } else {
          if (isAuthRelatedError(result)) {
            handleAuthError(error);
          } else {
            setCurrentLandingPage('');
            if (!window.userID) {
              const userID = result.payload.app_user_data.id;
              dataLayer.push({ userID: userID });
              pushLoginOrRegistrationEventToDataLayer('Login', 'Login Successful', userID);
              window.userID = userID;
            }
            let provider = null;
            try {
              /*
                If user data is not there or we dn have provider in it
                Compare provider to undefined. Any other value including null would
                mean that this provider call was made already.
              */
              if (!userData || userData.provider === undefined) {
                if (result.payload.email) {
                  provider = await getProviderFromEmail(result.payload.email);
                }
              }
            } catch (ex) {
              /* ignored */
            }

            setUserData({
              ...(data || {}),
              pk: result.payload.pk,
              app_user_data: result.payload.app_user_data,
              is_admin: result.payload.app_user_data.is_admin,
              provider,
            });

            if (!result.payload.app_user_data.accountconfirmed) {
              setCurrentUserType(UNVERIFIED);
            } else if (!result.payload.is_admin) {
                // check for invite code when a new token is set..!
                try {
                  if (inviteCode.current) {
                    await acceptAdminInvite();
                    refreshUser();
                  } else {
                    setCurrentUserType(NON_ADMIN);
                  }
                } catch (ex) {
                  setShowInvalidTokenModal(true);
                }
            } else {
              checkForSubscriptionDetail(result);
              // makeAPIcall(reverse('api:organization_list'), GET, organizationListHandler);
              // setCurrentUserType(ADMIN);
            }
          }
        }
        resolve();
      });
    });
  };

  const setAuthenticatedUser = async (data) => {
    await setTokens(data);
    await refreshUser(data);
  };

  const handleSessionExpiry = () => {
    // signOutCurrentUser();
    setIsSessionExpired(false);
  };

  const acceptAdminInvite = () => {
    return (new Promise((resolve, reject) => {
      makeAPIcall(
        reverse('api:admin_invite_accept', inviteCode.current),
        POST,
        (error, result) => {
          inviteCode.current = null;
          if (error) {
            reject(result);
          } else {
            setInviteData({ organization_name: result.payload.organization_name });
            setShowInviteSuccessModal(true);
            resolve();
          }
        },
      );
    }));
  };

  const verifyAdminInvite = () => {
    if (!inviteCode.current) {
      throw new Error('Invalid invite code');
      return;
    }

    return (new Promise((resolve, reject) => {
      makeAPIcall(
        reverse('api:admin_invite_status', inviteCode.current),
        GET,
        (error, result) => {
          if (error) {
            inviteCode.current = null;
            if (result.response.status == 404) {
              setShowInvalidTokenModal(true);
            }
            reject(result);
          } else {
            setInviteData(result.payload);
            setShowInvitedModal(true);
            resolve();
          }
        },
        {},
        {},
        true,
      );
    }));
  };

  /**
   * Signs out currently logged in user.
   *
   */
  const signOutCurrentUser = async () => {
    /* Signout from Cognito if current session exists */
    await signOut();

    /* Set the UI to initial state */
    setInitialHeader({ mainHeader: DASHBOARD_LANDING_PAGE, subHeader: '' });
    setCurrentUserType(GUEST);
    setUserData(null);
    props.history.push(reverse('app:login_page'));
    window._accessToken = null;
    window._idToken = null;
    setToastMsg(t('common.logout_msg'), false);
  }

  const updateCurrentPage = (page) => {
    if (page === 'logout') {
      signOutCurrentUser();
    } else {
      setCurrentPage(page);
    }
  };

  const setToastMsg = (msg, isError) => {
    setToastData({ msg, showMsg: true, isError });
    setTimeout( () => {
      setToastData({ msg: '', showMsg: false, isError: false });
    }, 5000);
  }

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }

  const getCurrentUserFromAmplify = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        const session = await Auth.currentAuthenticatedUser();
        const data = getUserDataFromSession(session);
        await setTokens(data);
        await refreshUser(data);
        setIsInitializing(false);
        resolve();
        if (localStorage.getItem('loginHook') == 'login') {
          makeAPIcall(reverse('api:update_login'), POST, (error, result) => { if (!error) { localStorage.setItem('loginHook', '');} }, []);
        }
      } catch (ex) {
        const params = new URLSearchParams(location.search.substr(1));
        const errorMessagee = params.get('error_description') || '';
        if (errorMessagee) {
          /* Means user exists with another provider or email */
          if (errorMessagee.includes('User Already Exists')) {
            setAuthErrorModal({
              title: t('auth.account_already_exists'),
              message: t('auth.account_already_exists_desc'),
            });
          } else {
            setAuthErrorModal({ message: t('auth.social_generic_error')})
          }
          props.history.push(reverse('app:login_page'));
        }

        setCurrentUserType(GUEST);
        try {
          if (inviteCode.current) {
            await verifyAdminInvite();
          }
        } catch (ex) {
          console.error(ex);
          setShowInvalidTokenModal(true);
        }
        setIsInitializing(false);
        reject(ex);
      }
    }));
  };

  React.useEffect(() => {
    // listen if we have any invite link

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          try {
            const newData = JSON.parse(data);
            if (newData && newData.inviteCode) {
              inviteCode.current = newData.inviteCode;
            }
          } catch (ex) { /* ignored */ }
          break;
      }
    });

    getCurrentUserFromAmplify();
  }, []);


  return (
    <AuthContext.Provider
      key="auth-context"
      value={{
        inviteCode: inviteCode.current,
        refreshUser,
        signOutCurrentUser,
        getCurrentUserFromAmplify,
        setAuthenticatedUser,
        currentUserType,
        userData,
        orgData,
        appUserId: userData && userData.app_user_data && userData.app_user_data.id || '',
        setCurrentUserType,
        handleAuthError,
        setInitialHeader,
        setCurrentPage,
        setCurrentLandingPage,
        setOrganization,
        initialHeader,
        currentPage,
        selectedOrganization,
        setToastMsg,
        setCurrentPage,
        subscriptionDetail
      }}
    >
      {
        /* While session is initializing for first time */
        isInitializing &&
          <>
            <Header disabled />
            <div className="page-container">
              <Container fluid className="page-content justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Container>
              <Footer changeLanguage={changeLanguage} appUserId={userData && userData.app_user_data && userData.app_user_data.id || ''} />
            </div>
          </>
      }
      {
        !isInitializing &&
          <>
            {
              toastData.showMsg &&
                <Toaster
                  ToasterText={toastData.msg}
                  isError={toastData.isError}
                />
            }
            <Header onClickSubheader={updateCurrentPage} onClickHover={updateCurrentPage} isRegister={currentLandingPage !== 'register'} />
            <div className="page-container">
              <Container fluid className="page-content" key={props.history.location.path}>
                {props.children}
              </Container>
              <Footer changeLanguage={changeLanguage} appUserId={userData && userData.app_user_data && userData.app_user_data.id || ''}/>
            </div>
            {
              modalData &&
              <GenericModal
                title={t(modalData.title)}
                desc={t(modalData.message)}
                okButtonTitle={t('auth.okay')}
                okButtonOnClick={() => setModalData(null)}
                onClose={() => setModalData(null)}
              />
            }
            {isSessionExpired && <SessionTimeoutModal onHide={handleSessionExpiry} />}
            {
              showInviteSuccessModal &&
              <AddedInvitedModal
                isAdded
                onHide={() => {
                  setShowInviteSuccessModal(false);
                }}
                organizationName={inviteData.organization_name}
              />
            }
            {
              /* Why ??? :( */
              showInvitedModal &&
              <AddedInvitedModal
                isAdded={false}
                onHide={() => {setShowInvitedModal(false);}}
                organizationName={inviteData.organization_name}
              />
            }
            {
              showInvalidTokenModal &&
                <InvalidTokenModal
                  onHide={() => {
                    setShowInvalidTokenModal(false);
                  }}
                />
            }
          </>
      }
    </AuthContext.Provider>
  );
}

AuthContext.Wrapper = AuthContextWrapper;
export default AuthContext;
