import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select'
import { Button, Form, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { pushEventToDataLayer } from '~/src/utils/GA';

import {
  LANGUAGE_PREFERENCE_CHOICES,
  ORGANIZATION_SIZE_CHOICES,
  PROVINCE_CHOICES,
  HEAR_ABOUT_US_CHOICES_EN,
  HEAR_ABOUT_US_CHOICES_FR
} from "../constants/organization";
import { CONTACT_US_INFO } from '~/src/constants/common';
import { reverse } from "~/src/api/urls";
import { isAuthRelatedError } from "~/src/api/errors";
import { POST, makeAPIcall } from "~/src/api/utils";
import AuthContext from '~/src/context/authContext';
import OrganizationContext from "~/src/context/organizationContext";
import ToolTip from "../components/Tooltip";
import CTA from "~/src/components/ctaBox";
import OrgSizeCard from "~/src/components/orgSizeCard";
import { LANDING } from "../constants/subpage";
import Assets from '~/src/constants/assets';
import FormikErrorMessage from '~/src/components/Formik/errorMessage';
import ButtonWithProgress from '~/src/components/buttonWithProgress';

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const MyOrganizationEdit = ({ onBackButtonClick }) => {
  const { t, i18n } = useTranslation();

  const [inProgress, setInProgress] = React.useState(false);
  const { handleAuthError, setToastMsg, subscriptionDetail, appUserId } = React.useContext(AuthContext);
  const { selectedOrganization, resetOrganization } = React.useContext(OrganizationContext);
  const existingCodes = React.useRef([]);

  const autoRenewDate = () => {
    const autoRenewDate = new Date((subscriptionDetail && subscriptionDetail[0] && subscriptionDetail[0].current_period_end)*1000 || '');
    return format(autoRenewDate, 'MMMM dd, yyyy');
  }

  const isCanceled = (subscriptionDetail && subscriptionDetail[0] && subscriptionDetail[0].cancel_at_period_end) || false;

  const formik = useFormik({
    initialValues: {
      name: selectedOrganization.name,
      invite_code: selectedOrganization.invite_code,
      organization_size: selectedOrganization.organization_size,
      address_line_1: selectedOrganization.address_line_1 ? selectedOrganization.address_line_1 : '',
      address_line_2: selectedOrganization.address_line_2 ? selectedOrganization.address_line_2 : '',
      city_name: selectedOrganization.city_name ? selectedOrganization.city_name : '',
      province_code: selectedOrganization.province_code,
      postal_code: selectedOrganization.postal_code ? selectedOrganization.postal_code : '',
      language_preference: selectedOrganization.language_preference
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t(("organization.name_required"))),
      invite_code: Yup.string().trim().required(t("organization.code_required")),
      address_line_1: Yup.string().trim().required(t("organization.address_line_1_required")),
      city_name: Yup.string().trim().required(t("organization.city_required")),
      province_code: Yup.string().trim().required(t("organization.province_required")),
      postal_code: Yup
      .string()
      .trim().required(t("organization.postal_required"))
      .matches(
        /^[^DFIOQUWZ][0-9][^DFIOQU] ?[0-9][^DFIOQU][0-9]$/,
        t("organization.post_code_invalid")
        // Valid postal code rule for Canada:
        // ABCEGHJKLMNPRSTVXY <-- letter used
        // DFIOQU <-- letters not used because it mixes up the reader
        // WZ     <-- letters used but not in the first letter
        // Eg: M6K 3P6 or M6K3P6
      ),
    }),
    validate: (values) => {
      let errors = {};
      if (existingCodes.current.includes(values.invite_code)) {
        errors.invite_code = t("organization.code_taken")
      }
      return errors;
    },
    onSubmit: (values, { setFieldError }) => {
      setInProgress(true);
      makeAPIcall(reverse('api:organization_update', selectedOrganization.pk), POST, (error, result) => {
        updateOrganizationResultHandler(error, result, values, setFieldError);
      }, values);
    }
  });


  const updateOrganizationResultHandler = async (error, result, values, setFieldError) => {
    setInProgress(false);
    if (error) {
      if (isAuthRelatedError(result)) {
        handleAuthError(result);
        return;
      }

      const hasJsonErrorCode = Boolean(result.responseJson && Array.isArray(result.responseJson.errors) && result.responseJson.errors.length);
      if ( result.response.status == 400 && hasJsonErrorCode ) {
        result.responseJson.errors.forEach(err => {
          if (err.code == 'duplicate_invite_code') {
            setFieldError('invite_code', t('organization.code_taken'));
            existingCodes.current.push(values.invite_code);
          } else {
            setFieldError(err.field, err.message);
          }
        });
      }
    } else {
      resetOrganization();
      setToastMsg(t('organization.org_saved'), false);
      onBackButtonClick();
    }
  };

  // items who render field
  const itemsRenderField = ["Other", "Autre"];
  // state save item selected
  const [itemSelected, setItemSelected] = useState(undefined);

  return (
    <Col className="org-edit-page" xs={12} md={11} lg={10} xl={6}>
      <CTA
        miniPadding
        className="content"
        onClick={() => onBackButtonClick()}
        text={t('organization.back_org')}
      >
        <div className="organization-container ">
          <Form onSubmit={formik.handleSubmit}>
            <div className="org-edit-title-area">
              <p className="org-edit-form-title">{t("organization.manage_org")}</p>
            </div>
            <p className="">{t("organization.required_field_instruction")}</p>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="input-label">{t("organization.name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className={formik.touched.name && formik.errors.name && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="name"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">
                {t("organization.code")}
                <ToolTip text={t("organization.code_info")} />
              </Form.Label>
              <Form.Control
                type="text"
                name="invite_code"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.invite_code}
                className={formik.touched.invite_code && formik.errors.invite_code && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="invite_code"
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label className="input-label">
                {t("organization.org_size")}
                <ToolTip text={t("organization.size_info")} />
              </Form.Label>
              {formik.values.organization_size &&
                (formik.values.organization_size === 'pilot' ?
                  <OrgSizeCard selectedValue={formik.values.organization_size} button={t('organization.button')} />
                  :
                  <div className="org-size-card-container">
                    <OrgSizeCard selectedValue={formik.values.organization_size} button={t('organization.button')} />
                    <div className="auto-renew">
                    <div className='text'>{!isCanceled ? t("organization.autorenewson") : t("organization.expireson")}</div>
                      <div className='date'>{autoRenewDate()}</div>
                    </div>
                  </div>
                )
              }
              <Form.Label className="input-label">
                <span>
                  {t("organization.size_contact_desc")}
                  <a
                    className="contactus-link"
                    href={CONTACT_US_INFO[`MANAGE_ORG_${i18n.language}`]}
                    onClick={() => pushEventToDataLayer('Edit Organization', 'Contact Us clicked', 'Contact Us', '1', appUserId)}
                  >
                    {t("organization.contact_us")}
                  </a>
                </span>
              </Form.Label>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("organization.address_1")}</Form.Label>
              <Form.Control
                type="text"
                disabled={inProgress}
                name="address_line_1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_line_1}
                className={formik.touched.address_line_1 && formik.errors.address_line_1 && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="address_line_1"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("organization.address_2")} ({(t("organization.optional"))})</Form.Label>
              <Form.Control
                type="text"
                disabled={inProgress}
                name="address_line_2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_line_2}
                className={formik.touched.address_line_2 && formik.errors.address_line_2 && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="address_line_2"
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("organization.city")}</Form.Label>
              <Form.Control
                type="text"
                name="city_name"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city_name}
                className={formik.touched.city_name && formik.errors.city_name && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="city_name"
              />
            </Form.Group>
            <Form.Row>
            <Col sm>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label className="input-label">{t("organization.province")}</Form.Label>
              <Form.Control
                as="select"
                name="province_code"
                disabled={inProgress}
                onChange={formik.handleChange}
                value={formik.values.province_code}
              >
                {
                  PROVINCE_CHOICES.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                    >
                      {!item.value ? t(item.label) : item.label}
                    </option>
                  ))
                }
              </Form.Control>
              <FormikErrorMessage
                formik={formik}
                fieldName="province_code"
              />
            </Form.Group>
            </Col>
            <Col sm>
            <Form.Group controlId="formBasicPassword">
              <Form.Label className="input-label">{t("organization.postal")}</Form.Label>
              <Form.Control
                name="postal_code"
                disabled={inProgress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postal_code}
                className={formik.touched.postal_code && formik.errors.postal_code && "highlight-input"}
              />
              <FormikErrorMessage
                formik={formik}
                fieldName="postal_code"
              />
            </Form.Group>
            </Col>
            </Form.Row>
            {/* Select Dropdown for hear about us goes here if needed to be implemented in future */}
            <Form.Row>
              <Col sm={12}>
                {
                  formik.submitCount > 0 && !formik.isValid ?
                  <span className="form-final-error-text">
                    <img src={Assets.ErrorIcon} alt="error-icon"  className="final-error-icon" />
                    {t("common.correct_errors_above")}
                  </span>
                  : null
                }
              </Col>
              <Col sm={12}>
                <div className="org-edit-save-btn-area">
                  <ButtonWithProgress
                    inProgress={inProgress}
                    text={t('organization.save')}
                  />
                </div>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </CTA>
    </Col>
  );
};

export default MyOrganizationEdit;
